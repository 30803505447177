import { SVGAttributes } from "react";

const MaskedIcon = ({
  width = "20",
  height = "20",
  fill = "#808085",
}: SVGAttributes<SVGAElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.8208 17.1786C6.43358 17.1786 5.25358 16.692 4.2808 15.7186C3.30747 14.7459 2.8208 13.5659 2.8208 12.1786C2.8208 10.792 3.30747 9.61198 4.2808 8.63865C5.25413 7.66531 6.43413 7.17865 7.8208 7.17865C9.20747 7.17865 10.3875 7.66531 11.3608 8.63865C12.3341 9.61198 12.8208 10.792 12.8208 12.1786C12.8208 13.5659 12.3341 14.7459 11.3608 15.7186C10.3875 16.692 9.20747 17.1786 7.8208 17.1786ZM7.8208 16.3453C8.97358 16.3453 9.95608 15.9392 10.7683 15.127C11.5805 14.3148 11.9869 13.332 11.9875 12.1786C11.9875 11.0259 11.5811 10.0434 10.7683 9.23115C9.95552 8.41892 8.97302 8.01253 7.8208 8.01198C6.66802 8.01198 5.68525 8.41837 4.87247 9.23115C4.05969 10.0439 3.65358 11.0264 3.65413 12.1786C3.65413 13.3314 4.06025 14.3142 4.87247 15.127C5.68469 15.9398 6.66747 16.3459 7.8208 16.3453ZM13.965 12.4736C13.9711 12.4225 13.9741 12.3739 13.9741 12.3278V12.1786C13.9741 10.4814 13.373 9.03142 12.1708 7.82865C10.968 6.62642 9.51802 6.02531 7.8208 6.02531H7.67163C7.62552 6.02531 7.57691 6.02837 7.5258 6.03448C7.80136 5.21837 8.35163 4.47865 9.17663 3.81531C10.0022 3.15198 11.003 2.82031 12.1791 2.82031C13.5664 2.82031 14.7464 3.30698 15.7191 4.28031C16.6925 5.25309 17.1791 6.43309 17.1791 7.82031C17.1791 8.99642 16.8475 9.99726 16.1841 10.8228C15.5208 11.6478 14.7811 12.1981 13.965 12.4736Z"
        fill={fill}
      />
    </svg>
  );
};
export default MaskedIcon;
