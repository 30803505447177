import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import {
  MasksFormContextProvider,
  MasksContextProvider,
  TaskContextProvider,
  TasksContextProvider,
} from "../providers";

import { PATH_ROUTES } from "./routes";

import { Home, Login, DocumentsViewer } from "../pages";

import ProtectedRoute from "./ProtectedRoute";

const routes = createBrowserRouter([
  {
    path: PATH_ROUTES.login,
    element: <Login />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATH_ROUTES.home,
        element: (
          <TasksContextProvider>
            <Home />
          </TasksContextProvider>
        ),
      },
      {
        path: PATH_ROUTES.documentsViewer,
        element: (
          <TaskContextProvider>
            <MasksFormContextProvider>
              <MasksContextProvider>
                <DocumentsViewer />
              </MasksContextProvider>
            </MasksFormContextProvider>
          </TaskContextProvider>
        ),
      },
    ],
  },
  { path: "*", element: <Navigate to={PATH_ROUTES.home} /> }, // Si no es una ruta correcta redirigimos a la HOME
]);

const Router = () => {
  return <RouterProvider router={routes} />;
};

export default Router;
