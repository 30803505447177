import { Task } from "../../../../types/task";

import { getTaskFiles } from "../../../../utils/getTaskFiles";

import useTaskDocuments from "../../../../hooks/useTaskDocuments";

import DocumentsLoader from "./DocumentsLoader";
import ExpandedRowItem from "./ExpandedRowItem";
import DocumentsError from "./DocumentsError";

const ExpandedRowContent = ({ taskId }: { taskId: number }) => {
  const { task, isLoading, error, getTask } = useTaskDocuments(taskId);

  if (isLoading) return <DocumentsLoader />;

  if (error) return <DocumentsError refreshTask={getTask} error={error} />;

  return (
    <>
      {getTaskFiles((task as Task).documents).map((document) => (
        <ExpandedRowItem document={document} />
      ))}
    </>
  );
};
export default ExpandedRowContent;
