import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import {
  addLastActivityCookie,
  getLastActivityCookie,
  removeLastActivityCookie,
} from "./cookies";

export interface UserLogged {
  jwtToken: string;
  refreshToken: string;
  idToken: string;
  user: {
    userName: string;
    email: string;
  };
}

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
};

const userPool = new CognitoUserPool(poolData);

export const authenticate = (
  email: string,
  password: string
): Promise<UserLogged> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (session) => {
        const user: UserLogged = getUserLoggedFromSession(session);
        addLastActivityCookie();
        resolve(user);
      },

      onFailure: reject,
    });
  });
};

export const signOut = () => {
  const user = userPool.getCurrentUser();
  user?.signOut();
  removeLastActivityCookie();
};

export const checkUserSession = (): Promise<UserLogged | null> => {
  return new Promise((resolve, reject) => {
    if (isLastActivityExpired()) {
      signOut()
      return resolve(null);
    }
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) return resolve(null);

    cognitoUser.getSession(
      (err: Error | null, session: CognitoUserSession | null) => {
        if (err) return reject(err);

        if (session?.isValid()) {
          const user = getUserLoggedFromSession(session);
          return resolve(user);
        } else return resolve(null);
      }
    );
  });
};

const getUserLoggedFromSession = (session: CognitoUserSession): UserLogged => ({
  jwtToken: session.getAccessToken().getJwtToken(),
  refreshToken: session.getRefreshToken().getToken(),
  idToken: session.getIdToken().getJwtToken(),
  user: {
    userName: session.getIdToken().payload["cognito:username"],
    email: session.getIdToken().payload.email,
  },
});

export const isLastActivityExpired = () => {
  const lastActivityCookie = getLastActivityCookie();

  if (!lastActivityCookie) return true;

  const givenTime = Number(lastActivityCookie);

  // Crear un objeto Date con el tiempo dado
  const givenDate = new Date(givenTime);

  // Añadir una hora (3600000 milisegundos) al tiempo dado
  const expirationDate = new Date(givenDate.getTime() + 3600000);

  // Obtener el tiempo actual
  const currentTime = new Date().getTime();

  // Comprobar si el tiempo actual es menor que el tiempo dado más una hora
  const isCurrentTimeValid = currentTime < expirationDate.getTime();

  return !isCurrentTimeValid;
};

export const getUserLogged = () => userPool.getCurrentUser();
