import logoHipra from "../../assets/images/hipra-logo.png";
import logoOmnios from "../../assets/images/omnios-logo.png";
import logoHipraAimask from "../../assets/images/hipra-aimasking-logo-01.png";

import LoginForm from "./LoginForm";

import styles from "./Login.module.css";

const Login = () => {
  return (
    <div className={styles.container}>
      <div className={styles.bgContent}>
        <img className={styles.logoHipraAim} src={logoHipraAimask} alt="Hipra Logo Aimask" />
        <footer className={styles.bgContentFooter}>
          <img className={styles.logoHipra} src={logoHipra} alt="Hipra Logo" />
        </footer>
      </div>
      <div className={styles.formContent}>
        <LoginForm />
        <footer className={styles.footer}>
          <a href="https://omnios.ai/" target="blank">
            <img src={logoOmnios} alt="Omnios Logo" />
          </a>
        </footer>
      </div>
    </div>
  );
};
export default Login;
