import { useRef, useState } from "react";
import { Button, Card, CardProps, Tooltip } from "antd";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";

import { useModalContext, useTaskContext } from "../../providers";

import { Mask, MaskType } from "../../types/masks";

import { getHistoryToShow, getOccurrencesSorted } from "./utils";
import { generateRandomId } from "../../utils/common";

import FeedbackAction from "./FeedbackAction";
import FeedbackCardOccurrences from "./FeedbackCardOccurrences";
import FeedbackTag from "./FeedbackTag";
import PPDIcon from "../icons/PPDIcon";
import CCIIcon from "../icons/CCIIcon";

import styles from "./FeedbackCard.module.css";

const getNotAssociatedMessage = (maskType: MaskType) => {
  return `Mask could not be associated to any ${
    maskType === "CCI" ? "Justification Table" : "Feedback Anonymization Report"
  } row.`;
};

interface FeedbackCardProps extends CardProps {
  maskInfo: Mask;
  selected: boolean;
  goToAnnotation: (maskId: number, occurrenceId: number) => void;
}

const FeedbackCard = ({
  maskInfo,
  selected,
  goToAnnotation,
  ...rest
}: FeedbackCardProps) => {
  const { openDeleteMassiveOccurrences, setMask } = useModalContext();
  const { task } = useTaskContext();

  const feedbackRef = useRef<HTMLParagraphElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);

  const [closedChevron, setClosedChevron] = useState(true);

  const toggleCard = () => setClosedChevron(!closedChevron);

  const occurrences = getOccurrencesSorted(maskInfo);

  const historyToShow = getHistoryToShow(closedChevron, maskInfo.history);

  const showActions = !closedChevron && !maskInfo.created_by;

  const isMaskDeleted = maskInfo.deleted_at !== null;

  const maskHasOccurrencesNoDeleted = maskInfo.occurrences.some(
    (occurrence) => occurrence.deleted_at === null
  );

  const isDeleteMassiveOccurrencesButtonShowed =
    selected &&
    !isMaskDeleted &&
    task?.deleted_at === null &&
    maskInfo.occurrences.length > 0 &&
    maskHasOccurrencesNoDeleted;

  const deleteAllOccurrencesTooltipTitle =
    isDeleteMassiveOccurrencesButtonShowed && occurrences.length === 1
      ? "Delete occurrence"
      : "Delete all occurrences";

  return (
    <Card
      className={`feedbackCard ${selected && "selected"}`}
      actions={historyToShow.map((action) => (
        <FeedbackAction
          key={generateRandomId()}
          action={action}
          occurrences={maskInfo.occurrences}
        />
      ))}
      {...rest}
    >
      <header className={styles.feedbackCardHeader}>
        {maskInfo.type === "PPD" && <PPDIcon />}
        {maskInfo.type === "CCI" && <CCIIcon />}
        <Button
          onClick={toggleCard}
          type="text"
          icon={
            <DownOutlined
              className={`rotate ${!closedChevron && "up"}`}
              ref={iconRef}
            />
          }
        />
      </header>
      <section className={styles.feedbackSection}>
        <h4 className={`lg strong m-0 ${closedChevron && "ellipsed"} one-line`}>
          {maskInfo.name}
        </h4>
        {maskInfo.type !== "PPD" &&
          !maskInfo.status &&
          !maskInfo.created_by && (
            <p className={styles.textNoOccurrences}>
              {getNotAssociatedMessage(maskInfo.type)}
            </p>
          )}
        <FeedbackCardOccurrences
          maskInfo={maskInfo}
          occurrences={occurrences}
          goToAnnotation={goToAnnotation}
        />
        {isDeleteMassiveOccurrencesButtonShowed && (
          <Tooltip title={deleteAllOccurrencesTooltipTitle} placement="bottom">
            <Button
              size="small"
              onClick={() => {
                setMask(maskInfo);
                openDeleteMassiveOccurrences();
              }}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        )}
        <p
          ref={feedbackRef}
          className={`lg normal m-0 ${closedChevron && "ellipsed"} two-lines`}
        >
          {maskInfo.feedback}
        </p>
        <footer>
          <FeedbackTag status={maskInfo.status} />
        </footer>
      </section>
      {showActions && (
        <section className={styles.actions}>
          <h4 className="base strong m-0">Actions</h4>
          <p className="base normal m-0">
            {maskInfo.updated_by_system
              ? "Applied changes"
              : "Unapplied changes"}
          </p>
        </section>
      )}
    </Card>
  );
};
export default FeedbackCard;
