import { ReactNode, createContext, useContext, useMemo } from "react";
import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";

import { MessageContextType } from "../types/providers";

export const MessageContext = createContext<MessageContextType | null>(null);

const MessageContextProvider = ({ children }: { children: ReactNode }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const KEY = "messages";

  const contextValue = useMemo(
    () => ({
      sendMessage: (
        message: string,
        messageType: NoticeType,
        duration = 1.5,
        key = KEY
      ) => {
        messageApi.open({
          key,
          type: messageType,
          content: message,
          duration,
        });
      },
    }),
    [messageApi]
  );

  return (
    <MessageContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => {
  const context = useContext(MessageContext);

  if (!context)
    throw new Error(
      "useMessageContext must be used within a MessageContextProvider"
    );

  return context;
};

export default MessageContextProvider;
