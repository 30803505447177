export const getParsedFullDate = (stringDate: string, separator = "") => {
  const date = new Date(stringDate);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${getParsedDate(stringDate)} ${separator} ${getPaddedTime(
    hours
  )}:${getPaddedTime(minutes)}`;
};

const getParsedDate = (stringDate: string) => {
  const date = new Date(stringDate);

  const dateString = date.toLocaleDateString("default", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const [day, month, year] = dateString.split("/");

  return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
};

const getPaddedTime = (time: number) => {
  return time.toString().padStart(2, "0");
};
