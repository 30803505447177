import {
  useMessageContext,
  useModalContext,
  useTasksContext,
} from "../../../providers";

import { tasksFetcher } from "../../../services/api";

import { Task } from "../../../types/task";

import PopupModal from "../PopupModal";

const ReactivateTask = () => {
  const { modalOpen, closeModal, taskSelected } = useModalContext();

  const { sendMessage } = useMessageContext();
  const { updateTask } = useTasksContext();

  const onReactivateTask = async () => {
    try {
      const taskUndeleted = await tasksFetcher.reactivateTask(
        (taskSelected as Task).id
      );
      sendMessage(
        `${taskSelected?.name} was successfully reactivated`,
        "success"
      );
      updateTask({ ...taskUndeleted, deleted_at: null });
      closeModal();
    } catch (error) {
      console.error(error);
      sendMessage(
        `Something was wrong reactivating tasl: "${taskSelected?.name}". Please, try in a few moments`,
        "error",
        3
      );
    }
  };

  return (
    <PopupModal
      open={modalOpen === "reactivateTask"}
      onConfirm={onReactivateTask}
      onCancel={closeModal}
      confirmText="Reactivate task"
    >
      <h3 className="lg normal">Reactivate task</h3>
      <p>By reactivating this task, you will be allowed to edit it.</p>
    </PopupModal>
  );
};
export default ReactivateTask;
