import { useState } from "react";
import { Button, Form, Upload } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";

import { UploadChildrenItemProps } from "./interfaces";

import { generateRandomId } from "../../utils/common";

import { onCustomUploadFile } from "./customRequest";

import LoadingFile from "./LoadingFile";

import DocumentName from "./DocumentName";

import styles from "./UploadItem.module.css";

const UploadChildrenItem = ({
  label,
  uploadProps,
  documentName,
  errors,
  onUploadCompleted,
  onUploadFailed,
  onDeleteJT,
}: UploadChildrenItemProps) => {
  const [isUploading, setIsUploading] = useState(false);

  const uploadText = documentName ? "Replace" : "Upload";

  const hasErrors = errors?.some((error) => error.hasError);
  return (
    <div
      className={`${styles.uploadItemJustification} ${
        hasErrors && styles.borderError
      }`}
    >
      <Form.Item label={label}>
        {documentName && (
          <Button
            style={{ marginRight: "10px" }}
            onClick={onDeleteJT}
            type="text"
            icon={<DeleteOutlined style={{ fontSize: "14px" }} />}
          />
        )}
        <Upload
          showUploadList={false}
          onChange={(info) => {
            if (info.file.status === "uploading") {
              setIsUploading(true);
            }
            if (info.file.status === "done") {
              setIsUploading(false);
              onUploadCompleted && onUploadCompleted(info);
            } else if (info.file.status === "error") {
              setIsUploading(false);
              onUploadFailed && onUploadFailed(info);
            }
          }}
          customRequest={onCustomUploadFile}
          {...uploadProps}
        >
          <Button icon={<UploadOutlined />}>{uploadText}</Button>
        </Upload>
      </Form.Item>
      {isUploading && <LoadingFile />}
      {documentName && <DocumentName docName={documentName} />}
      {errors?.map(
        (error) =>
          error.hasError && (
            <p
              key={generateRandomId()}
              className={`base normal ${styles.uploadItemError}`}
            >
              <ExclamationCircleFilled
                className={styles.exclamationCircleWarning}
              />
              {error.message}
            </p>
          )
      )}
    </div>
  );
};
export default UploadChildrenItem;
