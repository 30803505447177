import { Tag, Tooltip } from "antd";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { TooltipPlacement } from "antd/es/tooltip";

import styles from "./StatusTag.module.css";

const StatusTag = ({
  isValidated,
  tooltipPlacement,
}: {
  isValidated: boolean;
  tooltipPlacement: TooltipPlacement;
}) => {
  const tooltipTitle = isValidated
    ? "Validated task"
    : "Pending validation Task";
  const tagColor = isValidated ? "#D9F2EF" : "warning";
  const tagIcon = isValidated ? (
    <CheckOutlined style={{ color: "#007F6D" }} />
  ) : (
    <ExclamationCircleOutlined />
  );

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <Tag className={styles.tag} color={tagColor} icon={tagIcon} />
    </Tooltip>
  );
};

export default StatusTag;
