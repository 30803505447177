import styles from "./MaskTag.module.css";

interface MaskTagProps {
  maskType: string;
  hasFreeText: boolean;
}

const MaskTag = ({ maskType, hasFreeText }: MaskTagProps) => {
  return (
    <div className={styles.container}>
      <span className={`${styles.tagText} ${styles.greyText}`}>
        Mask set:
        {hasFreeText ? (
          <span className={styles.greyText}>
            {maskType}/<span className={styles.blackText}>Text</span>
          </span>
        ) : (
          <span className={styles.blackText}>{maskType}</span>
        )}
      </span>
    </div>
  );
};
export default MaskTag;
