import { TaskDocuments } from "../types/documents";

export const getTaskFiles = (taskDocuments: TaskDocuments) => {
  const rppFiles = taskDocuments.RPP_JT.flatMap((rppDocument) => {
    const files = [rppDocument.ORIGINAL_RPP];
    if (rppDocument.JT !== null) {
      files.push(rppDocument.JT);
    }
    return files;
  });

  return [...rppFiles, taskDocuments.AR_FAR.AR, taskDocuments.AR_FAR.FAR];
};
