import { FormTask, UpdateTask } from "../../types/formTasks";
import { RedactionInfo } from "../../types/redaction";
import { ErrorsHandler } from "../../utils/errorHandler";
import { ApiFetcher } from "./ApiFetcher";

export class BaseFetcher {
  apiFetcher: ApiFetcher;

  constructor() {
    this.apiFetcher = new ApiFetcher();
  }

  /** Request params validators */
  validateDocumentId(documentId: string): void {
    if (!documentId)
      ErrorsHandler.throwBadRequest("Document id is not provided");
  }

  validateFilename(filename: string | null): void {
    if (!filename) ErrorsHandler.throwBadRequest("Filename is not provided");
  }

  validateMaskId(maskId: number): void {
    if (!maskId) ErrorsHandler.throwBadRequest("Mask id is not provided");
  }

  validateOccurrenceId(occurenceId: number): void {
    if (!occurenceId) ErrorsHandler.throwBadRequest("Occurrence id is not provided");
  }

  validateRedactionInfo(redactionInfo: RedactionInfo) {
    if (!redactionInfo) ErrorsHandler.throwBadRequest("Redaction id is not provided");
  }

  validateUsername(username: string | undefined){
    if (!username) ErrorsHandler.throwBadRequest("User not provided");
  }

  validateTask(task: FormTask | UpdateTask): void {
    if (!task) ErrorsHandler.throwBadRequest("Task not provided");
  }

  validateTaskId(taskId: number | string): void {
    if (!taskId) ErrorsHandler.throwBadRequest("Task id not provided");
  }

  validateSubtaskId(subtaskId: string): void {
    if (!subtaskId) ErrorsHandler.throwBadRequest("Subtask id not provided");
  }


}
