import {
  ChangeEvent,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { RadioChangeEvent } from "antd";

import { MaskType } from "../types/masks";
import { MaskFormContextType } from "../types/providers";

export const MaskFormContext = createContext<MaskFormContextType | null>(null);

const MaskFormContextProvider = ({ children }: { children: ReactNode }) => {
  const [maskSelected, setMaskSelected] = useState<MaskType>("CCI");
  const [freeText, setFreeText] = useState("");

  const onChangeMask = (e: RadioChangeEvent) => setMaskSelected(e.target.value);

  const onChangeFreeText = (e: ChangeEvent<HTMLInputElement>) =>
    setFreeText(e.target.value);

  const [showAddMask, setShowAddMask] = useState(false);

  const onToggleShowAddMask = () => setShowAddMask((prevValue) => !prevValue);

  const [showMaskSelected, setShowMaskSelected] = useState<MaskType | "">("");

  const onAddMaskToShow = (maskSelected: MaskType) =>
    setShowMaskSelected(maskSelected);

  const onResetMaskForm = () => {
    setMaskSelected("CCI");
    setFreeText("");
    setShowAddMask(false);
    setShowMaskSelected("");
  };
  const contextValue = useMemo(
    () => ({
      maskSelected,
      freeText,
      onChangeMask,
      onChangeFreeText,
      showAddMask,
      onToggleShowAddMask,
      showMaskSelected,
      onAddMaskToShow,
      onResetMaskForm,
    }),
    [maskSelected, freeText, showAddMask, showMaskSelected]
  );

  return (
    <MaskFormContext.Provider value={contextValue}>
      {children}
    </MaskFormContext.Provider>
  );
};

export const useMaskFormContext = () => {
  const context = useContext(MaskFormContext);

  if (!context)
    throw new Error(
      "useMaskFormContext must be used within a MaskFormContextProvider"
    );

  return context;
};

export default MaskFormContextProvider;
