import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs, { Dayjs } from "dayjs";

import useMasterData from "../../hooks/useMasterData";

import { FormFilters } from "../../types/formFilters";

import FiltersFormItem from "./FiltersFormItem";
import AlphanumericInput from "./Inputs/AlphanumericInput";

import styles from "./FiltersForm.module.css";

const validationFilterValues = [
  { label: "Validated", value: "Validated" },
  { label: "Pending", value: "Pending" },
];

interface FiltersFormProps {
  formFilters: FormFilters;
  onChangeTaskIdFrom: (value: number | null) => void;
  onChangeTaskIdTo: (value: number | null) => void;
  onChangeProduct: (value: string) => void;
  onChangeUser: (value: string) => void;
  onChangeProcedureType: (value: string) => void;
  onChangeProcedureId: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeCreationDate: (
    dates: [Dayjs | null, Dayjs | null],
    dateString: [string, string]
  ) => void;
  onChangeEditionDate?: (
    dates: [Dayjs | null, Dayjs | null],
    dateString: [string, string]
  ) => void;
  onChangeValidation?: (checkedValues: string[]) => void;
  onClickApply: () => void;
  onClickCancel: () => void;
}

const { Option } = Select;
const { RangePicker } = DatePicker;

const FiltersForm = ({
  formFilters,
  onChangeTaskIdFrom,
  onChangeTaskIdTo,
  onChangeProduct,
  onChangeUser,
  onChangeProcedureType,
  onChangeProcedureId,
  onChangeCreationDate,
  onChangeEditionDate,
  onChangeValidation,
  onClickApply,
  onClickCancel,
}: FiltersFormProps) => {
  const [form] = useForm<FormFilters>();
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);
  const { masterData, isLoading } = useMasterData();

  useEffect(() => {
    form
      .validateFields()
      .then(() => setIsApplyDisabled(false))
      .catch((e) => setIsApplyDisabled(true))
      .finally(() => {
        // Arreglo para que estos campos se actualicen
        form.setFieldValue("taskIdFrom", formFilters.taskId.from);
        form.setFieldValue("taskIdTo", formFilters.taskId.to);
      });
  }, [form, formFilters]);

  return (
    <Form form={form} className={styles.form} layout="vertical">
      <FiltersFormItem label="Task ID range">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              className="customFormItem filters"
              dependencies={["taskIdTo"]}
              name="taskIdFrom"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const toValue = getFieldValue("taskIdTo");
                    if (value && toValue !== null && toValue < value) {
                      return Promise.reject(
                        new Error("From value must be lower than to")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                value={formFilters.taskId.from}
                onChange={onChangeTaskIdFrom}
                min={1}
                className="w-full"
                controls={false}
                placeholder="From"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="customFormItem filters"
              name="taskIdTo"
              dependencies={["taskIdFrom"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue("taskIdFrom") > value) {
                      return Promise.reject(
                        new Error("To value must be higher than from")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                name="taskIdTo"
                value={formFilters.taskId.to}
                onChange={onChangeTaskIdTo}
                className="w-full"
                min={1}
                controls={false}
                placeholder="To"
              />
            </Form.Item>
          </Col>
        </Row>
      </FiltersFormItem>
      <FiltersFormItem label="Product">
        <Select
          allowClear={Boolean(formFilters.product)}
          onChange={onChangeProduct}
          value={formFilters.product}
          placeholder="Product name"
          loading={isLoading}
        >
          {masterData.products.map((product) => (
            <Option key={product.key} value={product.value}>
              {product.value}
            </Option>
          ))}
        </Select>
      </FiltersFormItem>
      <FiltersFormItem label="User">
        <Select
          allowClear={Boolean(formFilters.user)}
          onChange={onChangeUser}
          value={formFilters.user}
          placeholder="Select username"
        >
          {masterData.users.map((user) => (
            <Option key={user.email} value={user.username}>
              {user.username}
            </Option>
          ))}
        </Select>
      </FiltersFormItem>
      <Row gutter={16}>
        <Col span={12}>
          <FiltersFormItem label="Procedure type">
            <Select
              loading={isLoading}
              allowClear={Boolean(formFilters.procedureType)}
              onChange={onChangeProcedureType}
              value={formFilters.procedureType}
              placeholder="Select"
            >
              {masterData.procedureTypes.map((procedureType) => (
                <Option key={procedureType.key} value={procedureType.value}>
                  {procedureType.value}
                </Option>
              ))}
            </Select>
          </FiltersFormItem>
        </Col>
        <Col span={12}>
          <FiltersFormItem label="ID procedure">
            <AlphanumericInput
              onChange={onChangeProcedureId}
              value={formFilters.procedureId}
              placeholder="0"
            />
          </FiltersFormItem>
        </Col>
      </Row>
      <FiltersFormItem label="Creation date range">
        <RangePicker
          onChange={onChangeCreationDate}
          value={formFilters.creationDate}
          className="w-full"
          maxDate={dayjs()}
        />
      </FiltersFormItem>
      {onChangeEditionDate && (
        <FiltersFormItem label="Last edition date range">
          <RangePicker
            onChange={onChangeEditionDate}
            value={formFilters.editionDate}
            className="w-full"
            maxDate={dayjs()}
          />
        </FiltersFormItem>
      )}
      {onChangeValidation && (
        <FiltersFormItem label="Validation">
          <Checkbox.Group
            options={validationFilterValues}
            value={formFilters.validation}
            onChange={onChangeValidation}
          />
        </FiltersFormItem>
      )}
      <footer className={styles.footer}>
        <Button
          onClick={onClickCancel}
          className={styles.footerBtn}
          type="text"
        >
          Cancel
        </Button>
        <Button
          disabled={isApplyDisabled}
          onClick={onClickApply}
          className={styles.footerBtn}
          type="primary"
        >
          Apply
        </Button>
      </footer>
    </Form>
  );
};
export default FiltersForm;
