import { useEffect, useState } from "react";

import { MasterData } from "../types/masterData";

import { masterDataFetcher } from "../services/api";

const useMasterData = () => {
  const [masterData, setMasterData] = useState<MasterData>({
    products: [],
    procedureTypes: [],
    users: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    masterDataFetcher.getMasterData()
      .then(setMasterData)
      .catch(setMasterData)
      .finally(() => setIsLoading(false));
  }, []);

  const getProduct = (productKey: string) => {
    return (
      masterData.products.find((product) => product.key === productKey) ?? {
        key: "",
        value: "",
      }
    );
  };

  const getProcedureType = (procedureTypeKey: string) => {
    return (
      masterData.procedureTypes.find(
        (procedureType) => procedureType.key === procedureTypeKey
      ) ?? {
        key: "",
        value: "",
      }
    );
  };

  return { masterData, isLoading, getProduct, getProcedureType };
};
export default useMasterData;
