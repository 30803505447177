import { UploadRequestOption } from "rc-upload/lib/interface";
import { RcFile } from "antd/es/upload";

import { documentsFetcher } from "../../services/api";

export const onCustomUploadFile = async ({
  file,
  onSuccess,
  onProgress,
  onError,
}: UploadRequestOption) => {
  const rcFile = file as RcFile;
  try {
    const url = await documentsFetcher.getDocumentUploadUrl(rcFile.name);

    if (!url) throw new Error("No presigned URL");

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.setRequestHeader("Content-Type", rcFile.type);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress?.({
          percent: (event.loaded / event.total) * 100,
        });
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        onSuccess?.(rcFile);
      } else {
        onError?.(new Error("Upload failed"));
      }
    };

    xhr.onerror = () => {
      onError?.(new Error("Upload failed"));
    };

    xhr.send(file);
  } catch (error) {
    onError?.(new Error("File upload failed"));
  }
};
