import { Tag, TagProps } from "antd";

interface FilterTagProps extends TagProps {
  name: string;
  dataValue?: string | null;
}

const FilterTag = ({ name, dataValue, children, ...props }: FilterTagProps) => {
  return (
    <Tag
      data-name={name}
      data-value={dataValue}
      className="base normal filtersTag"
      closable
      {...props}
    >
      {children}
    </Tag>
  );
};
export default FilterTag;
