import {
  useMessageContext,
  useModalContext,
  useTaskContext,
} from "../../../providers";

import { Task } from "../../../types/task";

import { getUserLogged } from "../../../services/cognito";
import { tasksFetcher } from "../../../services/api";

import PopupModal from "../PopupModal";

const ValidateTask = () => {
  const { modalOpen, closeModal, taskSelected } = useModalContext();
  const { updateTask } = useTaskContext();
  const { sendMessage } = useMessageContext();

  const isValidatedTask = Boolean(
    taskSelected?.validated_at && taskSelected.validated_by
  );

  const onValidateTask = async () => {
    closeModal();
    const userName = getUserLogged()?.getUsername();

    sendMessage("Updating task...", "loading", 0);

    try {
      const taskUpdated = await tasksFetcher.changeValidationStatus(
        (taskSelected as Task).id,
        userName,
        isValidatedTask
      );

      updateTask(taskUpdated);

      const taskStatus = isValidatedTask ? "Pending validation" : "Validated.";

      const message = `The task status successfully changed to ${taskStatus}`;
      sendMessage(message, "success");
    } catch (error) {
      console.error(error);
    }
  };

  const title = isValidatedTask ? "Undoing validation" : "Validating task";
  const confirmText = isValidatedTask ? "Undo validation" : "Validate";

  const message = isValidatedTask
    ? "You are about to undo the validation of this task. This means the validation status will be marked as Pending validation. "
    : "You are about to validate this task. This means the validation status will be marked as Validated by you, and other users will see the date and time of validation. Please note that the task can still be edited after validation.";

  return (
    <PopupModal
      open={modalOpen === "validateTask"}
      onConfirm={onValidateTask}
      onCancel={closeModal}
      confirmText={confirmText}
    >
      <h3 className="lg normal">{title}</h3>
      <p>{message}</p>
    </PopupModal>
  );
};
export default ValidateTask;
