import { CustomError, ErrorsHandler } from "../../utils/errorHandler";
import { checkUserSession } from "../cognito";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const DEFAULT_TIMEOUT = 30000;
const DEFAULT_OPTIONS: RequestInit = {
  method: "GET",
};

type UrlParams = Record<string, string | number | boolean>;

interface CallApiParams {
  endpoint: string;
  urlParams?: UrlParams;
  timeout?: number;
  options?: RequestInit;
}

type ApiResponse<T> = {
  message: string;
  data: T;
  success: boolean;
};

export class ApiFetcher {
  private apiUrl: string;

  constructor(apiUrl = API_URL) {
    if (!apiUrl) ErrorsHandler.throwBadRequest("API base URL not specified");
    this.apiUrl = apiUrl;
  }

  async callApi<T>({
    endpoint,
    urlParams,
    timeout = DEFAULT_TIMEOUT,
    options = {},
  }: CallApiParams) {
    if (!endpoint)
      ErrorsHandler.throwBadRequest("API call endpoint not specified");

    const url = this.createUrl(endpoint, urlParams);

    const abortController = new AbortController();

    const user = await checkUserSession().catch((error) => {
      this.handleError(error);
    });
    if (!user?.idToken) {
      throw new CustomError("User not logged in or token is missing", 401);
    }

    const fetchOptions: RequestInit = {
      ...DEFAULT_OPTIONS,
      ...options,
      signal: abortController.signal,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${user.idToken}`,
      },
    };

    const timeoutId = setTimeout(() => abortController.abort(), timeout);

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok)
        ErrorsHandler.throwErrorByCode(response.status, response.statusText);

      return (await response.json()) as ApiResponse<T>;
    } catch (error) {
      this.handleError(error);
    } finally {
      clearTimeout(timeoutId);
    }
  }

  private createUrl(endpoint: string, urlParams?: UrlParams) {
    const url = new URL(`${this.apiUrl}/${endpoint}`);
    if (urlParams) {
      Object.keys(urlParams).forEach((key) =>
        url.searchParams.append(key, String(urlParams[key]))
      );
    }
    return url;
  }

  private handleError(error: unknown): never {
    if (error instanceof CustomError) {
      if (error.name === "AbortError") ErrorsHandler.throwAbortError();
      else ErrorsHandler.throwErrorByCode(error.statusCode, error.message);
    } else ErrorsHandler.throwUnexpectedError();
  }
}
