import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";

import { Task } from "../../../types/task";

import { sharedColumns } from "./sharedColumns";

import DeleteTaskButton from "../../Buttons/DeleteTaskButton";
import ReuploadButton from "../../Buttons/ReuploadButton";

import ProcessingStatusRow from "./CustomRows/Processing/ProcessingStatusRow";

import styles from "./columns.module.css";

const processingColumns: TableProps<Task>["columns"] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span className="base normal">{text}</span>,
    sorter: (a, b) => Number(a.id) - Number(b.id),
    fixed: "left",
    width: 61,
    defaultSortOrder: "descend",
  },
  ...(sharedColumns as ColumnsType<Task>),
  {
    title: "Owner",
    dataIndex: "created_by",
    key: "owner",
    render: (created_by) => <span className="base normal">{created_by}</span>,
  },
  {
    title: "Process status",
    dataIndex: "error",
    key: "error",
    render: (error, task) => <ProcessingStatusRow error={error} task={task} />,
    ellipsis: true,
  },
  {
    title: "Actions",
    render: (_, task) => (
      <div className={styles.tableRowActions}>
        {!task.deleted_at && (
          <>
            {task.status === "FAILED" && <ReuploadButton task={task} />}
            <DeleteTaskButton task={task} />
          </>
        )}
      </div>
    ),
    fixed: "right",
  },
];

export default processingColumns;
