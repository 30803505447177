import "antd/dist/reset.css";
import "./index.css";

import {
  ThemeProvider,
  AuthContextProvider,
  MessageContextProvider,
  ModalContextProvider,
} from "./providers";

import Router from "./router/Router";

const App = () => {
  return (
    <ThemeProvider>
      <ModalContextProvider>
        <MessageContextProvider>
          <AuthContextProvider>
            <Router />
          </AuthContextProvider>
        </MessageContextProvider>
      </ModalContextProvider>
    </ThemeProvider>
  );
};

export default App;
