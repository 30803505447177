import { useContext } from "react";
import { Button, Form } from "antd";
import { RcFile } from "antd/es/upload";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import { TaskFormContext } from "../../../providers/TaskFormProvider";
import { TaskFormContextType } from "../../../providers/interfaces";

import { FormRppJt } from "../../../types/formTasks";

import {
  isDOC,
  isDOCX,
  isPDF,
  isFileSizeValid,
} from "../../../components/Forms/utils";

import UploadItem from "../../../components/UploadItem/UploadItem";
import UploadChildrenItem from "../../../components/UploadItem/UploadChildrenItem";

import styles from "./Task.module.css";

const lastBlockHasClinicalFile = (rppDocuments: FormRppJt[]) => {
  const lastBlockIndex = rppDocuments.length - 1;
  return rppDocuments[lastBlockIndex].RPP;
};

const RPPSection = () => {
  const {
    taskDocuments,
    documentsErrors,
    addNewRPPBlock,
    deleteRPPBlock,
    addClinicalDocument,
    addClinicalDocumentError,
    filenameExists,
  } = useContext(TaskFormContext) as TaskFormContextType;

  const beforeUploadClinicalDocument = (file: RcFile, rppBlock: FormRppJt) => {
    if (!isFileSizeValid(file)) {
      rppBlock.id &&
        addClinicalDocumentError(
          rppBlock?.id,
          "File uploaded is too large. Maximum allowed is 300 MB.",
          "RPP"
        );
      return false;
    }
    if (!isPDF(file)) {
      rppBlock.id &&
        addClinicalDocumentError(
          rppBlock.id,
          "Clinical file only supports PDF files",
          "RPP"
        );

      return false;
    }
    if (filenameExists(file.name, "RPP", rppBlock)) {
      rppBlock.id &&
        addClinicalDocumentError(
          rppBlock.id,
          `File ${file.name} is already uploaded in this form. Please, replace it.`,
          "RPP"
        );

      return false;
    }
  };
  const beforeUploadJustificationTable = (
    file: RcFile,
    rppBlock: FormRppJt
  ) => {
    if (!isFileSizeValid(file)) {
      rppBlock.id &&
        addClinicalDocumentError(
          rppBlock?.id,
          "File uploaded is too large. Maximum allowed is 300 MB.",
          "JT"
        );
      return false;
    }
    if (!isDOC(file) && !isDOCX(file)) {
      rppBlock.id &&
        addClinicalDocumentError(
          rppBlock.id,
          "Justification Table only supports DOC or DOCX files",
          "JT"
        );
      return false;
    }
    if (filenameExists(file.name, "JT", rppBlock)) {
      rppBlock.id &&
        addClinicalDocumentError(
          rppBlock.id,
          `File ${file.name} is already uploaded in this form. Please, replace it.`,
          "JT"
        );

      return false;
    }
  };

  const hasLastBlockClinicalFile = lastBlockHasClinicalFile(
    taskDocuments.RPP_JT
  );

  return (
    <div className={styles.uploadBlock}>
      <Form.Item
        label="Clinical Documents & Justification Table"
        tooltip="Documents with interactive masks and its justification document."
      >
        {taskDocuments.RPP_JT.map((rppBlock, index) => {
          let errorBlock = documentsErrors.RPPJTS.find(
            (error) => error.id === rppBlock.id
          );
          if (!errorBlock) {
            errorBlock = {
              id: rppBlock.id ?? "",
              JT: { hasError: false, message: "" },
              RPP: { hasError: false, message: "" },
            };
          }
          return (
            <UploadItem
              key={rppBlock.id}
              label="Clinical file"
              uploadProps={{
                beforeUpload: (file) =>
                  beforeUploadClinicalDocument(file, rppBlock),
                accept: ".pdf",
              }}
              onUploadCompleted={(info) =>
                rppBlock.id &&
                addClinicalDocument(info.file.name, rppBlock.id, "RPP")
              }
              onUploadFailed={(info) =>
                rppBlock.id &&
                addClinicalDocumentError(
                  rppBlock.id,
                  `${info.file.name} file upload failed`,
                  "RPP"
                )
              }
              documentName={rppBlock.RPP}
              errors={[errorBlock.RPP]}
              fileChildren={
                (rppBlock.RPP || rppBlock.JT) && (
                  <UploadChildrenItem
                    onUploadCompleted={(info) =>
                      rppBlock.id &&
                      addClinicalDocument(info.file.name, rppBlock.id, "JT")
                    }
                    onUploadFailed={(info) =>
                      rppBlock.id &&
                      addClinicalDocumentError(
                        rppBlock.id,
                        `${info.file.name} file upload failed`,
                        "JT"
                      )
                    }
                    label={
                      <p className={styles.uploadItemOptionalLabel}>
                        Justification Table <span>(optional)</span>
                      </p>
                    }
                    uploadProps={{
                      beforeUpload: (file) =>
                        beforeUploadJustificationTable(file, rppBlock),
                      accept: ".doc,.docx",
                    }}
                    documentName={rppBlock.JT}
                    errors={[errorBlock.JT]}
                    onDeleteJT={() =>
                      rppBlock.id &&
                      addClinicalDocument(null, rppBlock.id, "JT")
                    }
                  />
                )
              }
              closeBlock={
                index !== 0 && (
                  <Button
                    className={styles.uploadItemButtonClose}
                    onClick={() => rppBlock.id && deleteRPPBlock(rppBlock.id)}
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                  />
                )
              }
            />
          );
        })}
      </Form.Item>
      {hasLastBlockClinicalFile && (
        <Button onClick={addNewRPPBlock} icon={<PlusOutlined />} type="dashed">
          Add another clinical file
        </Button>
      )}
    </div>
  );
};
export default RPPSection;
