import { TaskFormContextProvider, useModalContext } from "../../../providers";

import { FormTaskType } from "../../../types/formTasks";
import { Task } from "../../../types/task";

import TaskForm from "../../Forms/Task/FormTask";

import ModalContainer from "../ModalContainer";

const CreateTaskModal = ({
  addTask,
  goToProcessingTab,
}: {
  addTask: (task: Task) => void;
  goToProcessingTab?: () => void;
}) => {
  const { modalOpen, closeModal } = useModalContext();

  return (
    <ModalContainer
      destroyOnClose
      open={modalOpen === "createTask"}
      onCancel={closeModal}
    >
      <TaskFormContextProvider>
        <TaskForm
          formTaskType={modalOpen as FormTaskType}
          addTask={addTask}
          goToProcessingTab={goToProcessingTab}
        />
      </TaskFormContextProvider>
    </ModalContainer>
  );
};
export default CreateTaskModal;
