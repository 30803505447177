import { ChangeEvent, useState } from "react";
import { Dayjs } from "dayjs";

import { FormFilters, OptionalFormFilters } from "../types/formFilters";

const MAX_CHARS = 8;

const initialFilters: FormFilters = {
  taskId: { from: null, to: null },
  product: undefined,
  user: undefined,
  procedureType: undefined,
  procedureId: undefined,
  creationDate: null,
  editionDate: null,
  validation: [],
};

const getResetedValue = (dataName: keyof FormFilters) => {
  if (dataName === "taskId") return { from: null, to: null };
  if (dataName === "creationDate" || dataName === "editionDate") return null;
  if (dataName === "validation") return [];
  return undefined;
};

const useFilters = () => {
  const [formFilters, setFormFilters] = useState(initialFilters);
  const [filtersApplied, setFiltersApplied] = useState<OptionalFormFilters[]>(
    []
  );

  const onChangeTaskIdFrom = (value: number | null) =>
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      taskId: { ...prevFilters.taskId, from: value },
    }));
  const onChangeTaskIdTo = (value: number | null) =>
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      taskId: { ...prevFilters.taskId, to: value },
    }));

  const onChangeProduct = (value: string) =>
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      product: value,
    }));

  const onChangeUser = (value: string) =>
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      user: value,
    }));

  const onChangeProcedureType = (value: string) =>
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      procedureType: value,
    }));

  const onChangeProcedureId = (e: ChangeEvent<HTMLInputElement>) =>
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      procedureId: e.target.value.slice(0, MAX_CHARS),
    }));

  const onChangeCreationDate = (dates: [Dayjs | null, Dayjs | null]) => {
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      creationDate: dates,
    }));
  };

  const onChangeEditionDate = (dates: [Dayjs | null, Dayjs | null]) => {
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      editionDate: dates,
    }));
  };

  const onChangeValidation = (checkedValues: string[]) => {
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      validation: checkedValues,
    }));
  };

  const onApplyFilters = () => {
    const filtersToShow: OptionalFormFilters[] = [];

    Object.entries(formFilters).forEach(([key, value]) => {
      const castKey = key as keyof OptionalFormFilters;

      if (value === null) return;

      const isTaskIdFilter = castKey === "taskId" && typeof value === "object";
      const isDateFilter =
        (castKey === "creationDate" || castKey === "editionDate") &&
        Array.isArray(value);
      const isValidationFilter = castKey === "validation";
      const isStringFilter = typeof value === "string";

      if (
        isTaskIdFilter ||
        isDateFilter ||
        isStringFilter ||
        isValidationFilter
      )
        filtersToShow.push({ [castKey]: value });
    });

    setFiltersApplied(filtersToShow);
  };

  const onClearFilter = (e: React.MouseEvent<HTMLElement>) => {
    // Acceder al elemento que disparó el evento
    const target = e.currentTarget.parentNode as HTMLElement;

    // Obtener el valor de data-name del elemento Tag
    const dataName = target.getAttribute("data-name") as keyof FormFilters;

    if (dataName === "validation") {
      const dataValue = target.getAttribute("data-value");
      setFiltersApplied((prevFilters) =>
        prevFilters.map((filter) =>
          Object.keys(filter)[0] !== "validation"
            ? filter
            : {
                [dataName]: filter[dataName]?.filter(
                  (filterOpt) => filterOpt !== dataValue
                ),
              }
        )
      );
      setFormFilters((prevFilters) => ({
        ...prevFilters,
        [dataName]: prevFilters.validation.filter(
          (validateValue) => validateValue !== dataValue
        ),
      }));
    } else {
      setFiltersApplied((prevFilters) =>
        prevFilters.filter(
          (filter) => !Object.keys(filter).includes(dataName as string)
        )
      );
      setFormFilters((prevFilters) => ({
        ...prevFilters,
        [dataName]: getResetedValue(dataName),
      }));
    }
  };

  const onClearFilters = () => {
    setFormFilters(initialFilters);
    setFiltersApplied([]);
  };

  const recoverFilterApplied = (filter: OptionalFormFilters) => {
    Object.entries(filter).forEach(([k, filterValue]) => {
      const filterKey = k as keyof FormFilters;
      setFormFilters((prevFilters) => ({
        ...prevFilters,
        [filterKey]: filterValue,
      }));
    });
  };

  return {
    formFilters,
    filtersApplied,
    onChangeTaskIdFrom,
    onChangeTaskIdTo,
    onChangeProduct,
    onChangeUser,
    onChangeProcedureType,
    onChangeProcedureId,
    onChangeCreationDate,
    onChangeEditionDate,
    onChangeValidation,
    onApplyFilters,
    onClearFilters,
    onClearFilter,
    recoverFilterApplied,
  };
};
export default useFilters;
