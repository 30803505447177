import { ChangeEvent, useEffect, useState } from "react";
import { debounce } from "lodash";

import { Task } from "../../../types/task";
import { OptionalFormFilters } from "../../../types/formFilters";

import {
  getProcessingTaskError,
  getTasksFiltered,
  getTasksProcessing,
} from "../../../utils/tasks";

import useFilters from "../../../hooks/useFilters";

import { TasksTable } from "../../../components";
import FiltersForm from "../../../components/FiltersForm/FiltersForm";
import ModalFormContainer from "../../../components/FiltersForm/ModalFormContainer";
import ProcessingHeader from "../../../components/TasksTable/ProcessingHeader/ProcessingHeader";
import processingColumns from "../../../components/TasksTable/TableColumns/processingColumns";
import EmptyFilters from "../../../components/Empty/EmptyFilters";
import EmptyTasks from "../../../components/Empty/EmptyTasks";

const getProcessingTasksFiltered = (
  tasks: Task[],
  showDeleted: boolean,
  filtersApplied: OptionalFormFilters[],
  searchName = ""
) => {
  const processingTasks = getTasksProcessing(tasks);

  const filteredTasks = getTasksFiltered(
    processingTasks,
    filtersApplied,
    searchName
  );

  return showDeleted
    ? filteredTasks
    : filteredTasks.filter((task) => !task.deleted_at);
};

const TabProcessing = ({ tasks }: { tasks: Task[] }) => {
  const [showDeleted, setShowDeleted] = useState(false);
  const [openFiltersForm, setOpenFiltersForm] = useState(false);
  const [searchName, setSearchName] = useState("");

  const {
    formFilters,
    filtersApplied,
    onChangeTaskIdFrom,
    onChangeTaskIdTo,
    onChangeProduct,
    onChangeUser,
    onChangeProcedureType,
    onChangeProcedureId,
    onChangeCreationDate,
    onApplyFilters,
    onClearFilters,
    onClearFilter,
    recoverFilterApplied,
  } = useFilters();

  const [tasksFiltered, setTasksFiltered] = useState<Task[]>(() =>
    getProcessingTasksFiltered(tasks, showDeleted, filtersApplied, searchName)
  );

  useEffect(() => {
    const filteredTasks = getProcessingTasksFiltered(
      tasks,
      showDeleted,
      filtersApplied,
      searchName
    );

    setTasksFiltered(filteredTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, showDeleted, filtersApplied]);

  const onOpenFiltersForm = () => setOpenFiltersForm(true);
  const onCloseFiltersForm = () => setOpenFiltersForm(false);

  const onToggleDeletedTasks = () => setShowDeleted(!showDeleted);

  const debouncedSearch = debounce((searchText: string) => {
    const newFilteredTasks = getProcessingTasksFiltered(
      tasks,
      showDeleted,
      filtersApplied,
      searchText
    );

    setTasksFiltered(newFilteredTasks);
  }, 300);

  const onChangeSearchName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
    debouncedSearch(e.target.value);
  };

  const hasFiltersApplied =
    filtersApplied.length > 1 || searchName.trim() !== "";

  const hasOnlyCompletedDeletedTasks = tasksFiltered.every(
    (task) => task.deleted_at
  );

  return (
    <>
      <TasksTable
        title={() => (
          <ProcessingHeader
            onChangeSearchName={onChangeSearchName}
            searchName={searchName}
            filtersApplied={filtersApplied}
            onClearFilters={onClearFilters}
            onClearFilter={onClearFilter}
            onOpenFiltersForm={onOpenFiltersForm}
            tasks={tasksFiltered}
            showDeleted={showDeleted}
            onToggleDeletedTasks={onToggleDeletedTasks}
          />
        )}
        size="small"
        columns={processingColumns}
        dataSource={tasksFiltered}
        locale={{
          emptyText: () =>
            (hasFiltersApplied && tasksFiltered.length === 0) ||
            hasOnlyCompletedDeletedTasks ? (
              <EmptyFilters />
            ) : (
              <EmptyTasks tabSelected="processing" />
            ),
        }}
        expandable={{
          expandedRowRender: (task: Task) =>
            task.status === "FAILED" && (
              <span className="base normal">
                {getProcessingTaskError(task.error).message}
              </span>
            ),
          rowExpandable: (task: Task) => task.status === "FAILED",
        }}
        rowKey="id"
      />
      <ModalFormContainer onCancel={onCloseFiltersForm} open={openFiltersForm}>
        <FiltersForm
          formFilters={formFilters}
          onClickApply={() => {
            onApplyFilters();
            onCloseFiltersForm();
          }}
          onClickCancel={() => {
            onCloseFiltersForm();
            filtersApplied.forEach(recoverFilterApplied);
          }}
          onChangeTaskIdFrom={onChangeTaskIdFrom}
          onChangeTaskIdTo={onChangeTaskIdTo}
          onChangeProduct={onChangeProduct}
          onChangeUser={onChangeUser}
          onChangeProcedureType={onChangeProcedureType}
          onChangeProcedureId={onChangeProcedureId}
          onChangeCreationDate={onChangeCreationDate}
        />
      </ModalFormContainer>
    </>
  );
};
export default TabProcessing;
