import { Button, Tooltip } from "antd";
import { HistoryOutlined } from "@ant-design/icons";

import { useModalContext } from "../../providers";

import { Task } from "../../types/task";

const ReactivateButton = ({ task }: { task: Task }) => {
  const { openReactivateTask, setTask } = useModalContext();

  const onReactivateTask = () => {
    setTask(task);
    openReactivateTask();
  };

  return (
    <Tooltip title="Reactivate task">
      <Button onClick={onReactivateTask} icon={<HistoryOutlined />} />
    </Tooltip>
  );
};
export default ReactivateButton;
