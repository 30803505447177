import { Maybe } from "../../../types/common";
import { Document, RPPJTS } from "../../../types/documents";

const getRppBlockSelected = (
  docSelected: Document,
  taskRppDocuments: RPPJTS[]
): Maybe<RPPJTS> => {
  const isClinicalFileSelected = docSelected.type === "RPP";
  const documentId = docSelected.id;

  return taskRppDocuments.find((rppBlock) =>
    isClinicalFileSelected
      ? rppBlock.RPP?.id === documentId
      : rppBlock.JT?.id === documentId
  );
};

export default getRppBlockSelected;
