import { Badge, TableProps } from "antd";

import { Task } from "../../../types/task";
import { getParsedFullDate } from "../../../utils/dateParsers";
import { sortTasksByDate } from "../../../utils/tasks";

import TooltipedElement from "../../TooltipedElement";

import styles from "./columns.module.css";

const getTaskStatus = (deletedDate: string | null) => {
  return deletedDate !== null ? "error" : "success";
};

export const sharedColumns: TableProps<Task>["columns"] = [
  {
    title: "Task name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      const status = getTaskStatus(record.deleted_at);
      return (
        <span className={`base strong ${styles.tableRowTaskNameWrapper}`}>
          <Badge status={status} />
          <TooltipedElement>
            <p className={`m-0 ${styles.tableRowTaskName}`}>{text}</p>
          </TooltipedElement>
        </span>
      );
    },
    fixed: "left",
    width: 240,
    ellipsis: true,
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    render: (product) => <span className="base normal">{product.value}</span>,
    ellipsis: true,
  },
  {
    title: "Procedure type",
    dataIndex: "procedure_type",
    key: "procedureType",
    render: (procedureType) => (
      <span className="base normal">{procedureType.value}</span>
    ),
    ellipsis: true,
  },
  {
    title: "ID procedure",
    dataIndex: "procedure_id",
    key: "procedureId",
    render: (text: number) => <span className="base normal">{text}</span>,
    sorter: (a, b) => Number(a.procedure_id) - Number(b.procedure_id),
  },
  {
    title: "Created on",
    dataIndex: "created_at",
    key: "createdAt",
    render: (text) => (
      <span className="base normal">{getParsedFullDate(text, "·")}</span>
    ),
    sorter: (a, b) => sortTasksByDate(a.created_at, b.created_at),
    width: 160,
  },
];
