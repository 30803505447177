import { ReactNode } from "react";
import { Button } from "antd";

import ModalContainer from "./ModalContainer";

import styles from "./PopupModal.module.css";

const PopupModal = ({
  open,
  onConfirm,
  onCancel,
  children,
  cancelText = "Cancel",
  confirmText = "Confirm",
}: {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  children: ReactNode;
  cancelText?: string;
  confirmText?: string;
}) => {
  return (
    <ModalContainer
      open={open}
      centered={false}
      onCancel={onCancel}
      width={384}
    >
      {children}
      <footer className={styles.footer}>
        <Button onClick={onCancel} type="link">
          {cancelText}
        </Button>
        <Button onClick={onConfirm} type="primary">
          {confirmText}
        </Button>
      </footer>
    </ModalContainer>
  );
};
export default PopupModal;
