import { Spin, Typography } from "antd";

import styles from "./LoadingFeedback.module.css";

const LoadingFeedback = () => {
  return (
    <div className={styles.loadingContainer}>
      <Spin size="large" />
      <Typography className={`lg strong ${styles.loadingTitle}`}>
        Gathering Feedback
      </Typography>
      <Typography className={`lg normal ${styles.loadingMessage}`}>
        We're loading the feedback data. It'll just take a few moments.
      </Typography>
    </div>
  );
};
export default LoadingFeedback;
