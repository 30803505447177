import { Table, TableProps } from "antd";

import styles from "./TasksTable.module.css";

const TasksTable = ({ ...props }: TableProps) => {
  return (
    <div className={styles.tableContainer}>
      <Table className="customTable" {...props} />
    </div>
  );
};
export default TasksTable;
