import { Task } from "../../../types/task";

const getDocumentById = (task: Task, documentId: string) => {
  const rppOrJtDoc = task.documents.RPP_JT.find(
    (rppBlock) =>
      rppBlock.ORIGINAL_RPP?.id === documentId || rppBlock.JT?.id === documentId
  );

  if (rppOrJtDoc) {
    if (rppOrJtDoc.ORIGINAL_RPP?.id === documentId) {
      return rppOrJtDoc.RPP;
    } else if (rppOrJtDoc.JT?.id === documentId) {
      return rppOrJtDoc.JT;
    }
  }

  if (task.documents.AR_FAR.AR?.id === documentId) {
    return task.documents.AR_FAR.AR;
  } else if (task.documents.AR_FAR.FAR?.id === documentId) {
    return task.documents.AR_FAR.FAR;
  }

  throw new Error("Document not found with ID: " + documentId);
};

export default getDocumentById;
