const ReuploadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69491 18.9383L4.01679 17.9047C5.81913 20.2086 8.62226 21.6875 11.7699 21.6875C17.2051 21.6875 21.6043 17.293 21.6113 11.8555C21.6183 6.41328 17.2098 2 11.7699 2C7.52069 2 3.8996 4.69531 2.52148 8.47109C2.48632 8.56953 2.53788 8.67969 2.63632 8.7125L3.96522 9.16953C4.01156 9.18541 4.06227 9.18253 4.10651 9.16151C4.15074 9.14049 4.185 9.10299 4.20194 9.05703C4.24413 8.93984 4.291 8.82266 4.34022 8.70781C4.74569 7.74688 5.32694 6.88438 6.06757 6.14375C6.80222 5.40772 7.67203 4.82041 8.62929 4.41406C9.62069 3.99453 10.6777 3.78125 11.7652 3.78125C12.8551 3.78125 13.9098 3.99453 14.9012 4.41406C15.8593 4.8187 16.7295 5.40622 17.4629 6.14375C18.1995 6.87831 18.7862 7.74918 19.1902 8.70781C19.6098 9.70156 19.823 10.7563 19.823 11.8461C19.823 12.9359 19.6098 13.9906 19.1902 14.9844C18.7848 15.9453 18.2035 16.8078 17.4629 17.5484C16.7223 18.2891 15.8598 18.8703 14.9012 19.2781C13.9098 19.6977 12.8527 19.9109 11.7652 19.9109C10.6754 19.9109 9.62069 19.6977 8.62929 19.2781C7.67111 18.8735 6.80098 18.286 6.06757 17.5484C5.83554 17.3164 5.61757 17.0703 5.41601 16.8125L6.82694 15.7109C6.85486 15.6893 6.87612 15.6603 6.88826 15.6272C6.90041 15.594 6.90296 15.5581 6.89561 15.5236C6.88827 15.4891 6.87133 15.4573 6.84674 15.432C6.82216 15.4067 6.79092 15.3888 6.75663 15.3805L2.64101 14.3727C2.52382 14.3445 2.40897 14.4336 2.40897 14.5531L2.39023 18.793C2.39257 18.9477 2.57304 19.0344 2.69491 18.9383Z"
        fill="#808085"
      />
      <path
        d="M10.9199 10.3852H9.18788C9.03085 10.3852 8.94413 10.2048 9.04022 10.0805L11.6652 6.75944C11.6828 6.73703 11.7052 6.71891 11.7308 6.70644C11.7563 6.69398 11.7844 6.6875 11.8129 6.6875C11.8413 6.6875 11.8694 6.69398 11.895 6.70644C11.9206 6.71891 11.943 6.73703 11.9605 6.75944L14.5855 10.0829C14.6816 10.2048 14.5949 10.3852 14.4379 10.3852H12.7012V15.6875C12.7012 15.7906 12.6168 15.875 12.5137 15.875H11.1074C11.0043 15.875 10.9199 15.7906 10.9199 15.6875V10.3852Z"
        fill="#808085"
      />
    </svg>
  );
};
export default ReuploadIcon;
