import { createContext, ReactNode, useContext, useMemo } from "react";

import { TaskFormContextType } from "./interfaces";

import { Task } from "../types/task";
import { Maybe } from "../types/common";

import useTaskForm from "../hooks/useTaskForm";

export const TaskFormContext = createContext<TaskFormContextType | null>(null);

const TaskFormContextProvider = ({
  children,
  initialTask,
}: {
  children: ReactNode;
  initialTask?: Maybe<Task>;
}) => {
  const taskForm = useTaskForm(initialTask);
  const contextValue = useMemo(
    () => ({
      ...taskForm,
    }),
    [taskForm]
  );
  return (
    <TaskFormContext.Provider value={contextValue}>
      {children}
    </TaskFormContext.Provider>
  );
};

export const useTaskFormContext = () => {
  const context = useContext(TaskFormContext);

  if (!context)
    throw new Error(
      "useTaskFormContext must be used within a TaskFormContextProvider"
    );

  return context;
};

export default TaskFormContextProvider;
