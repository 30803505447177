import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { ModalContextType } from "./interfaces";

import { Document } from "../types/documents";
import { FormTaskType } from "../types/formTasks";
import { Mask } from "../types/masks";
import { Task } from "../types/task";

export const ModalContext = createContext<ModalContextType | null>(null);

export type ModalsType =
  | FormTaskType
  | "completedFilters"
  | "processingFilters"
  | "deleteTask"
  | "masksFilters"
  | "reactivateTask"
  | "deleteMassiveOccurrences"
  | "validateTask"
  | "";

const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [modalOpen, setModalOpen] = useState<ModalsType>("");
  const [taskSelected, setTaskSelected] = useState<Task | null>(null);
  const [maskSelected, setMaskSelected] = useState<Mask | null>(null);
  const [docSelected, setDocSelected] = useState<Document | null>(null);

  const contextValue = useMemo(
    () => ({
      modalOpen,
      taskSelected,
      maskSelected,
      docSelected,
      setTask: (task: Task) => setTaskSelected(task),
      setMask: (mask: Mask) => setMaskSelected(mask),
      setDocument: (document: Document | null) => setDocSelected(document),
      openCreateTask: () => setModalOpen("createTask"),
      openUpdateTask: () => setModalOpen("updateTask"),
      openDeleteTask: () => setModalOpen("deleteTask"),
      openCompletedFilters: () => setModalOpen("completedFilters"),
      openProcessingFilters: () => setModalOpen("processingFilters"),
      openMasksFilters: () => setModalOpen("masksFilters"),
      openReactivateTask: () => setModalOpen("reactivateTask"),
      openDeleteMassiveOccurrences: () =>
        setModalOpen("deleteMassiveOccurrences"),
      openValidateTask: () =>
        setModalOpen("validateTask"),
      closeModal: () => setModalOpen(""),
    }),
    [modalOpen, taskSelected, docSelected, maskSelected]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context)
    throw new Error(
      "useModalContext must be used within a ModalContextProvider"
    );

  return context;
};
export default ModalContextProvider;
