import { History, HistoryType, Occurrence } from "../types/masks";
import { ACTIONS } from "./constants";

import { getParsedFullDate } from "./dateParsers";

const getActionToShow = (actionType: HistoryType) =>
  ACTIONS[actionType] ?? "Unknown action";

export const getActionMessage = (
  action: History,
  occurrences: Occurrence[]
) => {
  const parsedDate = getParsedFullDate(action.timestamp);
  const actionToShow = getActionToShow(action.type);

  let actionMessage = parsedDate;
  if (action.type === "DELETE_MASK")
    actionMessage += ` - ${actionToShow.message} ${action.username}`;
  else {
    const occurrenceFound = occurrences.find(
      (occurrence) => occurrence.id === action.occurrence_ids[0]
    );
    actionMessage += `- Page ${occurrenceFound?.page} - ${actionToShow.message} ${action.username}`;
  }
  return actionMessage;
};
