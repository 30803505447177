import { TagProps } from "antd";

import FilterTag from "./FilterTag";

interface RangeTagProps extends TagProps {
  from: number | null;
  to: number | null;
  name: string;
  type: string;
}

const getRangeTagText = (
  from: number | null,
  to: number | null,
  type: string
) => {
  let tagText = "";
  if (!from && !to) return tagText;

  if (from && !to) tagText = `${type} from: ${from}`;
  if (!from && to) tagText = `${type} to: ${to}`;
  if (from && to) tagText = `${type} from: ${from} to ${to}`;
  return tagText;
};

const RangeTag = ({ name, type, from, to, ...filterProps }: RangeTagProps) => {
  const tagText = getRangeTagText(from, to, type);
  if (tagText === "") return null;
  return (
    <FilterTag name={name} {...filterProps}>
      {tagText}
    </FilterTag>
  );
};
export default RangeTag;
