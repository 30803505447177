import { DocumentType } from "../types/documents";

export const generateRandomId = () => {
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);

  const uniquePart = `${Date.now()}-${array[0].toString(
    36
  )}-${array[1].toString(36)}`;
  return `id-${uniquePart}`;
};

export const toCapitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const toNormalize = (word: string) => {
  return toCapitalize(word).replace("_", " ");
};

export const addEllipsisToText = (
  textToEllipse: string,
  textMaxLength: number
): string => {
  const underscoreOccurrences = countCharacterOccurrences(textToEllipse, "_");
  const hyphenOccurrences = countCharacterOccurrences(textToEllipse, "-");

  let totalToReduce = 0;
  if (hyphenOccurrences === 0) {
    totalToReduce = underscoreOccurrences - 2;
  } else if (underscoreOccurrences === 0) {
    totalToReduce = hyphenOccurrences - 7;
  } else {
    totalToReduce = Math.abs(underscoreOccurrences - hyphenOccurrences) + 8;
  }

  const truncatedLength = Math.max(textMaxLength - totalToReduce, 0);

  if (textToEllipse.length > truncatedLength) {
    return `${textToEllipse.slice(0, truncatedLength)}...`;
  }
  return textToEllipse;
};

export const countCharacterOccurrences = (text: string, character: string) =>
  text.split(character).length - 1;

export const getTooltipTitle = (documentType: DocumentType) => {
  const TOOLTIP_TITLES = {
    RPP: "Interactive masks file",
    JT: "Justification table file",
    AR: "Feedback file",
    FAR: "Feedback file",
  };

  return TOOLTIP_TITLES[documentType];
};

export const downloadFileHidden = async (fileUrl: string, filename: string) => {
  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error(`Download file ${filename} failed`);
  }
  const blob = await response.blob();
  const urlBlob = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = urlBlob;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(urlBlob);
}