import { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import {
  useMaskFormContext,
  useMasksContext,
  useMessageContext,
  useTaskContext,
  useWebViewerContext,
} from "../../../providers";

import { Document } from "../../../types/documents";
import { LeftAsideProps } from "../../../types/documentsViewer";
import { MaskType } from "../../../types/masks";
import { RedactionInfo } from "../../../types/redaction";

import { documentsFetcher } from "../../../services/api";

import useRefProp from "../../../hooks/useRefProp";

import getDocumentById from "../utils/getDocumentById";
import getMenuItems from "../utils/getMenuItems";
import {
  getInitialRedaction,
  handleAnnotationChanged,
} from "../../../utils/pdfVisor";

import HelpButton from "../../../components/Buttons/HelpButton";

import AddMask from "./AddMask";

import styles from "./LeftAside.module.css";

const LeftAside = ({
  task,
  docSelected,
  onChangeDocSelected,
  rppGroupSelected,
}: LeftAsideProps) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const toggleMenuCollapse = () => setMenuCollapsed(!menuCollapsed);

  const { refreshNotifications } = useTaskContext();

  const { maskSelected, freeText, onResetMaskForm } = useMaskFormContext();

  const { propRef: maskSelectedRef } = useRefProp<MaskType>(maskSelected);

  const { propRef: freeTextRef } = useRefProp<string>(freeText);

  const { propRef: redactionInfoRef } = useRefProp<RedactionInfo>(
    getInitialRedaction(task, docSelected),
    task,
    docSelected
  );

  const { instance } = useWebViewerContext();

  const { sendMessage } = useMessageContext();

  const { getApiMasks } = useMasksContext();

  useEffect(() => {
    if (!instance) return;

    handleAnnotationChanged(
      instance,
      sendMessage,
      onResetMaskForm,
      redactionInfoRef,
      maskSelectedRef,
      freeTextRef,
      getApiMasks,
      refreshNotifications
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, sendMessage, onResetMaskForm]);

  return (
    <aside className={styles.leftAside}>
      <Menu
        className={styles.menu}
        mode="inline"
        inlineCollapsed={menuCollapsed}
        items={getMenuItems(task, rppGroupSelected, menuCollapsed)}
        defaultSelectedKeys={[task?.documents.RPP_JT[0].ORIGINAL_RPP?.id ?? ""]}
        onClick={async (info) => {
          if (!task) return;
          const docKey = info.key;
          try {
            const docFounded = getDocumentById(task, docKey) as Document;
            const apiDoc = await documentsFetcher.getDocumentUrl(docFounded.id);
            onChangeDocSelected(apiDoc, task);
          } catch (error) {
            console.error(error);
          } finally {
            onResetMaskForm();
          }
        }}
      />
      {docSelected?.type === "RPP" && <AddMask />}
      <footer
        className={`${styles.menuFooter} ${
          menuCollapsed
            ? styles.menuFooterCollapsed
            : styles.menuFooterUncollapsed
        }`}
      >
        <HelpButton className="" style={{ marginLeft: "16px" }} />
        <Button
          size="small"
          style={{
            marginLeft: "16px",
            marginRight: "16px",
          }}
          onClick={toggleMenuCollapse}
          icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />
      </footer>
    </aside>
  );
};
export default LeftAside;
