import { ChangeEvent } from "react";
import { Input, InputProps } from "antd";

import { ALPHANUMERIC } from "../../../utils/regex";

const AlphanumericInput = ({ onChange, ...props }: InputProps) => {
  const onCheckAlphanumericValue = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (ALPHANUMERIC.test(newValue)) onChange?.(e);
  };

  return <Input onChange={onCheckAlphanumericValue} {...props} />;
};
export default AlphanumericInput;
