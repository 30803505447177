import { ChangeEvent, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

import { Task } from "../../../types/task";
import { OptionalFormFilters } from "../../../types/formFilters";

import useFilters from "../../../hooks/useFilters";

import {
  getSortedTasks,
  getTasksCompleted,
  getTasksFiltered,
} from "../../../utils/tasks";

import { TasksTable } from "../../../components";
import ModalFormContainer from "../../../components/FiltersForm/ModalFormContainer";
import FiltersForm from "../../../components/FiltersForm/FiltersForm";
import CompletedHeader from "../../../components/TasksTable/CompletedHeader/CompletedHeader";
import EmptyFilters from "../../../components/Empty/EmptyFilters";
import EmptyTasks from "../../../components/Empty/EmptyTasks";
import completedColumns from "../../../components/TasksTable/TableColumns/completedColumns";

import CardsList from "./CardsList/CardsList";
import ExpandedRowContent from "./ExpandedRow/ExpandedRowContent";

const getCompletedTasksFiltered = (
  tasks: Task[],
  showDeleted: boolean,
  filtersApplied: OptionalFormFilters[],
  searchName = ""
) => {
  const tasksCompleted = getTasksCompleted(tasks);
  const tasksTableWithDelete = showDeleted
    ? tasksCompleted
    : tasksCompleted.filter((task) => !task.deleted_at);

  return getTasksFiltered(tasksTableWithDelete, filtersApplied, searchName);
};

const TabCompleted = ({ tasks }: { tasks: Task[] }) => {
  const navigate = useNavigate();

  const [showDeleted, setShowDeleted] = useState(false);
  const [openFiltersForm, setOpenFiltersForm] = useState(false);
  const [searchName, setSearchName] = useState("");

  const {
    formFilters,
    filtersApplied,
    onChangeTaskIdFrom,
    onChangeTaskIdTo,
    onChangeProduct,
    onChangeUser,
    onChangeProcedureType,
    onChangeProcedureId,
    onChangeCreationDate,
    onChangeEditionDate,
    onChangeValidation,
    onApplyFilters,
    onClearFilters,
    onClearFilter,
    recoverFilterApplied,
  } = useFilters();

  const [tasksFiltered, setTasksFiltered] = useState<Task[]>(() =>
    getCompletedTasksFiltered(tasks, showDeleted, filtersApplied, searchName)
  );

  useEffect(() => {
    setTasksFiltered(() =>
      getCompletedTasksFiltered(tasks, showDeleted, filtersApplied, searchName)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, showDeleted, filtersApplied]);

  const onOpenFiltersForm = () => setOpenFiltersForm(true);
  const onCloseFiltersForm = () => setOpenFiltersForm(false);

  const onToggleDeletedTasks = () => setShowDeleted(!showDeleted);

  const debouncedSearch = debounce((searchText: string) => {
    setTasksFiltered(() =>
      getCompletedTasksFiltered(tasks, showDeleted, filtersApplied, searchText)
    );
  }, 300);

  const onChangeSearchName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
    debouncedSearch(e.target.value);
  };

  const tasksCardToShow = getSortedTasks(tasks);

  const hasFiltersApplied =
    filtersApplied.length > 1 || searchName.trim() !== "";

  const hasOnlyCompletedDeletedTasks = tasksFiltered.every(
    (task) => task.deleted_at
  );

  return (
    <>
      <CardsList tasks={tasksCardToShow} />
      <TasksTable
        title={() => (
          <CompletedHeader
            onChangeSearchName={onChangeSearchName}
            searchName={searchName}
            filtersApplied={filtersApplied}
            onClearFilters={onClearFilters}
            onClearFilter={onClearFilter}
            onOpenFiltersForm={onOpenFiltersForm}
            tasks={tasksFiltered}
            showDeleted={showDeleted}
            onToggleDeletedTasks={onToggleDeletedTasks}
          />
        )}
        size="small"
        columns={completedColumns}
        dataSource={tasksFiltered}
        rowKey="id"
        locale={{
          emptyText: () =>
            (hasFiltersApplied && tasksFiltered.length === 0) ||
            hasOnlyCompletedDeletedTasks ? (
              <EmptyFilters />
            ) : (
              <EmptyTasks tabSelected="completed" />
            ),
        }}
        expandable={{
          expandedRowRender: (task: Task) => (
            <ExpandedRowContent taskId={task.id} />
          ),
        }}
        onRow={(task) => {
          return {
            onClick: (e) => {
              // Arreglo para al clickar en row muestre las actions de las celdas
              const elementClicked = e.target as HTMLElement;
              const nodeName = elementClicked.nodeName;
              if (
                nodeName === "svg" ||
                nodeName.toUpperCase() === "BUTTON" ||
                nodeName === "path" ||
                nodeName === "LI" ||
                (nodeName === "SPAN" &&
                  elementClicked.parentNode?.nodeName === "LI")
              )
                return;
              navigate(`tasks/${task.id}`);
            },
          };
        }}
        rowClassName="customTableRow"
      />
      <ModalFormContainer
        centered
        footer={null}
        maskClosable={false}
        onCancel={onCloseFiltersForm}
        open={openFiltersForm}
        width={347}
      >
        <FiltersForm
          formFilters={formFilters}
          onClickApply={() => {
            onApplyFilters();
            onCloseFiltersForm();
          }}
          onClickCancel={() => {
            onCloseFiltersForm();
            filtersApplied.forEach(recoverFilterApplied);
          }}
          onChangeTaskIdFrom={onChangeTaskIdFrom}
          onChangeTaskIdTo={onChangeTaskIdTo}
          onChangeProduct={onChangeProduct}
          onChangeUser={onChangeUser}
          onChangeProcedureType={onChangeProcedureType}
          onChangeProcedureId={onChangeProcedureId}
          onChangeCreationDate={onChangeCreationDate}
          onChangeEditionDate={onChangeEditionDate}
          onChangeValidation={onChangeValidation}
        />
      </ModalFormContainer>
    </>
  );
};
export default TabCompleted;
