import { useEffect, useRef } from "react";

import { Maybe } from "../types/common";
import { Document } from "../types/documents";
import { Task } from "../types/task";

import { getInitialRedaction } from "../utils/pdfVisor";

const useRefProp = <T>(prop: T, task?: Task, docSelected?: Maybe<Document>) => {
  const propRef = useRef<T>(prop);

  useEffect(() => {
    propRef.current = prop;
  }, [prop]);

  useEffect(() => {
    if (!task || !docSelected) return;
    propRef.current = getInitialRedaction(task, docSelected) as T;
  }, [task, docSelected]);

  return { propRef };
};

export default useRefProp;
