import EmptyContainer from "./EmptyContainer";

import NoProcessingTasksIcon from "../icons/NoProcessingTasksIcon";

const EmptyTasks = ({
  tabSelected,
}: {
  tabSelected: "processing" | "completed";
}) => {
  const title =
    tabSelected === "completed"
      ? "No tasks completed"
      : "No tasks in processing";
  return (
    <EmptyContainer
      title={title}
      description='There are no tasks in queue. To get started, you can upload
    new documents by clicking on "Create tasks”.'
      image={<NoProcessingTasksIcon />}
    />
  );
};
export default EmptyTasks;
