import { useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";

import {
  useMasksContext,
  useMessageContext,
  useTaskContext,
  useWebViewerContext,
} from "../../providers";

import { handleDocumentLoaded, handleCustomHeader } from "../../utils/pdfVisor";

import { Maybe } from "../../types/common";
import { Document } from "../../types/documents";
import { Mask } from "../../types/masks";
import { Task } from "../../types/task";

import useRefProp from "../../hooks/useRefProp";

import styles from "./CustomWebViewer.module.css";

interface CustomWebViewerProps {
  docSelected: Maybe<Document>;
}

const CustomWebViewer = ({ docSelected }: CustomWebViewerProps) => {
  const viewerDiv = useRef<HTMLDivElement>(null);
  const beenInitialized = useRef(false);
  const initialDocUrl = docSelected?.temporal_download_url as string;

  const { instance, setInstance, loadDocument } = useWebViewerContext();

  const { task } = useTaskContext();
  const { masks } = useMasksContext();
  const { sendMessage } = useMessageContext();

  const { propRef: masksRef } = useRefProp<Mask[]>(masks);
  const { propRef: docSelectedRef } = useRefProp<Maybe<Document>>(docSelected);

  useEffect(() => {
    if (docSelected) {
      loadDocument(docSelected?.temporal_download_url);
      if (docSelected.type !== "RPP") {
        instance?.UI.disableElements(["downloadDocument"]);
      } else {
        instance?.UI.enableElements(["downloadDocument"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docSelected]);

  useEffect(() => {
    if (!viewerDiv.current || beenInitialized.current) return;
    beenInitialized.current = true;
    WebViewer(
      {
        path: "/lib",
        fullAPI: true,
        enableRedaction: true,
        licenseKey: process.env.REACT_APP_APRYSE_WEBVIEWER_KEY,
        disabledElements: [
          "toolsHeader",
          "redactionPanel",
          "textPopup",
          "markReplaceTextToolButton",
          "markInsertTextToolButton",
          "freeTextToolButton",
          "freeHandHighlightToolButton",
          "freeHandToolButton",
          "highlightToolButton",
          "stickyToolButton",
          "annotationRedactButton",
          "annotationStyleEditButton",
          "annotationCommentButton",
        ],
        css: "/webViewerStyles.css",
      },
      viewerDiv.current
    ).then((instance) => {
      setInstance(instance);
      instance.UI.loadDocument(initialDocUrl);

      task?.deleted_at !== null &&
        instance.UI.disableElements(["annotationPopup"]);

      instance.UI.Fonts.addAnnotationFont("Arial");

      instance.UI.addEventListener(instance.UI.Events.VIEWER_LOADED, () => {
        handleCustomHeader(
          instance,
          docSelectedRef,
          (task as Task).id,
          sendMessage
        );
      });

      instance.UI.addEventListener(instance.UI.Events.DOCUMENT_LOADED, () => {
        handleDocumentLoaded(masksRef.current, instance);
        handleCustomHeader(
          instance,
          docSelectedRef,
          (task as Task).id,
          sendMessage
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="viewer" className={styles.documentViewer} ref={viewerDiv}></div>
  );
};
export default CustomWebViewer;
