import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import { HomeOutlined, PaperClipOutlined } from "@ant-design/icons";

import { Maybe } from "../../../types/common";
import { ARFAR, Document, RPPJTS } from "../../../types/documents";
import { MenuItem, RPPGroupSelected } from "../../../types/documentsViewer";
import { Task } from "../../../types/task";

import MaskedIcon from "../../../components/icons/MaskedIcon";
import StatusTag from "../../../components/Tag/StatusTag";
import TooltipedElement from "../../../components/TooltipedElement";

const hasMoreThanTwoNumbersAndTwoHyphens = (text: string): boolean => {
  const numberCount = (text.match(/\d/g) || []).length;
  const hyphenCount = (text.match(/-/g) || []).length;

  return numberCount > 2 && hyphenCount > 1;
};

const getRPPMenuItem = (
  document: Document,
  rppGroupSelected: Maybe<RPPGroupSelected>
) => {
  const isDocumentSelectedParent =
    rppGroupSelected?.clinicalParent === document.id ||
    rppGroupSelected?.justificationParent === document.id;
  const hasToFocus = isDocumentSelectedParent;

  if (document.type === "RPP")
    return {
      ...getDocumentItem(document),
      icon: (
        <span>
          <MaskedIcon width={14} height={14} fill="#222223" />
        </span>
      ),
      className: hasToFocus ? "customMenuItemFocus" : "",
    };
  return {
    ...getDocumentItem(document),
    className: hasToFocus ? "customMenuItemFocus" : "",
  };
};

const DocumentItem = ({ document }: { document: Document }) => {
  const needsTooltip =
    document.name.length > 30 ||
    (document.name.length >= 25 &&
      hasMoreThanTwoNumbersAndTwoHyphens(document.name));

  const baseItem = <span className="base normal">{document.name}</span>;

  return needsTooltip ? (
    <Tooltip placement="topLeft" title={document.name}>
      {baseItem}
    </Tooltip>
  ) : (
    baseItem
  );
};

const getDocumentItem = (document: Document) => {
  return {
    key: document.id,
    icon: <PaperClipOutlined style={{ fontSize: "14px" }} />,
    label: <DocumentItem document={document} />,
  };
};

const getRPPGroup = (
  rppJT: RPPJTS[],
  rppGroupSelected: Maybe<RPPGroupSelected>
) => {
  const rppGroup: MenuItem[] = [];
  rppJT.forEach((rppJTBlock) => {
    const cfDocument = rppJTBlock.ORIGINAL_RPP as Document;
    const jtDocument = rppJTBlock.JT as Document;
    const cfMenuItem = getRPPMenuItem(cfDocument, rppGroupSelected);
    rppGroup.push(cfMenuItem);
    if (jtDocument) {
      const jtMenuItem = getRPPMenuItem(jtDocument, rppGroupSelected);
      rppGroup.push(jtMenuItem);
    }
  });
  return rppGroup;
};

const getRPP = (
  task: Task | null,
  rppGroupSelected: Maybe<RPPGroupSelected>
): MenuItem => {
  if (!task)
    return {
      label: "Clinical Documents & Justification Table",
      type: "group",
      className: "customMenuGroup",
    };
  return {
    label: "Clinical Documents & Justification Table",
    type: "group",
    className: "customMenuGroup",
    children: getRPPGroup(task.documents.RPP_JT, rppGroupSelected),
  };
};

const getARFARGroup = (arFar: ARFAR) => {
  if (!arFar.AR || !arFar.FAR) return [];
  return [getDocumentItem(arFar.AR), getDocumentItem(arFar.FAR)];
};

const getAR = (task: Task | null): MenuItem => {
  if (!task)
    return {
      label: "Anonymization report",
      type: "group",
      className: "customMenuGroup",
    };
  return {
    label: "Anonymization report",
    type: "group",
    className: "customMenuGroup",
    children: getARFARGroup(task.documents.AR_FAR),
  };
};

const getFileItems = (
  task: Task | null,
  rppGroupSelected: Maybe<RPPGroupSelected>,
  menuCollapsed: boolean
): MenuItem => {
  return {
    type: "group",
    className: `leftaside-container ${menuCollapsed ? "collapsed" : ""}`,
    children: [getRPP(task, rppGroupSelected), getAR(task)],
  };
};

const getHeaderItems = (task: Task | null): MenuItem[] => {
  const isValidated = Boolean(task?.validated_at && task.validated_by);
  return [
    {
      key: "top",
      icon: (
        <Link to="/">
          <span>
            <HomeOutlined style={{ fontSize: "14px" }} />
          </span>
        </Link>
      ),
      className: "customMenuHeader",
      label: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TooltipedElement>
            <p
              className="m-0"
              style={{
                maxWidth: "170px",
                overflowX: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {task?.name}
            </p>
          </TooltipedElement>
          <StatusTag isValidated={isValidated} tooltipPlacement="right" />
        </span>
      ),
      disabled: true,
    },
    { type: "divider" },
  ];
};

const getMenuItems = (
  task: Task | null,
  rppGroupSelected: Maybe<RPPGroupSelected>,
  menuCollapsed: boolean
) => {
  if (!task) return [];

  return [
    ...getHeaderItems(task),
    getFileItems(task, rppGroupSelected, menuCollapsed),
  ];
};

export default getMenuItems;
