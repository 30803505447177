export class CustomError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    Error.captureStackTrace(this, this.constructor);
  }
}

export class ErrorsHandler {
  static throwBadRequest(message = "Bad Request"): never {
    throw new CustomError(message, 400);
  }
  static throwAbortError(
    message = "Fetch request was aborted due to timeout"
  ): never {
    throw new CustomError(message, 400);
  }
  static throwUnauthorized(message = "Unauthorized"): never {
    throw new CustomError(message, 401);
  }
  static throwForbidden(message = "Forbidden"): never {
    throw new CustomError(message, 403);
  }
  static throwNotFound(message = "Not Found"): never {
    throw new CustomError(message, 404);
  }
  static throwUnprocessableEntity(message = "UnprocessableEntity"): never {
    throw new CustomError(message, 422);
  }
  static throwUnexpectedError(
    message = "Unexpected error, contact support"
  ): never {
    throw new CustomError(message, 500);
  }
  static throwErrorByCode(statusCode: number, message?: string): never {
    switch (statusCode) {
      case 400:
        this.throwBadRequest(message);
        break;
      case 401:
        this.throwUnauthorized(message);
        break;
      case 403:
        this.throwForbidden(message);
        break;
      case 404:
        this.throwNotFound(message);
        break;
      case 422:
        this.throwUnprocessableEntity(message);
        break;
      default:
        this.throwUnexpectedError(message);
        break;
    }
  }
  static throwCustomError(message: string, statusCode: number): never {
    throw new CustomError(message, statusCode);
  }
}
