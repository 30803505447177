import { ChangeEvent } from "react";
import { Button, Input, Switch } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";

import { Task } from "../../../types/task";
import { OptionalFormFilters } from "../../../types/formFilters";

import { getTasksError, getTasksPendingAndRunning } from "../../../utils/tasks";

import TagsList from "../TagsList/TagsList";

import styles from "./ProcessingHeader.module.css";

interface ProcessingHeaderProps {
  tasks: Task[];
  filtersApplied: OptionalFormFilters[];
  searchName: string;
  onOpenFiltersForm: () => void;
  onClearFilters: () => void;
  onClearFilter: (e: React.MouseEvent<HTMLElement>) => void;
  onChangeSearchName: (e: ChangeEvent<HTMLInputElement>) => void;
  showDeleted: boolean;
  onToggleDeletedTasks: () => void;
}

const ProcessingHeader = ({
  tasks,
  filtersApplied,
  searchName,
  onOpenFiltersForm,
  onClearFilters,
  onClearFilter,
  onChangeSearchName,
  showDeleted,
  onToggleDeletedTasks,
}: ProcessingHeaderProps) => {
  const numTasksError = getTasksError(tasks).length;
  const tasksDeleted = tasks.filter((task) => task.deleted_at !== null).length;
  const numTasksProcessing = getTasksPendingAndRunning(tasks).length;
  return (
    <header className={styles.tableHeader}>
      <h3 className={`lg normal ${styles.tableHeaderTitle}`}>
        Task list ·{" "}
        <span className={`base normal ${styles.tableHeaderStatus}`}>
          {numTasksProcessing} uploading / {numTasksError} error{" "}
          {showDeleted && `/ ${tasksDeleted} deleted`}
        </span>
      </h3>
      <div className={styles.tableHeaderActions}>
        <Switch onClick={onToggleDeletedTasks} size="small" />
        <span style={{ whiteSpace: "nowrap" }} className="base normal">
          Show deleted
        </span>
        <Input
          value={searchName}
          onChange={onChangeSearchName}
          prefix={<SearchOutlined />}
          placeholder="Search by task name"
        />
      </div>
      <Button onClick={onOpenFiltersForm} icon={<FilterOutlined />} />
      <TagsList
        onClearFilters={onClearFilters}
        onClearFilter={onClearFilter}
        filtersApplied={filtersApplied}
      />
    </header>
  );
};
export default ProcessingHeader;
