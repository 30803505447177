import { useState } from "react";

import { Button, Form, Upload } from "antd";
import { CloseCircleFilled, UploadOutlined } from "@ant-design/icons";

import { UploadItemProps } from "./interfaces";

import { generateRandomId } from "../../utils/common";

import { onCustomUploadFile } from "./customRequest";

import LoadingFile from "./LoadingFile";
import DocumentName from "./DocumentName";

import styles from "./UploadItem.module.css";

const UploadItem = ({
  label,
  errors,
  uploadProps,
  documentName,
  fileChildren,
  onUploadCompleted,
  onUploadFailed,
  closeBlock,
}: UploadItemProps) => {
  const [isUploading, setIsUploading] = useState(false);

  const uploadText = documentName ? "Replace" : "Upload";

  return (
    <div
      className={`${styles.uploadItem} ${
        closeBlock && styles.uploadItemWithClose
      }`}
    >
      {closeBlock}
      <Form.Item required label={label}>
        <Upload
          showUploadList={false}
          onChange={(info) => {
            if (info.file.status === "uploading") {
              setIsUploading(true);
            }
            if (info.file.status === "done") {
              setIsUploading(false);
              onUploadCompleted && onUploadCompleted(info);
            } else if (info.file.status === "error") {
              setIsUploading(false);
              onUploadFailed && onUploadFailed(info);
            }
          }}
          customRequest={onCustomUploadFile}
          {...uploadProps}
        >
          <Button icon={<UploadOutlined />}>{uploadText}</Button>
        </Upload>
      </Form.Item>
      {isUploading && <LoadingFile />}
      {!isUploading && documentName && <DocumentName docName={documentName} />}
      {errors?.map(
        (error) =>
          error.hasError && (
            <p
              key={generateRandomId()}
              className={`base normal ${styles.uploadItemError}`}
            >
              <CloseCircleFilled
                style={{ color: "#FF4D4F", fontSize: "16px" }}
              />
              {error.message}
            </p>
          )
      )}
      {fileChildren}
    </div>
  );
};
export default UploadItem;
