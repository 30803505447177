import { Maybe } from "../../../types/common";
import { Mask, MaskStatus, MaskType } from "../../../types/masks";
import { MasksFilters } from "../../../types/providers";

export const getMasksList = (
  masks: Mask[],
  filtersApplied: MasksFilters,
  searchText = ""
) => {
  const masksSorted = getMasksSorted(masks);
  return getMasksFiltered(filtersApplied, masksSorted, searchText);
};

export const getMasksFiltered = (
  filtersApplied: MasksFilters,
  masks: Mask[],
  searchText: string
) => {
  let filteredMasks = masks;

  if (filtersApplied.maskType && filtersApplied.maskType.length > 0) {
    filteredMasks = filterByMaskType(filtersApplied.maskType, filteredMasks);
  }

  if (
    filtersApplied.feedbackStatus &&
    filtersApplied.feedbackStatus.length > 0
  ) {
    filteredMasks = filterByFeedbackStatus(
      filtersApplied.feedbackStatus,
      filteredMasks
    );
  }

  if (typeof filtersApplied.createdByUser === "boolean") {
    filteredMasks = filteredMasks.filter(
      (mask) => Boolean(mask.created_by) === filtersApplied.createdByUser
    );
  }

  if (filtersApplied.page.from || filtersApplied.page.to) {
    filteredMasks = filterByPageRange(filtersApplied.page, filteredMasks);
  }

  if (searchText.trim().length > 0) {
    filteredMasks = filterByText(filteredMasks, searchText);
  }

  return filteredMasks;
};

const filterByMaskType = (maskTypes: MaskType[], masks: Mask[]): Mask[] => {
  return masks.filter((mask) => maskTypes.includes(mask.type));
};

const filterByFeedbackStatus = (
  feedbackStatuses: MaskStatus[],
  masks: Mask[]
): Mask[] => {
  return masks.filter((mask) => feedbackStatuses.includes(mask.status));
};

const filterByPageRange = (
  page: { from: Maybe<number>; to: Maybe<number> },
  masks: Mask[]
): Mask[] => {
  return masks.filter((mask) => {
    if (mask.occurrences.length === 0) return false;

    return mask.occurrences.some((occurrence) => {
      const fromCondition =
        page.from && page.from !== undefined
          ? occurrence.page >= page.from
          : true;
      const toCondition =
        page.to && page.to !== undefined ? occurrence.page <= page.to : true;
      return fromCondition && toCondition;
    });
  });
};

const filterByText = (masks: Mask[], searchText: string) => {
  return masks.filter((mask) =>
    mask.name.toLowerCase().includes(searchText.toLowerCase())
  );
};

export const getMasksSorted = (masks: Mask[]): Mask[] => {
  masks.sort((a, b) => {
    const aHasOccurrences = a.occurrences.length > 0;
    const bHasOccurrences = b.occurrences.length > 0;

    if (!aHasOccurrences && !bHasOccurrences) return 0;
    if (!aHasOccurrences) return 1;
    if (!bHasOccurrences) return -1;

    const aHasQuads = a.occurrences[0].quads.length > 0;
    const bHasQuads = b.occurrences[0].quads.length > 0;

    if (!aHasQuads && !bHasQuads) return 0;
    if (!aHasQuads) return 1;
    if (!bHasQuads) return -1;

    const pageComparison = a.occurrences[0].page - b.occurrences[0].page;
    if (pageComparison !== 0) return pageComparison;

    return a.occurrences[0].quads[0].y4 - b.occurrences[0].quads[0].y4;
  });
  return masks;
};
