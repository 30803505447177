import { Modal, ModalProps } from "antd";

const modalStyles = {
  mask: { opacity: 0.8 },
};

interface ModalContainerProps extends ModalProps {
  title?: string;
}

const ModalContainer = ({ title, ...props }: ModalContainerProps) => {
  return (
    <Modal styles={modalStyles} centered footer={null} {...props}>
      {title && <h4 className="lg strong">{title}</h4>}
      {props.children}
    </Modal>
  );
};
export default ModalContainer;
