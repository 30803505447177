import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

import {
  MasksFiltersContextProvider,
  WebViewerProvider,
  useMasksContext,
  useMessageContext,
  useModalContext,
  useTaskContext,
} from "../../providers";

import { PATH_ROUTES } from "../../router/routes";

import { RPPGroupSelected } from "../../types/documentsViewer";
import { Maybe } from "../../types/common";
import { Document } from "../../types/documents";
import { Notification, Task } from "../../types/task";

import getRppBlockSelected from "./utils/getRppBlockSelected";

import CustomWebViewer from "./CustomWebViewer";

import LeftAside from "./LeftAside/LeftAside";
import RightAside from "./RightAside/RightAside";

import FiltersMasksModal from "../../components/Modals/FiltersMasks/FiltersMasks";
import DeleteMassiveOccurrences from "../../components/Modals/DeleteMassiveOccurrences/DeleteMassiveOccurrences";
import ValidateTaskPopup from "../../components/Modals/ValidateTask/ValidateTask";

import styles from "./DocumentsViewer.module.css";

const getNotificationsFromTask = (
  task: Task,
  documentId: Maybe<string>
): Notification[] => {
  if (!documentId) return [];
  const results = task.results?.find(
    (result) => result.document === documentId
  );

  return results?.notifications ?? [];
};

const DocumentsViewer = () => {
  const navigate = useNavigate();

  const { task, isLoading, error } = useTaskContext();
  const { masks, getApiMasks } = useMasksContext();
  const { sendMessage } = useMessageContext();
  const { docSelected, setDocument, setTask } = useModalContext();

  const [rppGroupSelected, setRppGroupSelected] =
    useState<Maybe<RPPGroupSelected>>(null);

  const [masksNotifications, setMasksNotifications] = useState<Notification[]>(
    []
  );

  const previousTask = useRef<Task | null>(null);

  useEffect(() => {
    const onHandleInitialData = async (task: Task) => {
      const targetDocSelected = docSelected || task.documents.RPP_JT[0].RPP;
      const originalRPP = task.documents.RPP_JT[0].ORIGINAL_RPP;
      setDocument(targetDocSelected);
      setTask(task);
      if (targetDocSelected !== docSelected) {
        setRppGroupSelected({
          docTypeSelected: originalRPP?.type ?? "",
          clinicalParent: originalRPP?.id ?? "",
          justificationParent: task.documents.RPP_JT[0].JT?.id ?? "",
        });
        await getApiMasks(task.id, targetDocSelected?.id as string);
      }
      setMasksNotifications(
        getNotificationsFromTask(task, targetDocSelected?.id)
      );
      task.deleted_at !== null &&
        sendMessage(
          "This task has been deleted. You cannot perform any actions on it.",
          "info",
          3.5
        );
    };

    if (task) {
      previousTask.current = task;
      onHandleInitialData(task);
    }

    return () => setDocument(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onChangeDocSelected = async (docSelected: Document, task: Task) => {
    const isClinicalFileSelected = docSelected.type === "RPP";
    const isRppFile = isClinicalFileSelected || docSelected.type === "JT";

    if (isRppFile) {
      const rppBlockSelected = getRppBlockSelected(
        docSelected,
        task.documents.RPP_JT
      );
      setRppGroupSelected({
        docTypeSelected: docSelected.type,
        clinicalParent: rppBlockSelected?.ORIGINAL_RPP?.id ?? "",
        justificationParent: rppBlockSelected?.JT?.id ?? "",
      });
    } else {
      setRppGroupSelected(null);
    }

    if (isClinicalFileSelected) {
      try {
        await getApiMasks(task.id, docSelected.id);
        setMasksNotifications(getNotificationsFromTask(task, docSelected.id));
      } catch (err) {
        console.error(err);
      }
    }

    setDocument(docSelected);
  };

  if (isLoading) return <Spin tip="Loading" spinning={isLoading} fullscreen />;

  if (error) {
    if (error.statusCode === 400) sendMessage("Invalid task", "error", 2.5);
    if (error.statusCode === 404) sendMessage("Task not found", "error", 2.5);
    if (error.statusCode === 422) sendMessage("Invalid Id", "error", 2.5);
    navigate(PATH_ROUTES.home);
    return null;
  }

  return (
    <WebViewerProvider>
      <div className={styles.container}>
        <LeftAside
          task={task as Task}
          docSelected={docSelected}
          onChangeDocSelected={onChangeDocSelected}
          rppGroupSelected={rppGroupSelected}
        />
        {docSelected && <CustomWebViewer docSelected={docSelected} />}
        <MasksFiltersContextProvider>
          {docSelected?.type === "RPP" && (
            <RightAside masks={masks} masksNotifications={masksNotifications} />
          )}
          <FiltersMasksModal />
        </MasksFiltersContextProvider>
      </div>
      <DeleteMassiveOccurrences />
      <ValidateTaskPopup />
    </WebViewerProvider>
  );
};
export default DocumentsViewer;
