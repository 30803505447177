import { FormRule } from "antd";
import { FormValues } from "./interfaces";

export const isValidUser = ({ email, password }: FormValues) => {
  return email === "hipra.masking@omnios.com" && password === "TBD*";
};

export const emailRules: FormRule[] = [
  { required: true, message: "Email is required" },
  {
    type: "email",
    message: "Not a valid email",
  },
];

export const passwordRules: FormRule[] = [
  { required: true, message: "Password is required" },
];
