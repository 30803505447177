import { Spin } from "antd";

import styles from "./Loader.module.css";

const Loader = () => {
  return (
    <Spin size="large">
      <span className={`${styles.loaderText} xl strong`}>Loading task...</span>
    </Spin>
  );
};

export default Loader;
