import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { redactionsFetcher } from "../services/api";

import { Mask } from "../types/masks";
import { MasksContextType } from "../types/providers";

export const MasksContext = createContext<MasksContextType | null>(null);

const removeDuplicates = (arr: Mask[]) => {
  const uniqueObjects = new Map();

  arr.forEach((obj) => {
    if (!uniqueObjects.has(obj.id)) {
      uniqueObjects.set(obj.id, obj);
    }
  });

  return Array.from(uniqueObjects.values());
};

const MasksContextProvider = ({ children }: { children: ReactNode }) => {
  const [masks, setMasks] = useState<Mask[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getApiMasks = async (taskId: number, documentId: string) => {
    let masks: Mask[] = [];
    setIsLoading(true);
    try {
      masks = await redactionsFetcher.getMasks(taskId, documentId);
      // Quitar removeDuplicates cuando las id's de las mascaras no se repitan
      masks = removeDuplicates(masks);
    } catch (error) {
      console.error("Error fetching masks:", error);
    } finally {
      setIsLoading(false);
    }
    setMasks(masks);
  };

  const addMasks = (masks: Mask[]) => setMasks(masks);

  const contextValue = useMemo(
    () => ({
      masks,
      isLoading,
      getApiMasks,
      addMasks,
    }),
    [masks, isLoading]
  );

  return (
    <MasksContext.Provider value={contextValue}>
      {children}
    </MasksContext.Provider>
  );
};

export const useMasksContext = () => {
  const context = useContext(MasksContext);

  if (!context)
    throw new Error(
      "useMasksContext must be used within a MasksContextProvider"
    );

  return context;
};

export default MasksContextProvider;
