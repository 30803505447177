import { Spin } from "antd";

import styles from "./ProcessingStatusRow.module.css";

const StatusLoader = () => {
  return (
    <div className={styles.statusLoaderContainer}>
      <Spin />
      <p className="base m-0">Loading status...</p>
    </div>
  );
};

export default StatusLoader;
