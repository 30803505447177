import { TagProps } from "antd";
import { Dayjs } from "dayjs";

import FilterTag from "./FilterTag";

interface DateTagProps extends TagProps {
  name: string;
  dateTypeText: string;
  from: Dayjs | null;
  to: Dayjs | null;
}

const getDateTagText = (
  dateTypeText: string,
  from: Dayjs | null,
  to: Dayjs | null
) => {
  let tagText = "";
  if (!from) tagText = `${dateTypeText} to: ${to?.format("DD-MM")}`;
  if (!to) tagText = `${dateTypeText} from: ${from?.format("DD-MM")}`;
  if (from && to)
    tagText = `${dateTypeText} from: ${from?.format("DD-MM")} to ${to?.format(
      "DD-MM-YYYY"
    )}`;
  return tagText;
};

const DateTag = ({
  name,
  dateTypeText,
  from,
  to,
  ...filterProps
}: DateTagProps) => {
  if (!from && !to) return null;
  const tagText = getDateTagText(dateTypeText, from, to);
  return (
    <FilterTag name={name} {...filterProps}>
      {tagText}
    </FilterTag>
  );
};
export default DateTag;
