import { HTMLAttributes, useRef } from "react";

// Componente para manejar el error por consola de findDOMNode
// https://ant.design/components/tooltip#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode

const IconWrapped = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={divRef} {...props}>
      {children}
    </div>
  );
};

export default IconWrapped;
