import { BaseFetcher } from "./BaseFetcher";

import { MasterData, MasterDataItem } from "../../types/masterData";
import { User } from "../../types/task";

class MasterDataFetch extends BaseFetcher {
  async getMasterData(): Promise<MasterData> {
    try {
      const procedureTypes = await this.getProcedureTypes();
      const products = await this.getProducts();
      const users = await this.getUsers();
      return { procedureTypes, products, users };
    } catch (error) {
      console.error("Get masterdata failed:", error);
      return { procedureTypes: [], products: [], users: [] };
    }
  }

  async getProducts(): Promise<MasterDataItem[]> {
    const { data: products } = await this.apiFetcher.callApi<MasterDataItem[]>({
      endpoint: "products",
    });
    return products;
  }

  async getProcedureTypes() {
    const { data: procedureTypes } = await this.apiFetcher.callApi<
      MasterDataItem[]
    >({
      endpoint: "procedures",
    });
    return procedureTypes;
  }

  async getUsers(): Promise<User[]> {
    const { data: users } = await this.apiFetcher.callApi<User[]>({
      endpoint: "users",
    });
    return users;
  }
}

const masterDataFetcher = new MasterDataFetch();

export default masterDataFetcher;
