import { Alert } from "antd";

import { TaskError } from "../../../types/task";

import { getProcessingTaskError } from "../../../utils/tasks";

const ErrorBlock = ({ taskError }: { taskError: TaskError }) => {
  const error = getProcessingTaskError(taskError);
  return (
    <Alert
      message={<h5 className="base strong">{error.type}</h5>}
      description={<p className="base normal m-0">{error.message}</p>}
      type="error"
    />
  );
};
export default ErrorBlock;
