import { ReactNode, useState, createContext, useMemo, useContext } from "react";
import { WebViewerInstance } from "@pdftron/webviewer";

import { WebViewerContextType } from "./interfaces";

export const WebViewerContext = createContext<WebViewerContextType | null>(
  null
);

const WebViewerContextProvider = ({ children }: { children: ReactNode }) => {
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);

  const contextValue = useMemo(
    () => ({
      instance,
      setInstance,
      loadDocument: (docUrl: string) => instance?.UI.loadDocument(docUrl),
      goToAnnotation: (maskId: number, occurrenceId: number) => {
        if (!instance) return;
        const annotations = instance.Core.annotationManager
          .getAnnotationsList()
          .filter(
            (annot) =>
              annot instanceof instance.Core.Annotations.RedactionAnnotation
          );

        const matchingAnnotations = annotations.filter((annot) => {
          const annotMaskId = annot.getCustomData("mask_id");
          const annotOccurrenceId = annot.getCustomData("occurrence_id");

          return (
            annotMaskId === maskId.toString() &&
            annotOccurrenceId === occurrenceId.toString()
          );
        });

        if (matchingAnnotations.length === 0) return;

        const {
          Core: { annotationManager },
        } = instance;

        annotationManager.deselectAllAnnotations();
        annotationManager.jumpToAnnotation(matchingAnnotations[0]);
        matchingAnnotations.forEach((annot) =>
          annotationManager.selectAnnotation(annot)
        );
      },
    }),
    [instance]
  );

  return (
    <WebViewerContext.Provider value={contextValue}>
      {children}
    </WebViewerContext.Provider>
  );
};

export const useWebViewerContext = () => {
  const context = useContext(WebViewerContext);

  if (!context)
    throw new Error(
      "useWebViewerContext must be used within a WebViewerContextProvider"
    );

  return context;
};

export default WebViewerContextProvider;
