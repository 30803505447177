import { Button } from "antd";

import { useMaskFormContext, useTaskContext } from "../../../providers";

import MaskedIcon from "../../../components/icons/MaskedIcon";
import MaskTag from "../../../components/Tag/MaskTag";

import AddMaskPopup from "./AddMaskPopup";

import styles from "./AddMask.module.css";

const AddMask = () => {
  const { showAddMask, showMaskSelected, freeText, onToggleShowAddMask } =
    useMaskFormContext();
  const { task } = useTaskContext();

  return (
    <div className={styles.container}>
      <Button
        onClick={onToggleShowAddMask}
        size="large"
        className={styles.maskButton}
        disabled={task?.deleted_at !== null}
        icon={<MaskedIcon fill="currentColor" width={16} height={16} />}
      >
        Add mask
      </Button>
      {!showAddMask && showMaskSelected !== "" && (
        <MaskTag maskType={showMaskSelected} hasFreeText={freeText !== ""} />
      )}
      {showAddMask && <AddMaskPopup />}
    </div>
  );
};
export default AddMask;
