import { useState } from "react";

import { useWebViewerContext } from "../../../providers";

import { Mask } from "../../../types/masks";

import EmptyMasks from "../../../components/Empty/EmptyMasks";
import EmptyMasksFiltered from "../../../components/Empty/EmptyMasksFiltered";
import FeedbackCard from "../../../components/FeedbackCard/FeedbackCard";

import styles from "./RightAside.module.css";

const MasksList = ({
  masksList,
  hasFiltersApplied,
}: {
  masksList: Mask[];
  hasFiltersApplied: boolean;
}) => {
  const [feedbackSelected, setFeedbackSelected] = useState<Mask | null>(null);

  const { goToAnnotation } = useWebViewerContext();

  const documentHasNoMasks = !hasFiltersApplied && masksList.length === 0;
  const filtersAppliedHasNoResults =
    hasFiltersApplied && masksList.length === 0;

  return (
    <>
      {documentHasNoMasks && <EmptyMasks />}
      {filtersAppliedHasNoResults && <EmptyMasksFiltered />}
      <ul className={styles.masksList}>
        {masksList.map((maskInfo) => (
          <FeedbackCard
            goToAnnotation={goToAnnotation}
            selected={maskInfo.id === feedbackSelected?.id}
            key={maskInfo.id}
            maskInfo={maskInfo}
            onClick={() => setFeedbackSelected(maskInfo)}
          />
        ))}
      </ul>
    </>
  );
};
export default MasksList;
