const NoProcessingTasksIcon = () => {
  return (
    <svg
      width="116"
      height="130"
      viewBox="0 0 116 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6708 23.3145H50.2813L73.439 46.4721V94.3022C73.439 95.9226 72.7953 97.4766 71.6495 98.6224C70.5037 99.7682 68.9497 100.412 67.3293 100.412H18.6708C17.0504 100.412 15.4963 99.7682 14.3505 98.6224C13.2047 97.4766 12.561 95.9226 12.561 94.3022V29.4242C12.561 27.8038 13.2047 26.2498 14.3505 25.104C15.4963 23.9582 17.0504 23.3145 18.6708 23.3145Z"
        fill="#F0F6FF"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-2-inside-1_856_28853" fill="white">
        <path d="M51.1099 21.3145V45.6437H75.4391" />
      </mask>
      <path d="M51.1099 21.3145V45.6437H75.4391" fill="#F0F6FF" />
      <path
        d="M55.1099 21.3145C55.1099 19.1053 53.319 17.3145 51.1099 17.3145C48.9007 17.3145 47.1099 19.1053 47.1099 21.3145H55.1099ZM51.1099 45.6437H47.1099C47.1099 47.8528 48.9007 49.6437 51.1099 49.6437V45.6437ZM75.4391 49.6437C77.6482 49.6437 79.4391 47.8528 79.4391 45.6437C79.4391 43.4346 77.6482 41.6437 75.4391 41.6437V49.6437ZM47.1099 21.3145V45.6437H55.1099V21.3145H47.1099ZM51.1099 49.6437H75.4391V41.6437H51.1099V49.6437Z"
        fill="white"
        mask="url(#path-2-inside-1_856_28853)"
      />
      <path
        d="M59.2197 65.918H26.7808"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2197 82.1377H26.7808"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-6-inside-2_856_28853" fill="white">
        <path d="M34.8905 49.6982H30.8356H26.7808" />
      </mask>
      <path d="M34.8905 49.6982H30.8356H26.7808" fill="#F0F6FF" />
      <path
        d="M34.8905 53.6982C37.0996 53.6982 38.8905 51.9074 38.8905 49.6982C38.8905 47.4891 37.0996 45.6982 34.8905 45.6982V53.6982ZM26.7808 45.6982C24.5716 45.6982 22.7808 47.4891 22.7808 49.6982C22.7808 51.9074 24.5716 53.6982 26.7808 53.6982V45.6982ZM34.8905 45.6982H30.8356V53.6982H34.8905V45.6982ZM30.8356 45.6982H26.7808V53.6982H30.8356V45.6982Z"
        fill="white"
        mask="url(#path-6-inside-2_856_28853)"
      />
      <path
        d="M18.6708 23.5942H50.2855L73.4391 46.5882V94.0787C73.4391 95.6798 72.7988 97.2181 71.6545 98.3545C70.5098 99.4914 68.9542 100.133 67.3294 100.133H18.6708C17.0459 100.133 15.4904 99.4914 14.3456 98.3545C13.2014 97.2181 12.561 95.6798 12.561 94.0787V29.6481C12.561 28.047 13.2014 26.5086 14.3456 25.3722C15.4904 24.2354 17.0459 23.5942 18.6708 23.5942Z"
        fill="#F0F6FF"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2831 20.4473H18.1504C15.9535 20.4473 13.8467 21.32 12.2933 22.8734C10.7399 24.4268 9.86719 26.5336 9.86719 28.7305V94.9959C9.86719 97.1928 10.7399 99.2996 12.2933 100.853C13.8467 102.406 15.9535 103.279 18.1504 103.279H67.8495C70.0463 103.279 72.1532 102.406 73.7066 100.853C75.26 99.2996 76.1327 97.1928 76.1327 94.9959V45.2968L51.2831 20.4473Z"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2832 20.4473V45.2968H76.1328"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5663 66.0049H26.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5663 82.5713H26.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7168 49.4385H30.5752H26.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2831 20.4473H18.1504C15.9535 20.4473 13.8467 21.32 12.2933 22.8734C10.7399 24.4268 9.86719 26.5336 9.86719 28.7305V94.9959C9.86719 97.1928 10.7399 99.2996 12.2933 100.853C13.8467 102.406 15.9535 103.279 18.1504 103.279H67.8495C70.0463 103.279 72.1532 102.406 73.7066 100.853C75.26 99.2996 76.1327 97.1928 76.1327 94.9959V45.2968L51.2831 20.4473Z"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5663 66.0049H26.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5663 82.5713H26.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7168 49.4385H30.5752H26.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2832 20.4473V45.2968H76.1328"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6708 7.34961H80.2813L103.439 30.5073V78.3373C103.439 79.9577 102.795 81.5118 101.649 82.6576C100.504 83.8034 98.9497 84.4471 97.3293 84.4471H48.6708C47.0504 84.4471 45.4963 83.8034 44.3505 82.6576C43.2047 81.5118 42.561 79.9577 42.561 78.3373V13.4594C42.561 11.8389 43.2047 10.2849 44.3505 9.13911C45.4963 7.99331 47.0504 7.34961 48.6708 7.34961Z"
        fill="#F0F6FF"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-20-inside-3_856_28853" fill="white">
        <path d="M81.1099 5.34961V29.6788H105.439" />
      </mask>
      <path d="M81.1099 5.34961V29.6788H105.439" fill="#F0F6FF" />
      <path
        d="M85.1099 5.34961C85.1099 3.14047 83.319 1.34961 81.1099 1.34961C78.9007 1.34961 77.1099 3.14047 77.1099 5.34961H85.1099ZM81.1099 29.6788H77.1099C77.1099 31.888 78.9007 33.6788 81.1099 33.6788V29.6788ZM105.439 33.6788C107.648 33.6788 109.439 31.888 109.439 29.6788C109.439 27.4697 107.648 25.6788 105.439 25.6788V33.6788ZM77.1099 5.34961V29.6788H85.1099V5.34961H77.1099ZM81.1099 33.6788H105.439V25.6788H81.1099V33.6788Z"
        fill="white"
        mask="url(#path-20-inside-3_856_28853)"
      />
      <path
        d="M89.2197 49.9531H56.7808"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.2197 66.1729H56.7808"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-24-inside-4_856_28853" fill="white">
        <path d="M64.8905 33.7334H60.8356H56.7808" />
      </mask>
      <path d="M64.8905 33.7334H60.8356H56.7808" fill="#F0F6FF" />
      <path
        d="M64.8905 37.7334C67.0996 37.7334 68.8905 35.9425 68.8905 33.7334C68.8905 31.5243 67.0996 29.7334 64.8905 29.7334V37.7334ZM56.7808 29.7334C54.5716 29.7334 52.7808 31.5243 52.7808 33.7334C52.7808 35.9425 54.5716 37.7334 56.7808 37.7334V29.7334ZM64.8905 29.7334H60.8356V37.7334H64.8905V29.7334ZM60.8356 29.7334H56.7808V37.7334H60.8356V29.7334Z"
        fill="white"
        mask="url(#path-24-inside-4_856_28853)"
      />
      <path
        d="M48.6708 7.62939H80.2855L103.439 30.6234V78.1139C103.439 79.715 102.799 81.2533 101.655 82.3897C100.51 83.5266 98.9542 84.1677 97.3294 84.1677H48.6708C47.0459 84.1677 45.4904 83.5266 44.3456 82.3897C43.2014 81.2533 42.561 79.715 42.561 78.1139V13.6832C42.561 12.0821 43.2014 10.5438 44.3456 9.40741C45.4904 8.27055 47.0459 7.62939 48.6708 7.62939Z"
        fill="#F0F6FF"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.2831 4.48242H48.1504C45.9535 4.48242 43.8467 5.35511 42.2933 6.90851C40.7399 8.46191 39.8672 10.5688 39.8672 12.7656V79.0311C39.8672 81.2279 40.7399 83.3348 42.2933 84.8882C43.8467 86.4416 45.9535 87.3143 48.1504 87.3143H97.8495C100.046 87.3143 102.153 86.4416 103.707 84.8882C105.26 83.3348 106.133 81.2279 106.133 79.0311V29.332L81.2831 4.48242Z"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.2832 4.48242V29.332H106.133"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.2831 4.48242H48.1504C45.9535 4.48242 43.8467 5.35511 42.2933 6.90851C40.7399 8.46191 39.8672 10.5688 39.8672 12.7656V79.0311C39.8672 81.2279 40.7399 83.3348 42.2933 84.8882C43.8467 86.4416 45.9535 87.3143 48.1504 87.3143H97.8495C100.046 87.3143 102.153 86.4416 103.707 84.8882C105.26 83.3348 106.133 81.2279 106.133 79.0311V29.332L81.2831 4.48242Z"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.2832 4.48242V29.332H106.133"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.7168 33.438H60.5752H56.4336"
        stroke="#0161FE"
        strokeWidth="3.10619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4121 49.6228C57.3053 48.6025 55.5885 48.6808 54.5775 49.7978C53.5664 50.9147 53.6441 52.6473 54.7508 53.6676L67.5252 65.4438C69.0747 66.8722 71.4783 66.7625 72.8937 65.1988C72.9529 65.1324 72.9529 65.1324 73.01 65.0641L91.2399 42.9378C92.1983 41.7745 92.0408 40.0475 90.8882 39.0803C89.7355 38.1131 88.0241 38.272 87.0657 39.4353L69.9267 60.2376L58.4121 49.6228Z"
        fill="white"
        stroke="#1677FF"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94 121.482C94 123.691 78.1066 125.482 58.5006 125.482C38.8945 125.482 23 123.691 23 121.482C23 119.274 38.8945 117.482 58.5006 117.482C78.1066 117.482 94 119.274 94 121.482Z"
        fill="#DBE9FF"
      />
    </svg>
  );
};
export default NoProcessingTasksIcon;
