import { Button, Form, Input, Radio, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { CloseOutlined } from "@ant-design/icons";

import { useMaskFormContext, useWebViewerContext } from "../../../providers";

import { startMaskingTool } from "../../../utils/pdfVisor";

import styles from "./AddMaskPopup.module.css";

const AddMaskPopup = () => {
  const [form] = useForm();

  const { instance } = useWebViewerContext();

  const {
    maskSelected,
    freeText,
    onChangeMask,
    onChangeFreeText,
    onAddMaskToShow,
    onToggleShowAddMask,
  } = useMaskFormContext();

  return (
    <div className={styles.container}>
      <Button
        className={styles.closeButton}
        type="text"
        onClick={onToggleShowAddMask}
        icon={<CloseOutlined />}
      />
      <Form
        className="maskForm"
        form={form}
        layout="vertical"
        initialValues={{
          maskSelected,
          freeText,
        }}
      >
        <Form.Item
          name="maskSelected"
          label={
            <Typography className="base normal">Select type of mask</Typography>
          }
        >
          <Radio.Group
            onChange={onChangeMask}
            // defaultValue={maskSelected}
            value={maskSelected}
          >
            <Radio checked value="CCI">
              CCI
            </Radio>
            <Radio value="PPD">PPD</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="freeText"
          label={
            <label>
              Add additional text{" "}
              <span className={styles.optionalText}>(optional)</span>
            </label>
          }
        >
          <Input
            size="large"
            placeholder="Type free text"
            value={freeText}
            onChange={onChangeFreeText}
            className={styles.freeTextInput}
          />
        </Form.Item>
        <footer className={styles.footer}>
          <Button
            onClick={() => {
              onToggleShowAddMask();
            }}
            block
            type="text"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onAddMaskToShow(maskSelected);
              startMaskingTool(instance, {
                maskType: maskSelected,
                additionalText: freeText,
              });
              onToggleShowAddMask();
            }}
            block
            type="primary"
          >
            Add
          </Button>
        </footer>
      </Form>
    </div>
  );
};
export default AddMaskPopup;
