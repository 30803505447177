import { Navigate, Outlet } from "react-router-dom";
import { Spin } from "antd";

import { useAuthContext } from "../providers";

import { PATH_ROUTES } from "./routes";

const ProtectedRoute = () => {
  const { user, isLoading } = useAuthContext();

  if (isLoading) return <Spin tip="Loading" spinning={isLoading} fullscreen />;

  return user ? <Outlet /> : <Navigate to={PATH_ROUTES.login} />;
};
export default ProtectedRoute;
