import { QuestionOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Dropdown, MenuProps, Tooltip } from "antd";

import styles from "./Buttons.module.css";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <a href="mailto:hipra.masking@omnios.ai">Contact support</a>,
  },
  {
    type: "divider",
  },
  {
    key: "2",
    label: (
      <a
        className={styles.omniosLink}
        href="https://omnios.ai/"
        target="_blank"
        rel="noreferrer"
      >
        © 2024 • Omnios
      </a>
    ),
  },
];

const HelpButton = ({ ...buttonProps }: ButtonProps) => {
  return (
    <Dropdown placement="topLeft" menu={{ items }} trigger={["click"]}>
      <Tooltip title="Help" placement="right">
        <Button size="small" icon={<QuestionOutlined />} {...buttonProps} />
      </Tooltip>
    </Dropdown>
  );
};
export default HelpButton;
