import { Modal, ModalProps } from "antd";

const modalStyles = {
  mask: { opacity: 0.8 },
};

const ModalFormContainer = ({ ...props }: ModalProps) => {
  return (
    <Modal
      className="filtersFormModal"
      styles={modalStyles}
      centered
      footer={null}
      maskClosable={false}
      width={347}
      {...props}
    >
      <h4 className="lg strong">Task list filter</h4>
      {props.children}
    </Modal>
  );
};
export default ModalFormContainer;
