import { TaskFormContextProvider } from "../../../providers";

import useTaskDocuments from "../../../hooks/useTaskDocuments";

import TaskForm from "../../Forms/Task/FormTask";

import ModalContainer from "../ModalContainer";
import Loader from "./Loader";

const UpdateTaskModal = ({
  taskId,
  isOpen,
  onCancel,
}: {
  taskId: number;
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const { task, isLoading } = useTaskDocuments(taskId);

  return (
    <ModalContainer
      destroyOnClose
      open={isOpen}
      onCancel={onCancel}
      wrapClassName="updateModalWrapper"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <TaskFormContextProvider initialTask={task}>
          <TaskForm taskToUpdate={task} formTaskType={"updateTask"} />
        </TaskFormContextProvider>
      )}
    </ModalContainer>
  );
};
export default UpdateTaskModal;
