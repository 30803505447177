import { Button, Checkbox, Col, Form, InputNumber, Radio, Row } from "antd";
import { useForm } from "antd/es/form/Form";

import { useModalContext } from "../../../providers";

import { MasksFilters } from "../../../types/providers";

import {
  FEEDBACK_STATUS_OPTIONS,
  MASK_TYPE_OPTIONS,
  USER_OPTIONS,
} from "./utils";

import { generateRandomId } from "../../../utils/common";

import useMasksFilters from "./useMasksFilters";

import MasksItem from "./MasksItem";

import styles from "./Masks.module.css";

interface MasksFiltersFormProps {
  filtersApplied: MasksFilters;
  onApplyFilters: (masksFilters: MasksFilters) => void;
}

const MasksFiltersForm = ({
  filtersApplied,
  onApplyFilters,
}: MasksFiltersFormProps) => {
  const [form] = useForm<MasksFilters>();

  const { closeModal } = useModalContext();

  const {
    formValues,
    onChangeMaskType,
    onChangeCreatedByUser,
    onChangeFeedbackStatus,
    onChangePageFrom,
    onChangePageTo,
  } = useMasksFilters(filtersApplied);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        maskType: formValues.maskType,
        createdByUser: formValues.createdByUser,
        feedbackStatus: formValues.feedbackStatus,
        pageFrom: formValues.page.from,
        pageTo: formValues.page.to,
      }}
    >
      <section className={styles.formSection}>
        <MasksItem label="Type of mask" name="maskType" valuePropName="checked">
          <Checkbox.Group
            options={MASK_TYPE_OPTIONS}
            value={formValues.maskType}
            onChange={onChangeMaskType}
          />
        </MasksItem>
        <MasksItem label="Created by user" name="createdByUser">
          <Radio.Group
            name="createdByUser"
            options={USER_OPTIONS}
            value={formValues.createdByUser}
            onChange={onChangeCreatedByUser}
          />
        </MasksItem>
        <MasksItem
          label="Feedback status"
          name="feedbackStatus"
          valuePropName="checked"
          className="masksFormItem feedback"
        >
          <Checkbox.Group
            value={formValues.feedbackStatus}
            onChange={onChangeFeedbackStatus}
          >
            <Row style={{ gap: ".5rem" }}>
              {FEEDBACK_STATUS_OPTIONS.map((option) => (
                <Col key={generateRandomId()} span={16}>
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </MasksItem>
        <MasksItem label="Page number">
          <Row style={{ marginTop: ".25rem" }} gutter={16}>
            <Col span={12}>
              <Form.Item
                name="pageFrom"
                className="customFormItem"
                dependencies={["pageTo"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const toValue = getFieldValue("pageTo");
                      if (value && toValue !== null && toValue < value) {
                        return Promise.reject(
                          new Error("From value must be lower than to")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  min={1}
                  className="w-full"
                  placeholder="From"
                  controls={false}
                  value={formValues.page.from}
                  onChange={onChangePageFrom}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pageTo"
                className="customFormItem"
                dependencies={["pageFrom"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && getFieldValue("pageFrom") > value) {
                        return Promise.reject(
                          new Error("To value must be higher than from")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  min={1}
                  className="w-full"
                  placeholder="To"
                  controls={false}
                  value={formValues.page.to}
                  onChange={onChangePageTo}
                />
              </Form.Item>
            </Col>
          </Row>
        </MasksItem>
      </section>
      <footer className={styles.footer}>
        <Button onClick={closeModal} block type="text">
          Cancel
        </Button>
        <Button
          block
          type="primary"
          onClick={async () => {
            try {
              await form.validateFields();
            } catch (error) {
              return;
            }

            onApplyFilters({
              createdByUser: form.getFieldValue("createdByUser"),
              feedbackStatus: form.getFieldValue("feedbackStatus"),
              maskType: form.getFieldValue("maskType"),
              page: {
                from: form.getFieldValue("pageFrom"),
                to: form.getFieldValue("pageTo"),
              },
            });
            closeModal();
          }}
        >
          Apply
        </Button>
      </footer>
    </Form>
  );
};

export default MasksFiltersForm;
