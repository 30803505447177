import { Button, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { useModalContext } from "../../providers";

import { Task } from "../../types/task";

const DeleteTaskButton = ({ task }: { task: Task }) => {
  const { setTask, openDeleteTask } = useModalContext();

  return (
    <Tooltip title="Delete task">
      <Button
        onClick={() => {
          setTask(task);
          openDeleteTask();
        }}
        type="text"
        icon={<DeleteOutlined />}
      />
    </Tooltip>
  );
};
export default DeleteTaskButton;
