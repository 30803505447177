import { UserOutlined } from "@ant-design/icons";

import { History, Occurrence } from "../../types/masks";

import { getActionMessage } from "../../utils/masks";

import styles from "./FeedbackCard.module.css";

const FeedbackAction = ({
  action,
  occurrences,
}: {
  action: History;
  occurrences: Occurrence[];
}) => {
  const actionMessage = getActionMessage(action, occurrences);

  return (
    <section className={styles.cardFooter}>
      <UserOutlined style={{ color: "#808085" }} />
      <p className={`base normal ${styles.cardFooterText}`}>{actionMessage}</p>
    </section>
  );
};
export default FeedbackAction;
