import { Avatar, Dropdown, MenuProps, theme } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";

import { useAuthContext } from "../../providers";

import hipraLogo from "../../assets/images/hipra-aimasking-logo-01.png";

import styles from "./Header.module.css";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <span className={styles.dropdownMenuItem}>
        <LogoutOutlined
          style={{ color: theme.getDesignToken().colorPrimary }}
        />
        Log out
      </span>
    ),
  },
];

const Header = () => {
  const { logout } = useAuthContext();

  const onAvatarClick: MenuProps["onClick"] = () => {
    logout();
  };
  return (
    <header className={styles.header}>
      <img className={styles.hipraLogo} src={hipraLogo} alt="Hipra Logo" />
      <h4 className={`head4 ${styles.title}`}>Task Management</h4>
      <Dropdown menu={{ items, onClick: onAvatarClick }} trigger={["click"]}>
        <Avatar style={{ cursor: "pointer" }} icon={<UserOutlined />} />
      </Dropdown>
    </header>
  );
};
export default Header;
