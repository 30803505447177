import { BaseFetcher } from "./BaseFetcher";

import { Document } from "../../types/documents";
import { ApiDownloadDocument } from "../../types/subtask";

type ApiDocument = {
  url: string;
  filename: string;
};

type ApiSubtaskId = {
  subtask_id: string
}

class DocumentsFetch extends BaseFetcher {
  async getDocumentUploadUrl(filename: string | null): Promise<string> {
    this.validateFilename(filename);

    const { data } = await this.apiFetcher.callApi<ApiDocument>({
      endpoint: `documents/upload?filename=${encodeURIComponent(
        filename as string
      )}`,
    });

    return data.url;
  }

  async getDocumentUrl(documentId: string): Promise<Document> {
    this.validateDocumentId(documentId);

    const { data: document } = await this.apiFetcher.callApi<Document>({
      endpoint: `documents/${documentId}`,
    });
    return document;
  }

  async getFlattenedDocument(
    taskId: number,
    documentId: string
  ): Promise<ApiSubtaskId> {
    this.validateTaskId(taskId);
    this.validateDocumentId(documentId);

    const { data } = await this.apiFetcher.callApi<ApiSubtaskId>({
      endpoint: `tasks/${taskId}/documents/${documentId}/flattened`,
      options: { method: "POST" },
    });
    return data;
  }

  async getAllTaskDocuments(
    taskId: number,
    only_flattened = false
  ): Promise<ApiDocument> {
    this.validateTaskId(taskId);

    const { data } = await this.apiFetcher.callApi<ApiDocument>({
      endpoint: `tasks/${taskId}/documents`,
      urlParams: {
        only_results: only_flattened,
      },
    });
    return data;
  }

  async startProcessDownloadDocuments(
    taskId: number,
    onlyResults: boolean
  ): Promise<{ subtask_id: string }> {
    this.validateTaskId(taskId);

    const { data: subtaskId } = await this.apiFetcher.callApi<{
      subtask_id: string;
    }>({
      options: {
        method: "POST",
      },
      endpoint: `tasks/${taskId}/documents`,
      urlParams: {
        only_results: onlyResults,
      },
    });

    return subtaskId;
  }

  async getDownloadDocuments(
    taskId: number,
    subtaskId: string
  ): Promise<ApiDownloadDocument> {
    this.validateTaskId(taskId);
    this.validateSubtaskId(subtaskId);

    const { data } = await this.apiFetcher.callApi<ApiDownloadDocument>({
      endpoint: `tasks/${taskId}/documents/${subtaskId}`,
    });

    return data;
  }
}

const documentsFetcher = new DocumentsFetch();

export default documentsFetcher;
