import { Spin } from "antd";

import styles from './ExpandedRowContent.module.css'

const DocumentsLoader = () => {
  return (
    <div className={styles.spinnerContainer}>
      <Spin />
      <p className="base m-0">Loading documents...</p>
    </div>
  );
};

export default DocumentsLoader;
