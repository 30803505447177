/* eslint-disable @typescript-eslint/no-explicit-any */
import { isDayjs } from "dayjs";

import { DayJSType } from "../types/formFilters";

export const isDayJSType = (array: any[]): array is DayJSType => {
  return array.every(
    (_) =>
      Array.isArray(array) &&
      array.length === 2 &&
      (array[0] === null || isDayjs(array[0])) &&
      (array[1] === null || isDayjs(array[1]))
  );
};
