import Cookies from "js-cookie";

import { MAX_TIME_EXPIRATION } from "../utils/constants";

const COOKIES = {
  lastActivity: "lastActivity",
};

const addCookie = (
  cookieName: string,
  cookieValue: string,
  options?: Cookies.CookieAttributes | undefined
) => {
  Cookies.set(cookieName, cookieValue, options);
};

const removeCookie = (cookieName: string) => Cookies.remove(cookieName);

const getCookie = (cookieName: string) => Cookies.get(cookieName);

export const addLastActivityCookie = () => {
  const expirationDate = new Date(new Date().getTime() + MAX_TIME_EXPIRATION);

  addCookie(COOKIES.lastActivity, Date.now().toString(), {
    expires: expirationDate,
  });
};

export const removeLastActivityCookie = () =>
  removeCookie(COOKIES.lastActivity);

export const getLastActivityCookie = () => getCookie(COOKIES.lastActivity);
