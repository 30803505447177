import { Button, Dropdown, Menu, MenuProps, Tooltip } from "antd";
import { HomeOutlined, QuestionOutlined } from "@ant-design/icons";

import styles from "./Aside.module.css";

type MenuItem = Required<MenuProps>["items"][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <a href="mailto:hipra.masking@omnios.ai">Contact support</a>,
  },
  {
    type: "divider",
  },
  {
    key: "2",
    label: (
      <a
        className={styles.omniosLink}
        href="https://omnios.ai/"
        target="_blank"
        rel="noreferrer"
      >
        © 2024 • Omnios
      </a>
    ),
  },
];

const Aside = () => {
  return (
    <aside className={styles.aside}>
      <Menu
        mode="inline"
        className={styles.menu}
        inlineCollapsed
        defaultSelectedKeys={["1"]}
        items={[getItem("Home", "1", <HomeOutlined />)]}
      />
      <Dropdown placement="topLeft" menu={{ items }} trigger={["click"]}>
        <Tooltip title="Help" placement="right">
          <Button size="small" icon={<QuestionOutlined />} />
        </Tooltip>
      </Dropdown>
    </aside>
  );
};
export default Aside;
