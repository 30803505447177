import { Tooltip } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

import { Maybe } from "../../../../types/common";
import { Document } from "../../../../types/documents";

import { generateRandomId, getTooltipTitle } from "../../../../utils/common";

import IconWrapped from "../../../../components/IconWrapped";
import MaskedIcon from "../../../../components/icons/MaskedIcon";

import styles from "./ExpandedRowContent.module.css";

const handleDownload = async (documentToDownload: Document) => {
  try {
    const response = await fetch(documentToDownload.temporal_download_url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = documentToDownload.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Download failed", error);
  }
};

const ExpandedRowItem = ({ document }: { document: Maybe<Document> }) => {
  if (!document) return null;

  const tooltipTitle = getTooltipTitle(document.type);

  return (
    <li className={styles.tableRowExpanded} key={generateRandomId()}>
      {document.type === "RPP" ? (
        <Tooltip title={tooltipTitle}>
          <IconWrapped
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MaskedIcon width="20" height="20" />
          </IconWrapped>
        </Tooltip>
      ) : (
        <Tooltip title={tooltipTitle}>
          <PaperClipOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      )}
      <Tooltip title="Click to download">
        <a
          onClick={(e) => {
            e.preventDefault();
            handleDownload(document);
          }}
          href={document.temporal_download_url}
          className={`base normal documentLink`}
        >
          {document.name}
        </a>
      </Tooltip>
    </li>
  );
};

export default ExpandedRowItem;
