import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { MaskStatus, MaskType } from "../types/masks";
import { MasksFilters, MasksFiltersContextType } from "../types/providers";

const MasksFiltersContext = createContext<MasksFiltersContextType | null>(null);

const initialFilters = {
  maskType: undefined,
  createdByUser: undefined,
  feedbackStatus: undefined,
  page: { from: undefined, to: undefined },
};

const MasksFiltersContextProvider = ({ children }: { children: ReactNode }) => {
  const [filtersApplied, setFiltersApplied] =
    useState<MasksFilters>(initialFilters);

  const onApplyFilters = (masksFilters: MasksFilters) =>
    setFiltersApplied(masksFilters);

  const onClearFilters = () => {
    setFiltersApplied(initialFilters);
  };

  const removeFilter = (
    filterToRemove: keyof MasksFilters,
    elementToRemove?: MaskType | MaskStatus
  ) => {
    setFiltersApplied((prevFilters) => {
      if (filterToRemove === "page") {
        return {
          ...prevFilters,
          [filterToRemove]: { from: undefined, to: undefined },
        };
      }

      if (filterToRemove === "createdByUser") {
        return {
          ...prevFilters,
          [filterToRemove]: undefined,
        };
      }

      const filterValue = prevFilters[filterToRemove];

      if (filterValue && Array.isArray(filterValue)) {
        if (filterToRemove === "maskType") {
          return {
            ...prevFilters,
            [filterToRemove]: (filterValue as MaskType[]).filter(
              (element: MaskType) => element !== elementToRemove
            ),
          };
        } else if (filterToRemove === "feedbackStatus") {
          return {
            ...prevFilters,
            [filterToRemove]: (filterValue as MaskStatus[]).filter(
              (element: MaskStatus) => element !== elementToRemove
            ),
          };
        }
      }

      return prevFilters;
    });
  };

  const contextValue = useMemo(
    () => ({
      filtersApplied,
      onApplyFilters,
      onClearFilters,
      removeFilter,
    }),
    [filtersApplied]
  );

  return (
    <MasksFiltersContext.Provider value={contextValue}>
      {children}
    </MasksFiltersContext.Provider>
  );
};

export const useMasksFiltersContext = () => {
  const context = useContext(MasksFiltersContext);

  if (!context)
    throw new Error(
      "useMasksFiltersContext must be used within a MasksFiltersContextProvider"
    );

  return context;
};

export default MasksFiltersContextProvider;
