import { WebViewerInstance } from "@pdftron/webviewer";

import { MaskingStyles } from "../../types/documentsViewer";
import { MaskType } from "../../types/masks";

export const DEFAULT_STYLES: MaskingStyles = {
  Font: "Arial",
  FontSize: "12pt",
};

export const getMaskingStyles = (
  webViewerInstance: WebViewerInstance,
  maskType: MaskType,
  isAdditionalText?: boolean
) => {
  const DEFAULT_STROKE_COLOR = getColor("RED", webViewerInstance);

  let redactionStyles = {
    ...DEFAULT_STYLES,
    ...DEFAULT_STROKE_COLOR,
  };

  if (maskType === "PPD") {
    redactionStyles = {
      ...redactionStyles,
      TextColor: getColor("BLACK", webViewerInstance),
      FillColor: isAdditionalText
        ? getColor("WHITE", webViewerInstance)
        : getColor("CYAN_BLUE", webViewerInstance),
    };
  }
  if (maskType === "CCI") {
    redactionStyles = {
      ...redactionStyles,
      TextColor: isAdditionalText
        ? getColor("BLACK", webViewerInstance)
        : getColor("RED", webViewerInstance),
      FillColor: isAdditionalText
        ? getColor("WHITE", webViewerInstance)
        : getColor("BLACK", webViewerInstance),
    };
  }

  return redactionStyles;
};

type CustomColors = "BLACK" | "WHITE" | "CYAN_BLUE" | "RED";

export const getColor = (color: CustomColors, instance: WebViewerInstance) => {
  const COLORS = {
    BLACK: new instance.Core.Annotations.Color(0, 0, 0),
    WHITE: new instance.Core.Annotations.Color(255, 255, 255),
    CYAN_BLUE: new instance.Core.Annotations.Color(115, 203, 235),
    RED: new instance.Core.Annotations.Color(255, 0, 0),
  };

  return COLORS[color];
};

export const getFillColor = (
  instance: WebViewerInstance,
  maskType: MaskType,
  freeText?: string | null
) => {
  if (freeText) return getColor("WHITE", instance);
  if (maskType === "PPD") return getColor("CYAN_BLUE", instance);
  return getColor("BLACK", instance);
};

export const startMaskingTool = (
  webViewerInstance: WebViewerInstance | null,
  maskData: {
    maskType: MaskType;
    additionalText?: string;
  }
) => {
  if (!webViewerInstance) return null;

  const docViewer = webViewerInstance.Core.documentViewer;

  const { maskType, additionalText } = maskData;

  const hasAdditionalText = Boolean(additionalText) && additionalText !== "";

  const webViewerToolInstance = webViewerInstance.Core.Tools;
  const maskingTool = new webViewerToolInstance.RedactionCreateTool(docViewer);

  const maskingStyles = getMaskingStyles(
    webViewerInstance,
    maskType,
    hasAdditionalText
  );

  maskingTool.setStyles({
    OverlayText: hasAdditionalText ? additionalText : maskType,
    ...maskingStyles,
  });

  docViewer.setToolMode(maskingTool);
};
