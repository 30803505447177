import { Col, Form, Input, Row, Select } from "antd";

import {
  procedureIdRules,
  procedureTypeRules,
  productNameRules,
  taskNameRules,
} from "../utils";

import { MasterData } from "../../../types/masterData";

import styles from "./Task.module.css";

const { Option } = Select;

interface InputsSectionProps {
  masterData: MasterData;
  isLoading: boolean;
}

const InputsSection = ({ masterData, isLoading }: InputsSectionProps) => {
  return (
    <section className={styles.inputsSection}>
      <Form.Item
        className="customFormItem login"
        name="taskName"
        label="Task name"
        rules={taskNameRules}
      >
        <Input name="taskName" placeholder="Type a name for this task" />
      </Form.Item>
      <Form.Item
        name="productName"
        label="Product name"
        tooltip="Product Commercial Name"
        rules={productNameRules}
        className="customFormItem login"
      >
        <Select loading={isLoading}>
          {masterData.products.map((product) => (
            <Option key={product.key} value={product.key}>
              {product.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Procedure type"
            name="procedureType"
            tooltip="Policy type that applies to this task."
            rules={procedureTypeRules}
            className="customFormItem login"
          >
            <Select loading={isLoading}>
              {masterData.procedureTypes.map((procedureType) => (
                <Option key={procedureType.key} value={procedureType.key}>
                  {procedureType.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            required
            label="ID procedure"
            name="procedureId"
            tooltip="This identifier is used to group tasks."
            rules={procedureIdRules}
            className="customFormItem login"
          >
            <Input placeholder="Type a number" />
          </Form.Item>
        </Col>
      </Row>
    </section>
  );
};
export default InputsSection;
