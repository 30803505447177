import { useState } from "react";
import { RadioChangeEvent } from "antd";

import { MasksFilters } from "../../../types/providers";

import { MaskStatus, MaskType } from "../../../types/masks";

const useMasksFilters = (initialValues: MasksFilters) => {
  const [formValues, setFormValues] = useState<MasksFilters>(initialValues);

  const onChangeMaskType = (checkedValues: MaskType[]) =>
    setFormValues((prevValues) => ({ ...prevValues, maskType: checkedValues }));

  const onChangeCreatedByUser = (e: RadioChangeEvent) =>
    setFormValues((prevValues) => ({
      ...prevValues,
      createdByUser: e.target.checked,
    }));

  const onChangeFeedbackStatus = (checkedValues: MaskStatus[]) =>
    setFormValues((prevValues) => ({
      ...prevValues,
      feedbackStatus: checkedValues,
    }));

  const onChangePageFrom = (value: number | null) =>
    setFormValues((prevValues) => ({
      ...prevValues,
      page: { ...prevValues.page, from: value },
    }));

  const onChangePageTo = (value: number | null) =>
    setFormValues((prevValues) => ({
      ...prevValues,
      page: { ...prevValues.page, to: value },
    }));

  return {
    formValues,
    onChangeMaskType,
    onChangeCreatedByUser,
    onChangeFeedbackStatus,
    onChangePageFrom,
    onChangePageTo,
  };
};

export default useMasksFilters;
