import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MessageOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

import { MaskStatus } from "../../types/masks";

import { toCapitalize } from "../../utils/common";
import { Maybe } from "../../types/common";

const getTagData = (status: MaskStatus) => {
  let color, icon;

  switch (status) {
    case "REJECTED":
      color = "red";
      icon = <CloseCircleOutlined style={{ fontSize: "12px" }} />;
      break;
    case "ACCEPTED":
      color = "green";
      icon = <CheckCircleOutlined style={{ fontSize: "12px" }} />;
      break;
    case "PARTIALLY_ACCEPTED":
      color = "warning";
      icon = <MinusCircleOutlined style={{ fontSize: "12px" }} />;
      break;
    case "MISSING":
      color = "purple";
      icon = <QuestionCircleOutlined style={{ fontSize: "12px" }} />;
      break;
    case "SUGGESTION":
      color = "geekblue";
      icon = <MessageOutlined style={{ fontSize: "12px" }} />;
      break;
    default:
      color = "processing";
      icon = null;
  }

  return { tagColor: color, tagIcon: icon };
};

const formatTagStatusText = (status: MaskStatus) => {
  return status
    .split("_")
    .map((s) => toCapitalize(s))
    .join(" ");
};

const FeedbackTag = ({ status }: { status: Maybe<MaskStatus> }) => {
  if (!status) return null;
  const { tagColor, tagIcon } = getTagData(status);
  return (
    <Tag
      color={tagColor}
      icon={tagIcon}
      style={{ borderRadius: "12px", marginTop: "12px" }}
    >
      <span className="base normal" style={{ color: "#222223" }}>
        {formatTagStatusText(status)}
      </span>
    </Tag>
  );
};
export default FeedbackTag;
