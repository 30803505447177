import { useNavigate } from "react-router-dom";
import { Card } from "antd";

import { Task } from "../../../../types/task";

import { getParsedFullDate } from "../../../../utils/dateParsers";

import placeholderImage from "../../../../assets/images/cardplaceholder.png";

import StatusTag from "../../../../components/Tag/StatusTag";

import styles from "./CardsList.module.css";

const CardsList = ({ tasks }: { tasks: Task[] }) => {
  const navigate = useNavigate();
  return (
    <section className={styles.cardList}>
      {tasks.slice(0, 5).map((task) => {
        const isValidated = Boolean(task.validated_at && task.validated_by);
        return (
          <Card
            key={task.id}
            className={`customCard ${styles.card}`}
            hoverable
            onClick={() => navigate(`/tasks/${task.id}`)}
            cover={
              <img
                alt="placeholder"
                src={task.thumbnail ?? placeholderImage}
                className={styles.cardImage}
              />
            }
          >
            <div className={styles.cardContent}>
              <div className={styles.cardInfoContent}>
                <span className={`base normal ${styles.cardId}`}>
                  ID {task.id}
                </span>
                <h4 className={`lg strong ${styles.cardTitle}`}>{task.name}</h4>
                <span className={`lg normal ${styles.cardUpdatedAt}`}>
                  {getParsedFullDate(task.edited_at ?? task.created_at, "·")}
                </span>
              </div>
              <div>
                <StatusTag isValidated={isValidated} tooltipPlacement="top" />
              </div>
            </div>
          </Card>
        );
      })}
    </section>
  );
};
export default CardsList;
