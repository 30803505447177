import { Mask, Occurrence } from "../../types/masks";

import { getOccurrenceShowAsDeleted } from "./utils";
import { generateRandomId } from "../../utils/common";

import styles from "./FeedbackCard.module.css";

const FeedbackCardOccurrences = ({
  maskInfo,
  occurrences,
  goToAnnotation,
}: {
  maskInfo: Mask;
  occurrences: Occurrence[];
  goToAnnotation: (maskId: number, occurrenceId: number) => void;
}) => {
  return (
    <ul className={styles.occurrencesList}>
      {occurrences.length === 0 ? (
        <p className={styles.textNoOccurrences}>
          No occurrences found for this mask
        </p>
      ) : (
        occurrences.map((occurrence, index, arrOcurrences) => {
          const showAsDeleted = getOccurrenceShowAsDeleted(
            maskInfo,
            occurrence
          );
          return (
            <button
              className={`base normal ${styles.occurrenceItem} ${
                showAsDeleted && styles.deleted
              }`}
              key={generateRandomId()}
              onClick={() =>
                !showAsDeleted && goToAnnotation(maskInfo.id, occurrence.id)
              }
            >
              Page {occurrence.page.toString().padStart(2, "0")}
              {index < arrOcurrences.length - 1 && ", "}
            </button>
          );
        })
      )}
    </ul>
  );
};
export default FeedbackCardOccurrences;
