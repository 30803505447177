import { Button } from "antd";

import { OptionalFormFilters } from "../../../types/formFilters";

import { generateRandomId } from "../../../utils/common";
import { isDayJSType } from "../../../utils/typeGuards";

import FilterTag from "../../Tag/FilterTag";
import TaskIdTag from "../../Tag/TaskIdTag";
import DateTag from "../../Tag/DateTag";

import styles from "./TagsList.module.css";

interface TagsListProps {
  filtersApplied: OptionalFormFilters[];
  onClearFilters: () => void;
  onClearFilter: (e: React.MouseEvent<HTMLElement>) => void;
}

const hasFilters = (filters: OptionalFormFilters[]) => filters.length > 0;
const hasValue = (filter: OptionalFormFilters) => {
  if (filter.taskId?.from) return filter.taskId?.from !== null;
  if (filter.taskId?.to) return filter.taskId?.to !== null;
  if (filter.product) return filter.product.trim() !== "";
  if (filter.user) return filter.user.trim() !== "";
  if (filter.procedureType) return filter.procedureType.trim() !== "";
  if (filter.procedureId) return filter.procedureId.trim() !== "";
  if (filter.creationDate) return filter.creationDate !== null;
  if (filter.editionDate) return filter.editionDate !== null;
  if (filter.validation) return filter.validation.length > 0;
};
const checkHasMoreThanOneFilterFilled = (filters: OptionalFormFilters[]) => {
  if (!hasFilters(filters)) return false;

  let count = filters.reduce(
    (acc, filter) => (hasValue(filter) ? acc + 1 : acc),
    0
  );

  const validationFilter = filters.find((filter) =>
    Object.keys(filter).find((key) => key === "validation")
  ) as { validation: string[] } | undefined;

  if (validationFilter && validationFilter.validation.length === 2) count++;

  return count >= 2;
};

const TagsList = ({
  filtersApplied,
  onClearFilters,
  onClearFilter,
}: TagsListProps) => {
  const showClearFiltersBtn = checkHasMoreThanOneFilterFilled(filtersApplied);

  return (
    <ul className={styles.list}>
      {filtersApplied.map((filter) => {
        return Object.entries(filter).map(([k, v]) => {
          if (typeof v === "string" && v.trim() !== "")
            return k === "procedureId" ? (
              <FilterTag
                onClose={onClearFilter}
                name={k}
                key={generateRandomId()}
              >
                ID procedure: {v}
              </FilterTag>
            ) : (
              <FilterTag
                onClose={onClearFilter}
                name={k}
                key={generateRandomId()}
              >
                {v}
              </FilterTag>
            );
          else if (v && Array.isArray(v)) {
            if (isDayJSType(v)) {
              const [from, to] = v;
              const dateTypeText =
                k === "creationDate" ? "Creation date" : "Edition date";
              return (
                <DateTag
                  name={k}
                  key={generateRandomId()}
                  dateTypeText={dateTypeText}
                  from={from}
                  to={to}
                  onClose={onClearFilter}
                />
              );
            } else {
              return v.map((validateFilter) => {
                const validateText =
                  validateFilter === "Validated"
                    ? "Validated task"
                    : "Pending validation task";
                return (
                  <FilterTag
                    onClose={onClearFilter}
                    name={k}
                    key={generateRandomId()}
                    dataValue={validateFilter}
                  >
                    {validateText}
                  </FilterTag>
                );
              });
            }
          } else if (v && typeof v === "object") {
            return (
              <TaskIdTag
                onClose={onClearFilter}
                name={k}
                key={generateRandomId()}
                from={v?.from}
                to={v.to}
              />
            );
          }
          return null;
        });
      })}
      {showClearFiltersBtn && (
        <Button onClick={onClearFilters} size="small" type="link">
          Clear all filters
        </Button>
      )}
    </ul>
  );
};

export default TagsList;
