const PPDIcon = () => {
  return (
    <svg
      width="33"
      height="24"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="24" fill="#73CAE9" />
      <path
        d="M4.95881 16V7.27273H8.0696C8.74858 7.27273 9.31108 7.39631 9.7571 7.64347C10.2031 7.89062 10.5369 8.22869 10.7585 8.65767C10.9801 9.08381 11.0909 9.56392 11.0909 10.098C11.0909 10.6349 10.9787 11.1179 10.7543 11.5469C10.5327 11.973 10.1974 12.3111 9.74858 12.5611C9.30256 12.8082 8.74148 12.9318 8.06534 12.9318H5.92614V11.8153H7.94602C8.375 11.8153 8.72301 11.7415 8.99006 11.5938C9.2571 11.4432 9.45312 11.2386 9.57812 10.9801C9.70312 10.7216 9.76562 10.4276 9.76562 10.098C9.76562 9.76847 9.70312 9.47585 9.57812 9.22017C9.45312 8.96449 9.25568 8.7642 8.9858 8.61932C8.71875 8.47443 8.36648 8.40199 7.92898 8.40199H6.27557V16H4.95881ZM12.6346 16V7.27273H15.7454C16.4244 7.27273 16.9869 7.39631 17.4329 7.64347C17.8789 7.89062 18.2127 8.22869 18.4343 8.65767C18.6559 9.08381 18.7667 9.56392 18.7667 10.098C18.7667 10.6349 18.6545 11.1179 18.43 11.5469C18.2085 11.973 17.8732 12.3111 17.4244 12.5611C16.9783 12.8082 16.4173 12.9318 15.7411 12.9318H13.6019V11.8153H15.6218C16.0508 11.8153 16.3988 11.7415 16.6658 11.5938C16.9329 11.4432 17.1289 11.2386 17.2539 10.9801C17.3789 10.7216 17.4414 10.4276 17.4414 10.098C17.4414 9.76847 17.3789 9.47585 17.2539 9.22017C17.1289 8.96449 16.9315 8.7642 16.6616 8.61932C16.3945 8.47443 16.0423 8.40199 15.6048 8.40199H13.9513V16H12.6346ZM23.1357 16H20.3104V7.27273H23.2251C24.0803 7.27273 24.8146 7.44744 25.4283 7.79688C26.0419 8.14347 26.5121 8.64205 26.8388 9.29261C27.1683 9.94034 27.3331 10.7173 27.3331 11.6236C27.3331 12.5327 27.1669 13.3139 26.8345 13.9673C26.505 14.6207 26.0277 15.1236 25.4027 15.4759C24.7777 15.8253 24.022 16 23.1357 16ZM21.6271 14.8494H23.0632C23.728 14.8494 24.2805 14.7244 24.7209 14.4744C25.1612 14.2216 25.4908 13.8565 25.7095 13.3793C25.9283 12.8991 26.0376 12.3139 26.0376 11.6236C26.0376 10.9389 25.9283 10.358 25.7095 9.88068C25.4936 9.40341 25.1712 9.04119 24.7422 8.79403C24.3132 8.54687 23.7805 8.4233 23.1442 8.4233H21.6271V14.8494Z"
        fill="#222223"
      />
    </svg>
  );
};
export default PPDIcon;
