import {
  FeedbackStatusOption,
  MaskTypeOption,
  UserOption,
} from "../../../types/masksFilters";

export const MASK_TYPE_OPTIONS: MaskTypeOption[] = [
  { label: "CCI", value: "CCI" },
  { label: "PPD", value: "PPD" },
];

export const USER_OPTIONS: UserOption[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const FEEDBACK_STATUS_OPTIONS: FeedbackStatusOption[] = [
  { label: "Accepted", value: "ACCEPTED" },
  { label: "Missing", value: "MISSING" },
  { label: "Partially Accepted", value: "PARTIALLY_ACCEPTED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Suggestion", value: "SUGGESTION" },
];
