import EmptyContainer from "./EmptyContainer";

import NoResultsIcon from "../icons/NoResultsIcon";

const EmptyMasks = () => {
  return (
    <EmptyContainer
      title="Clinical file has no interactive masks."
      description="The uploaded document doesn't cointain masks ocurrences."
      image={<NoResultsIcon />}
    />
  );
};
export default EmptyMasks;
