import { BaseFetcher } from "./BaseFetcher";

import { FormTask, UpdateTask } from "../../types/formTasks";
import { Notification, Task } from "../../types/task";

import { ErrorsHandler } from "../../utils/errorHandler";

class TasksFetch extends BaseFetcher {
  async getTasks(): Promise<Task[]> {
    const { data: tasks } = await this.apiFetcher.callApi<Task[]>({
      endpoint: "tasks",
      urlParams: {
        skip_results: true,
        skip_documents: true,
      },
    });

    return tasks;
  }

  async getTask(taskId: number, statusNeeded = true): Promise<Task> {
    this.validateTaskId(taskId);

    const { data: task } = await this.apiFetcher.callApi<Task>({
      endpoint: `tasks/${taskId}`,
    });

    if (task.status !== "SUCCESS" && statusNeeded)
      ErrorsHandler.throwBadRequest(`Task uncompleted: ${taskId}`);

    return task;
  }

  async createTask(task: FormTask): Promise<Task> {
    this.validateTask(task);

    const apiTask: FormTask = {
      ...task,
      documents: {
        ...task.documents,
        RPP_JT: task.documents.RPP_JT.map(({ id, ...rppBlock }) => rppBlock),
      },
    };

    const { data: createdTask } = await this.apiFetcher.callApi<Task>({
      endpoint: "tasks",
      options: {
        method: "POST",
        body: JSON.stringify(apiTask),
        headers: { "Content-type": "application/json" },
      },
    });

    return createdTask;
  }

  async deleteTask(taskId: number): Promise<void> {
    this.validateTaskId(taskId);

    await this.apiFetcher.callApi({
      endpoint: `tasks/${taskId}`,
      options: { method: "DELETE" },
    });
  }

  async reactivateTask(taskId: number): Promise<Task> {
    this.validateTaskId(taskId);

    const { data: taskUpdated } = await this.apiFetcher.callApi<Task>({
      endpoint: `tasks/${taskId}/restore`,
      options: { method: "PUT" },
    });
    return taskUpdated;
  }

  async reuploadTask(taskToReupload: UpdateTask): Promise<void> {
    this.validateTask(taskToReupload);

    const taskId = taskToReupload.id;

    if (taskToReupload.documents?.RPP_JT) {
      taskToReupload.documents = {
        ...taskToReupload.documents,
        RPP_JT: taskToReupload.documents?.RPP_JT.map(
          ({ id, ...rppblock }) => rppblock
        ),
      };
    }

    await this.apiFetcher.callApi({
      endpoint: `tasks/${taskId}/retry`,
      options: {
        method: "PUT",
        body: JSON.stringify(taskToReupload),
        headers: { "Content-type": "application/json" },
      },
    });
  }

  async changeValidationStatus(
    taskId: number,
    username: string | undefined,
    isValidated: boolean
  ): Promise<Task> {
    this.validateTaskId(taskId);
    this.validateUsername(username);

    const validateEndpoint = isValidated ? "invalidate" : "validate";

    const { data: taskUpdated } = await this.apiFetcher.callApi<Task>({
      endpoint: `tasks/${taskId}/${validateEndpoint}`,
      options: { method: "PUT" },
    });

    return taskUpdated;
  }

  async getNotifications(taskId: number): Promise<Notification[]> {
    this.validateTaskId(taskId);

    const { data: notifications } = await this.apiFetcher.callApi<
      Notification[]
    >({
      endpoint: `tasks/${taskId}/notifications`,
    });

    return notifications;
  }
}

const tasksFetcher = new TasksFetch();

export default tasksFetcher;
