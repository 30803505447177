import { Button } from "antd";

import {
  useMessageContext,
  useModalContext,
  useTasksContext,
} from "../../../providers";

import { tasksFetcher } from "../../../services/api";

import { Task, TaskStatus } from "../../../types/task";

import ModalContainer from "../ModalContainer";

import styles from "./DeleteTask.module.css";

const getModalMessage = (task: Task | null) => {
  if (!task) return "";

  const baseMessage = `You are about to delete the task "${task.name}"`;

  const messages: { [key in TaskStatus]?: string } = {
    SUCCESS: `${baseMessage}. Later, you can search for it by switching "Show deleted" and you can also reactivate it if necessary.`,
    FAILED: `${baseMessage} in which a processing error has been reported. If you delete it, the process will be aborted and the information processed so far will be lost.`,
    PENDING: `${baseMessage} that is being processed. If you delete it, the process will be aborted and the information processed so far will be lost.`,
  };

  return (
    messages[task.status] ??
    "The status of this task is unrecognized. Please check the task details."
  );
};

const DeleteTaskModal = () => {
  const { modalOpen, closeModal, taskSelected } = useModalContext();

  const { sendMessage } = useMessageContext();
  const { updateTask } = useTasksContext();

  const modalMessage = getModalMessage(taskSelected);

  return (
    <ModalContainer
      centered={false}
      onCancel={closeModal}
      open={modalOpen === "deleteTask"}
      width={384}
    >
      <h3 className="lg normal">Delete task</h3>
      <p>{modalMessage}</p>
      <footer className={styles.footer}>
        <Button onClick={closeModal} type="link">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (!taskSelected) return;
            try {
              await tasksFetcher.deleteTask(taskSelected.id);
              updateTask({
                ...taskSelected,
                deleted_at: new Date().toISOString(),
              });
              sendMessage(
                `${taskSelected?.name} was successfully deleted`,
                "success"
              );
            } catch (error) {
              sendMessage(
                `Something was wrong deleting task ${taskSelected?.name}. Try in a few moments`,
                "error",
                2.5
              );
            } finally {
              closeModal();
            }
          }}
          type="primary"
        >
          Delete task
        </Button>
      </footer>
    </ModalContainer>
  );
};

export default DeleteTaskModal;
