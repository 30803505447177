import { useState } from "react";

import { Maybe } from "../types/common";
import { DocumentType } from "../types/documents";
import {
  FormTaskDocuments,
  FileTaskErrors,
  ClinicalFileError,
  FormRppJt,
  FormArFar,
  ClinicalDocumentType,
  AnonymizationDocumentType,
} from "../types/formTasks";
import { Task } from "../types/task";

import { generateRandomId } from "../utils/common";

const initialTaskDocuments: FormTaskDocuments = {
  RPP_JT: [{ id: generateRandomId(), RPP: null, JT: null }],
  AR_FAR: {
    AR: null,
    FAR: null,
  },
};

const initialDocumentsErrors: FileTaskErrors = {
  RPPJTS: [
    {
      id: initialTaskDocuments.RPP_JT[0].id ?? "",
      RPP: { hasError: false, message: "" },
      JT: { hasError: false, message: "" },
    },
  ],
  ARFAR: {
    AR: { hasError: false, message: "" },
    FAR: { hasError: false, message: "" },
  },
};

const getDocumentsFromTask = (task: Maybe<Task>): FormTaskDocuments => {
  const RPP_JT = task?.documents.RPP_JT.map((rppBlock) => ({
    id: generateRandomId(),
    RPP: rppBlock.ORIGINAL_RPP?.name,
    JT: rppBlock.JT?.name,
  })) as FormRppJt[];
  const AR_FAR = {
    AR: task?.documents.AR_FAR.AR?.name,
    FAR: task?.documents.AR_FAR.FAR?.name,
  } as FormArFar;
  return { RPP_JT, AR_FAR };
};

const useTaskForm = (initialTask?: Maybe<Task>) => {
  const [taskDocuments, setTaskDocuments] = useState<FormTaskDocuments>(
    initialTask ? getDocumentsFromTask(initialTask) : initialTaskDocuments
  );

  const [documentsErrors, setDocumentsErrors] = useState<FileTaskErrors>(() => {
    return {
      ...initialDocumentsErrors,
      RPPJTS:
        (taskDocuments.RPP_JT.map((rppBlock) => ({
          RPP: { hasError: false, message: "" },
          JT: { hasError: false, message: "" },
          id: rppBlock.id,
        })) as ClinicalFileError[]) ?? initialDocumentsErrors.RPPJTS,
    };
  });

  const addNewRPPBlock = () => {
    const id = generateRandomId();

    setTaskDocuments((prevTaskDocuments) => ({
      ...prevTaskDocuments,
      RPP_JT: [...prevTaskDocuments.RPP_JT, { id, RPP: null, JT: null }],
    }));
    setDocumentsErrors((prevDocumentsErrors) => ({
      ...prevDocumentsErrors,
      RPPJTS: [
        ...prevDocumentsErrors.RPPJTS,
        {
          id,
          RPP: { hasError: false, message: "" },
          JT: { hasError: false, message: "" },
        },
      ],
    }));
  };

  const deleteRPPBlock = (rppBlockId: string) => {
    setTaskDocuments((prevTaskFiles) => ({
      ...prevTaskFiles,
      RPP_JT: prevTaskFiles.RPP_JT.filter(
        (rppDocuments) => rppDocuments.id !== rppBlockId
      ),
    }));
    setDocumentsErrors((prevDocumentsError) => ({
      ...prevDocumentsError,
      RPPJTS: prevDocumentsError.RPPJTS.filter(
        (rppBlockErrors) => rppBlockErrors.id !== rppBlockId
      ),
    }));
  };

  const addClinicalDocument = (
    filename: string | null,
    blockId: string,
    clinicalType: ClinicalDocumentType
  ) => {
    setTaskDocuments((prevTaskDocuments) => ({
      ...prevTaskDocuments,
      RPP_JT: prevTaskDocuments.RPP_JT.map((rppBlock) =>
        rppBlock.id === blockId
          ? { ...rppBlock, [clinicalType]: filename }
          : rppBlock
      ),
    }));
    setDocumentsErrors((prevDocumentsError) => ({
      ...prevDocumentsError,
      RPPJTS: prevDocumentsError.RPPJTS.map((rppBlockErrors) =>
        rppBlockErrors.id === blockId
          ? {
              ...rppBlockErrors,
              [clinicalType]: { hasError: false, message: "" },
            }
          : rppBlockErrors
      ),
    }));
  };

  const addAnonymizationDocument = (
    filename: string,
    anonymizationType: AnonymizationDocumentType
  ) => {
    setTaskDocuments((prevTaskDocuments) => ({
      ...prevTaskDocuments,
      AR_FAR: { ...prevTaskDocuments.AR_FAR, [anonymizationType]: filename },
    }));
    setDocumentsErrors((prevDocumentsError) => ({
      ...prevDocumentsError,
      ARFAR: {
        ...prevDocumentsError.ARFAR,
        [anonymizationType]: { hasError: false, message: "" },
      },
    }));
  };

  const addClinicalDocumentError = (
    rppBlockId: string,
    message: string,
    clinicalType: ClinicalDocumentType
  ) => {
    setDocumentsErrors((prevDocumentsErrors) => ({
      ...prevDocumentsErrors,
      RPPJTS: prevDocumentsErrors.RPPJTS.map((rppBlockError) =>
        rppBlockId === rppBlockError.id
          ? {
              ...rppBlockError,
              [clinicalType]: {
                hasError: true,
                message,
              },
            }
          : rppBlockError
      ),
    }));
    setTaskDocuments((prevDocuments) => ({
      ...prevDocuments,
      RPP_JT: prevDocuments.RPP_JT.map((rppJtBlock) =>
        rppBlockId === rppJtBlock.id
          ? { ...rppJtBlock, [clinicalType]: null }
          : rppJtBlock
      ),
    }));
  };

  const addAnonymizationDocumentError = (
    message: string,
    anonymizationType: AnonymizationDocumentType
  ) => {
    setDocumentsErrors((prevDocumentsErrors) => ({
      ...prevDocumentsErrors,
      ARFAR: {
        ...prevDocumentsErrors.ARFAR,
        [anonymizationType]: {
          hasError: true,
          message,
        },
      },
    }));
    setTaskDocuments((prevDocuments) => ({
      ...prevDocuments,
      AR_FAR: { ...prevDocuments.AR_FAR, [anonymizationType]: null },
    }));
  };

  const isSameElement = (
    filename: string,
    fileType: DocumentType,
    rppBlock?: FormRppJt
  ) => {
    let isSameElement = false;

    if (fileType === "RPP" || fileType === "JT") {
      isSameElement = rppBlock?.RPP === filename || rppBlock?.JT === filename;
    } else {
      isSameElement =
        taskDocuments.AR_FAR.AR === filename ||
        taskDocuments.AR_FAR.FAR === filename;
    }

    return isSameElement;
  };

  const filenameExists = (
    filename: string,
    fileType: ClinicalDocumentType | AnonymizationDocumentType,
    rppBlock?: FormRppJt
  ) => {
    if (isSameElement(filename, fileType, rppBlock)) return false;

    const filenamesRPP = taskDocuments.RPP_JT.map(
      (rppJtBlock) => rppJtBlock.RPP
    );

    const filenamesJT = taskDocuments.RPP_JT.map((rppJtBlock) => rppJtBlock.JT);

    const filenameAR = taskDocuments.AR_FAR.AR;
    const filenameFAR = taskDocuments.AR_FAR.FAR;

    const allFilenames = [
      ...filenamesRPP,
      ...filenamesJT,
      filenameAR,
      filenameFAR,
    ].filter((filename) => filename);

    return allFilenames.includes(filename);
  };

  const isValidFormDocumentation = () => {
    const areClinicalDocumentsFilled = taskDocuments.RPP_JT.every(
      (rppBlock) => rppBlock.RPP
    );
    const areAnonymizationDocumentFilled = Boolean(taskDocuments.AR_FAR.AR);
    const areAnonymizationFeedbackDocumentFilled = Boolean(
      taskDocuments.AR_FAR.FAR
    );

    const formHasfilledRequiredDocuments =
      areClinicalDocumentsFilled &&
      areAnonymizationDocumentFilled &&
      areAnonymizationFeedbackDocumentFilled;

    if (!areClinicalDocumentsFilled) {
      const rppBlockIdUnfilledClinicalDocuments = taskDocuments.RPP_JT.filter(
        (rppBlock) => !rppBlock.RPP
      ).map((rppBlock) => rppBlock.id);
      rppBlockIdUnfilledClinicalDocuments.forEach(
        (rppBlockId) =>
          rppBlockId &&
          addClinicalDocumentError(
            rppBlockId,
            "This document is required",
            "RPP"
          )
      );
    }

    if (!areAnonymizationDocumentFilled)
      addAnonymizationDocumentError("This document is required", "AR");

    if (!areAnonymizationFeedbackDocumentFilled)
      addAnonymizationDocumentError("This document is required", "FAR");

    return formHasfilledRequiredDocuments;
  };

  const resetTasks = () => {
    setTaskDocuments(initialTaskDocuments);
    setDocumentsErrors(initialDocumentsErrors);
  };

  return {
    taskDocuments,
    documentsErrors,
    addNewRPPBlock,
    deleteRPPBlock,
    addClinicalDocument,
    addAnonymizationDocument,
    addClinicalDocumentError,
    addAnonymizationDocumentError,
    filenameExists,
    isValidFormDocumentation,
    resetTasks,
  };
};
export default useTaskForm;
