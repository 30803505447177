import { Button } from "antd";

import { MasksFilters } from "../../types/providers";

import { MaskStatus, MaskType } from "../../types/masks";

import { generateRandomId, toNormalize } from "../../utils/common";

import FilterTag from "../Tag/FilterTag";
import RangeTag from "../Tag/RangeTag";

import styles from "./MasksTagsList.module.css";

interface MasksTagsListProps {
  filtersApplied: MasksFilters;
  removeFilter: (
    filterToRemove: keyof MasksFilters,
    elementToRemove?: MaskType | MaskStatus
  ) => void;
  onClearFilters: () => void;
}

const hasMoreThanOneFilterApplied = (filters: MasksFilters) => {
  let filtersApplied = 0;

  Object.entries(filters).forEach(([k, v]) => {
    const castKey = k as keyof MasksFilters;
    if (castKey === "maskType" || castKey === "feedbackStatus") {
      if (v && Array.isArray(v) && v.length > 0) {
        v.forEach(() => filtersApplied++);
      }
    } else if (castKey === "createdByUser") {
      if (typeof v === "boolean") filtersApplied++;
    } else {
      (v.from || v.to) && filtersApplied++;
    }
  });

  return filtersApplied > 1;
};

const MasksTagsList = ({
  filtersApplied,
  removeFilter,
  onClearFilters,
}: MasksTagsListProps) => {
  return (
    <ul className={styles.tagsList}>
      {Object.entries(filtersApplied).map(([k, v]) => {
        if ((k === "maskType" || k === "feedbackStatus") && v?.length)
          return v.flatMap((value: MaskType | MaskStatus) => (
            <FilterTag onClose={() => removeFilter(k, value)} name={value}>
              {k === "feedbackStatus" ? toNormalize(value) : value}
            </FilterTag>
          ));
        if (k === "page")
          return (
            <RangeTag
              key={k}
              from={v.from}
              to={v.to}
              name="page"
              type="Page number"
              onClose={() => removeFilter("page")}
            />
          );
        if (k === "createdByUser" && typeof v === "boolean") {
          return (
            <FilterTag key={generateRandomId()} onClose={() => removeFilter(k)} name={"createdByUser"}>
              {v ? "Created by user" : "Not created by user"}
            </FilterTag>
          );
        }
        return null;
      })}
      {hasMoreThanOneFilterApplied(filtersApplied) && (
        <Button onClick={onClearFilters} size="small" type="link">
          Clear all filters
        </Button>
      )}
    </ul>
  );
};
export default MasksTagsList;
