import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useForm } from "antd/es/form/Form";

import {
  useAuthContext,
  useMessageContext,
  useModalContext,
  useTaskFormContext,
} from "../../../providers";

import { tasksFetcher } from "../../../services/api";
import { getUserLogged } from "../../../services/cognito";

import { Maybe } from "../../../types/common";
import { FormTask, UpdateTask, FormTaskType } from "../../../types/formTasks";
import { Task, TaskError } from "../../../types/task";

import useMasterData from "../../../hooks/useMasterData";

import ErrorBlock from "./ErrorBlock";
import InputsSection from "./InputsSection";
import RPPSection from "./RPPSection";
import AnonymizationSection from "./AnonymizationSection";

import styles from "./Task.module.css";

interface TaskFormValues {
  taskName: string;
  productName: string;
  procedureType: string;
  procedureId: string;
}

const getTextButton = (isUpdate: boolean, isSendingTask: boolean) => {
  if (isSendingTask) return isUpdate ? "Re uploading" : "Creating";
  return isUpdate ? "Re upload" : "Create";
};

const TaskForm = ({
  taskToUpdate,
  formTaskType,
  addTask,
  goToProcessingTab,
}: {
  taskToUpdate?: Maybe<Task>;
  formTaskType: FormTaskType;
  addTask?: (newTask: Task) => void;
  goToProcessingTab?: () => void;
}) => {
  const isUpdate = formTaskType === "updateTask";

  const [form] = useForm<TaskFormValues>();
  const [isSendingTask, setIsSendingTask] = useState(false);

  const { logout } = useAuthContext();
  const { closeModal } = useModalContext();
  const { sendMessage } = useMessageContext();
  const { taskDocuments, isValidFormDocumentation } = useTaskFormContext();

  const { masterData, isLoading, getProduct, getProcedureType } =
    useMasterData();

  useEffect(() => {
    if (
      !isLoading &&
      masterData.products.length > 0 &&
      masterData.procedureTypes.length > 0
    ) {
      form.setFieldsValue({
        taskName: taskToUpdate?.name ?? "",
        productName: taskToUpdate?.product.key ?? masterData.products[0]?.key,
        procedureType:
          taskToUpdate?.procedure_type.key ?? masterData.procedureTypes[2]?.key,
        procedureId: taskToUpdate?.procedure_id ?? "",
      });
    }
  }, [isLoading, masterData, taskToUpdate, form]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      // Los campos del formulario tipo Input no son validos
      isValidFormDocumentation();
      return;
    }

    if (!isValidFormDocumentation()) return;

    const { getFieldValue } = form;

    const userName = getUserLogged()?.getUsername();

    if (!userName) {
      sendMessage("Your session has expired, you need to login", "info", 2);
      return logout();
    }

    const task: FormTask = {
      id: taskToUpdate?.id,
      name: getFieldValue("taskName"),
      product: getProduct(getFieldValue("productName")),
      procedure_type: getProcedureType(getFieldValue("procedureType")),
      procedure_id: getFieldValue("procedureId"),
      documents: taskDocuments,
    };

    setIsSendingTask(true);

    if (isUpdate) {
      try {
        await tasksFetcher.reuploadTask(task as UpdateTask);
        sendMessage(`${task.name} is being reprocessed`, "success");
        closeModal();
      } catch (error) {
        console.error(error);
        sendMessage(
          `Something was wrong reuploading task: ${task.name}. Please, try in a few moments`,
          "error",
          3
        );
      } finally {
        setIsSendingTask(false);
      }
      return;
    }

    try {
      const taskCreated = await tasksFetcher.createTask(task);
      addTask && addTask(taskCreated);
      sendMessage(`The task ${task.name} is being processed`, "success");
      goToProcessingTab && goToProcessingTab();
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingTask(false);
    }
  };

  return (
    <Form form={form} layout="vertical">
      <h4 className={`head4 ${styles.modalTitle}`}>
        {isUpdate ? "Re upload a masking task" : "Create a Masking Task"}
      </h4>
      {isUpdate && <ErrorBlock taskError={taskToUpdate?.error as TaskError} />}
      <InputsSection masterData={masterData} isLoading={isLoading} />
      <RPPSection />
      <AnonymizationSection />
      <Button loading={isSendingTask} onClick={onSubmit} type="primary" block>
        {getTextButton(isUpdate, isSendingTask)}
      </Button>
    </Form>
  );
};
export default TaskForm;
