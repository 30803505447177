import { Core, WebViewerInstance } from "@pdftron/webviewer";

import { Maybe } from "../../types/common";
import { Document } from "../../types/documents";
import { Mask, MaskType, Occurrence, Quad } from "../../types/masks";
import { CustomAnnotation, RedactionInfo } from "../../types/redaction";
import { Task } from "../../types/task";

import { getColor, getFillColor } from "./styles";

const MAX_FONT_SIZE = 12;
const MIN_FONT_SIZE = 7;
const QUAD_MARGIN = 1;

export const getInitialRedaction = (
  task: Task,
  docSelected: Maybe<Document>
): RedactionInfo => {
  return {
    name: "",
    type: "CCI",
    occurrences: [
      {
        text: "",
        page: 0,
        replacement: "",
        quads: [],
      },
    ],
    taskId: task?.id,
    documentId: docSelected?.id ?? "",
  };
};

export const updateRedactionToSend = (
  redactionToSend: RedactionInfo,
  redaction: Core.Annotations.RedactionAnnotation,
  maskType: MaskType,
  freeText: string
) => {
  const isAreaRedaction =
    redaction.getCustomData("trn-annot-no-move").length > 0;
  redactionToSend.name = isAreaRedaction
    ? "Area mask"
    : redaction.getCustomData("trn-annot-preview");
  redactionToSend.occurrences[0].text = isAreaRedaction
    ? "Area mask"
    : redactionToSend.occurrences[0].text;
  redactionToSend.occurrences[0].page = redaction.getPageNumber();
  redactionToSend.occurrences[0].quads = redaction.Quads.map((quad) => ({
    x1: quad.x1,
    x2: quad.x2,
    x3: quad.x3,
    x4: quad.x4,
    y1: quad.y1,
    y2: quad.y2,
    y3: quad.y3,
    y4: quad.y4,
    text: null,
  }));
  redactionToSend.type = maskType;
  redactionToSend.occurrences[0].replacement =
    freeText.trim().length > 0 ? freeText : null;
};

const getFontSize = (
  redactionWidth: number,
  redactionHeight: number,
  text: string
): number => {
  if (redactionWidth <= 0 || redactionHeight <= 0) {
    throw new Error("El ancho y el alto deben ser números positivos.");
  }
  const heightMaxFontSize = computeHeightFontSize(redactionHeight, text);
  const widthMaxFontSize = computeWidthFontSize(redactionWidth, text);
  const fontSizeLimit = Math.max(
    Math.min(Math.min(heightMaxFontSize, widthMaxFontSize), MAX_FONT_SIZE),
    MIN_FONT_SIZE
  );
  return Math.round(fontSizeLimit);
};

const computeHeightFontSize = (
  redactionHeight: number,
  text: string
): number => {
  const fontSizeToHeightRatio = 1.2;
  const fontSize = Math.round(redactionHeight / fontSizeToHeightRatio);
  return Math.max(Math.min(fontSize, MAX_FONT_SIZE), MIN_FONT_SIZE);
};

const computeWidthFontSize = (redactionWidth: number, text: string): number => {
  const font = "Arial";
  let fontSize = MAX_FONT_SIZE;
  while (
    computeTextWidth(text, font, fontSize) > redactionWidth - 2 * QUAD_MARGIN &&
    fontSize > MIN_FONT_SIZE
  ) {
    fontSize--;
  }
  return fontSize;
};
const computeTextWidth = (
  text: string,
  font: string,
  fontSize: number
): number => {
  // Create a canvas element (in memory) to measure text width
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Unable to create 2D context for canvas");
  }

  // Set the font properties on the context
  context.font = `${fontSize}px ${font}`;

  // Measure the width of the entire text string
  const width = context.measureText(text).width;

  return width;
};

export const adaptFontSizeToRedaction = (
  instance: WebViewerInstance,
  redaction: CustomAnnotation
) => {
  const quadsFontSizes = redaction.Quads.map((quad) => {
    const minX = Math.min(quad.x1, quad.x2, quad.x3, quad.x4);
    const maxX = Math.max(quad.x1, quad.x2, quad.x3, quad.x4);
    const minY = Math.min(quad.y1, quad.y2, quad.y3, quad.y4);
    const maxY = Math.max(quad.y1, quad.y2, quad.y3, quad.y4);
    const quadWidth = maxX - minX;
    const quadHeight = maxY - minY;
    return getFontSize(quadWidth, quadHeight, redaction.OverlayText);
  });
  const fontSize = Math.min(...quadsFontSizes);

  instance.Core.annotationManager.setAnnotationStyles(redaction, {
    FontSize: `${Math.round(fontSize)}pt`,
  });
};

export const addInitialAnnotations = (
  instance: WebViewerInstance,
  redaction: CustomAnnotation
) => {
  adaptFontSizeToRedaction(instance, redaction);

  instance.Core.annotationManager.addAnnotation(redaction, {
    imported: true,
  });
};

export const createInitialRedactions = (
  masks: Mask[],
  instance: WebViewerInstance
): Core.Annotations.RedactionAnnotation[] => {
  return masks
    .flatMap((mask) => createRedactionsAnnotationsFromMask(mask, instance))
    .filter(
      (redaction): redaction is Core.Annotations.RedactionAnnotation =>
        redaction !== null
    );
};

export const refreshRedactionAnnotationFromMask = (
  mask: Mask,
  instance: WebViewerInstance
) => {
  const redaction = instance.Core.annotationManager
    .getAnnotationsList()
    .find(
      (annot) =>
        annot instanceof instance.Core.Annotations.RedactionAnnotation &&
        annot.getCustomData("mask_id") === mask.id.toString()
    );
  if (!redaction) return;
  instance.Core.annotationManager.deleteAnnotation(redaction, {
    source: "refresh",
  });
  const redactions = createRedactionsAnnotationsFromMask(mask, instance);
  if (!redactions) return;
  redactions.forEach((redaction) =>
    addInitialAnnotations(instance, redaction as CustomAnnotation)
  );
};

const createRedactionsAnnotationsFromMask = (
  mask: Mask,
  instance: WebViewerInstance
) => {
  if (mask.deleted_at !== null) return null;
  if (mask.occurrences.length === 0) return null;
  const occurrencesNotDeleted = mask.occurrences.filter(
    (occurrence) => occurrence.deleted_at === null
  );
  if (occurrencesNotDeleted.length === 0) return null;
  const newOccurrencesNotDeleted: Core.Annotations.RedactionAnnotation[] = [];
  occurrencesNotDeleted.forEach((occurrence) => {
    const quads = occurrence.quads;
    if (occurrence?.replacement) {
      quads.forEach((quad) => {
        const redaction = createRedactionAnnotation(
          occurrence,
          [quad],
          mask,
          instance
        );
        if (redaction) newOccurrencesNotDeleted.push(redaction);
      });
    } else {
      const redaction = createRedactionAnnotation(
        occurrence,
        quads,
        mask,
        instance
      );
      if (redaction) newOccurrencesNotDeleted.push(redaction);
    }
  });
  return newOccurrencesNotDeleted;
};

const createRedactionAnnotation = (
  occurrence: Occurrence,
  quads: Quad[],
  mask: Mask,
  instance: WebViewerInstance
): Core.Annotations.RedactionAnnotation | null => {
  if (occurrence.deleted_at) return null;
  if (quads.length === 0) return null;
  const annot = new instance.Core.Annotations.RedactionAnnotation({
    PageNumber: occurrence.page,
    Quads: createRedactionQuads(quads, instance),
    StrokeColor: getColor("RED", instance),
    FillColor: getFillColor(instance, mask.type, occurrence.replacement),
    TextColor:
      occurrence.replacement || mask.type === "PPD"
        ? getColor("BLACK", instance)
        : getColor("RED", instance),
    OverlayText: quads[0]?.text ?? occurrence.replacement ?? mask.type,
  });

  annot.Font = "Arial";
  addInitialCustomDataRedaction(annot, mask.id, occurrence, quads);
  return annot;
};

export const addInitialCustomDataRedaction = (
  redaction: Core.Annotations.RedactionAnnotation,
  maskId: number,
  occurrence: Occurrence,
  quads: Quad[]
) => {
  redaction.setCustomData("mask_id", maskId.toString());
  redaction.setCustomData("occurrence_id", occurrence.id.toString());
  redaction.setCustomData("initial_quads", JSON.stringify(quads));
};

export const createRedactionQuads = (
  quads: Quad[],
  instance: WebViewerInstance
) =>
  quads.map(
    ({ x1, x2, x3, x4, y1, y2, y3, y4 }) =>
      new instance.Core.Math.Quad(x1, y1, x2, y2, x3, y3, x4, y4)
  );

export const deleteMassiveRedactionsAnnotationsFromMask = (
  mask: Mask,
  instance: WebViewerInstance
) => {
  const occurrencesToDelete = mask.occurrences.filter(
    (occurrence) => occurrence.deleted_at === null
  );
  const { annotationManager } = instance.Core;
  const pdfViewerRedactions = annotationManager.getAnnotationsList();
  const pdfViewerRedactionsAnnotations = pdfViewerRedactions.filter(
    (redaction) =>
      redaction instanceof instance.Core.Annotations.RedactionAnnotation
  );
  const ocurrencesIds = new Set(
    occurrencesToDelete.map((occurrence) => occurrence.id.toString())
  );

  const pdfViewerRedactionsFounded = pdfViewerRedactionsAnnotations.filter(
    (annot) => ocurrencesIds.has(annot.getCustomData("occurrence_id"))
  );

  pdfViewerRedactionsFounded.forEach((annot) =>
    annot.setCustomData("massive_delete", "true")
  );

  annotationManager.deleteAnnotations(pdfViewerRedactionsFounded);
};
