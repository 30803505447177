import { cloneElement, ReactElement, useEffect, useRef, useState } from "react";
import { Tooltip, TooltipProps } from "antd";

const TooltipedElement = ({
  children,
  ...tooltipProps
}: {
  children: ReactElement;
  tooltipProps?: TooltipProps;
}) => {
  const elementRef = useRef<HTMLElement>(null);
  const [needsTooltip, setNeedsTooltip] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      setNeedsTooltip(
        elementRef.current.scrollWidth > elementRef.current.clientWidth
      );
    }
  }, []);

  const clonedChild = cloneElement(children, { ref: elementRef });

  return (
    <Tooltip
      title={needsTooltip ? elementRef.current?.textContent : ""}
      {...tooltipProps}
    >
      {clonedChild}
    </Tooltip>
  );
};

export default TooltipedElement;
