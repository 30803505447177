import { useContext } from "react";
import { RcFile } from "antd/es/upload";
import { Form } from "antd";

import { TaskFormContext } from "../../../providers/TaskFormProvider";
import { TaskFormContextType } from "../../../providers/interfaces";

import {
  isDOC,
  isDOCX,
  isFileSizeValid,
  isPDF,
} from "../../../components/Forms/utils";

import UploadItem from "../../../components/UploadItem/UploadItem";

import styles from "./Task.module.css";

const AnonymizationSection = () => {
  const {
    taskDocuments,
    documentsErrors,
    addAnonymizationDocument,
    addAnonymizationDocumentError,
    filenameExists,
  } = useContext(TaskFormContext) as TaskFormContextType;

  const beforeUploadAR = (file: RcFile) => {
    if (!isFileSizeValid(file)) {
      addAnonymizationDocumentError(
        "File uploaded is too large. Maximum allowed is 300 MB.",
        "AR"
      );
      return false;
    }
    if (!isPDF(file)) {
      addAnonymizationDocumentError(
        "Anonymization report only supports PDF files",
        "AR"
      );

      return false;
    }
    if (filenameExists(file.name, "AR")) {
      addAnonymizationDocumentError(
        `File ${file.name} is already uploaded in this form. Please, replace it.`,
        "AR"
      );

      return false;
    }
  };
  const beforeUploadFAR = (file: RcFile) => {
    if (!isFileSizeValid(file)) {
      addAnonymizationDocumentError(
        "File uploaded is too large. Maximum allowed is 300 MB.",
        "FAR"
      );
      return false;
    }
    if (!isDOC(file) && !isDOCX(file)) {
      addAnonymizationDocumentError(
        "Anonymization Feedback only supports DOC or DOCX files",
        "FAR"
      );
      return false;
    }
    if (filenameExists(file.name, "FAR")) {
      addAnonymizationDocumentError(
        `File ${file.name} is already uploaded in this form. Please, replace it.`,
        "FAR"
      );

      return false;
    }
  };
  return (
    <div className={styles.uploadBlock}>
      <Form.Item
        label="Anonymization report"
        tooltip="Document with HIPRA justification about PPD and document with EMA feedback of mentioned one."
      >
        <UploadItem
          label="Your file"
          documentName={taskDocuments.AR_FAR.AR}
          errors={[documentsErrors.ARFAR.AR]}
          onUploadCompleted={(info) =>
            addAnonymizationDocument(info.file.name, "AR")
          }
          onUploadFailed={(info) =>
            addAnonymizationDocumentError(
              `${info.file.name} file upload failed`,
              "AR"
            )
          }
          uploadProps={{
            beforeUpload: beforeUploadAR,
            accept: ".pdf",
          }}
        />
        <UploadItem
          label="Feedback file"
          documentName={taskDocuments.AR_FAR.FAR}
          errors={[documentsErrors.ARFAR.FAR]}
          onUploadCompleted={(info) =>
            addAnonymizationDocument(info.file.name, "FAR")
          }
          onUploadFailed={(info) =>
            addAnonymizationDocumentError(
              `${info.file.name} file upload failed`,
              "FAR"
            )
          }
          uploadProps={{
            beforeUpload: beforeUploadFAR,
            accept: ".doc,.docx",
          }}
        />
      </Form.Item>
    </div>
  );
};
export default AnonymizationSection;
