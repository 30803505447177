import { Actions } from "../types/masks";
import { TaskError, TaskErrorType } from "../types/task";

export const MAX_TIME_EXPIRATION = 60 * 60 * 1000; // 60min

export const ACTIONS: Actions = {
  CREATE_OCCURRENCE: {
    type: "CREATE_OCCURRENCE",
    message: "Mask created by",
  },
  DELETE_OCCURRENCE: {
    type: "DELETE_OCCURRENCE",
    message: "Mask deleted by",
  },
  DELETE_MASK: {
    type: "DELETE_MASK",
    message: "All occurrences deleted by",
  },
};

export const PROCESSING_ERRORS: { [key in TaskErrorType]: TaskError } = {
  TypelessAnnotationException: {
    type: "TypelessAnnotationException",
    message: "Invalid PDF Annotation",
  },
  UnreadableJustificationTableError: {
    type: "UnreadableJustificationTableError",
    message: "Unreadable Justification Table",
  },
  InvalidJustificationTableError: {
    type: "InvalidJustificationTableError",
    message: "Invalid Justification Table",
  },
  ProcessingError: {
    type: "ProcessingError",
    message: "Unknown error. Please contact support",
  },
};
