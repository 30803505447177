import { TagProps } from "antd";

import FilterTag from "./FilterTag";

interface TaskIdTagProps extends TagProps {
  from: number | null;
  to: number | null;
  name: string;
}

const getTaskIdTagText = (from: number | null, to: number | null) => {
  let tagText = "";
  if (!from && !to) return tagText;

  if (from && !to) tagText = `Task ID from: ${from}`;
  if (!from && to) tagText = `Task ID to: ${to}`;
  if (from && to) tagText = `Task ID from: ${from} to ${to}`;
  return tagText;
};

const TaskIdTag = ({ name, from, to, ...filterProps }: TaskIdTagProps) => {
  const tagText = getTaskIdTagText(from, to);
  if (tagText === "") return null;
  return (
    <FilterTag name={name} {...filterProps}>
      {tagText}
    </FilterTag>
  );
};
export default TaskIdTag;
