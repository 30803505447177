import { Alert } from "antd";

import { Notification } from "../../../types/task";

import { generateRandomId } from "../../../utils/common";

import styles from "./RightAside.module.css";

type AlertTypes = "success" | "info" | "warning" | "error";

const MasksNotificationsList = ({
  masksNotifications,
}: {
  masksNotifications: Notification[];
}) => {
  if (masksNotifications.length === 0) return null;
  return (
    <ul className={styles.notificationsList}>
      {masksNotifications.map((notification) => (
        <Alert
          key={generateRandomId()}
          className={styles.customAlert}
          type={notification.level.toLowerCase() as AlertTypes}
          message={notification.message}
        />
      ))}
    </ul>
  );
};
export default MasksNotificationsList;
