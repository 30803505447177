import { Spin } from "antd";

import styles from "./LoadingFile.module.css";

const LoadingFile = () => {
  return (
    <div className={styles.container}>
      <Spin size="small" />
      Loading file
    </div>
  );
};
export default LoadingFile;
