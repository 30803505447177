import { Button, Empty } from "antd";

import emptyImage from "../../assets/images/empty.svg";

import styles from "./EmptyContent.module.css";

const EmptyContent = ({ onOpenModal }: { onOpenModal: () => void }) => {
  return (
    <Empty
      className={styles.emptyContainer}
      image={emptyImage}
      description={
        <>
          <h3 className="lg strong">Welcome to your workspace!</h3>
          <p className={`lg normal ${styles.emptyDescription}`}>
            Start by creating tasks and attaching your specific files to begin
            work.
          </p>
        </>
      }
    >
      <Button
        onClick={onOpenModal}
        className="lg normal"
        size="large"
        type="primary"
      >
        Create task
      </Button>
    </Empty>
  );
};
export default EmptyContent;
