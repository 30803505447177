import { ReactNode } from "react";

import Header from "./Header";
import Aside from "./Aside";

import styles from "./HomeLayout.module.css";

const HomeLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.contentWrapper}>
        <Aside />
        {children}
      </div>
    </div>
  );
};
export default HomeLayout;
