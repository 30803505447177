import { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

import styles from "./UploadItem.module.css";

const DocumentName = ({ docName }: { docName: string }) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isEllipsed, setIsEllipsed] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) setIsEllipsed(element.scrollWidth > element.clientWidth);
  }, [docName]);

  return (
    <Tooltip title={isEllipsed ? docName : ""}>
      <div className={styles.uploadItemFilename}>
        <PaperClipOutlined style={{ fontSize: "22px" }} />
        <p
          ref={textRef}
          className={`base normal m-0 ${styles.uploadItemFilenameText}`}
        >
          {docName}
        </p>
      </div>
    </Tooltip>
  );
};
export default DocumentName;
