import { FormRule } from "antd";
import { RcFile } from "antd/es/upload";

const maxFileSizeMB =
  process.env.REACT_APP_STORAGE_AWS_S3_BUCKET_MAX_FILE_SIZE_BYTES ?? 314572800; // tamaño máximo en megabytes

export const taskNameRules: FormRule[] = [
  { required: true, message: "Task name is required" },
  { max: 50, message: "Maximum 50 characters" },
  {
    pattern: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]+$/,
    message: "Task name can only include alphanumeric characters",
  },
  { whitespace: true, message: "Task name is required" },
];

export const productNameRules: FormRule[] = [
  { required: true, message: "Product name is required" },
];

export const procedureTypeRules: FormRule[] = [
  { required: true, message: "Procedure type is required" },
];

export const procedureIdRules: FormRule[] = [
  () => ({
    validator(_, value) {
      if (value.trim().length === 0)
        return Promise.reject(new Error("ID Procedure is required"));

      const ALPHA_REGEX = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]+$/;

      if (value.length > 8 || !ALPHA_REGEX.test(value)) {
        return Promise.reject(new Error("Max. 8 alphanumeric characters"));
      }
      return Promise.resolve();
    },
  }),
];

/* Files Accepted */

export const isPDF = (file: RcFile) => file.type === "application/pdf";
export const isDOC = (file: RcFile) => file.type === "application/msword";
export const isDOCX = (file: RcFile) =>
  file.type ===
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const isFileSizeValid = (file: RcFile): boolean => {
  if (typeof maxFileSizeMB === "string")
    return file.size <= Number(maxFileSizeMB);
  return file.size <= maxFileSizeMB;
};
