import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { debounce } from "lodash";

import { AuthContextType } from "../types/providers";

import {
  UserLogged,
  authenticate,
  checkUserSession,
  isLastActivityExpired,
  signOut,
} from "../services/cognito";

import { addLastActivityCookie } from "../services/cookies";

import { MAX_TIME_EXPIRATION } from "../utils/constants";

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserLogged | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleUserSession = async () => {
    try {
      const user = await checkUserSession();
      setUser(user);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "NotAuthorizedException") {
          signOut();
        }
        setUser(null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleUserSession();
  }, []);

  useEffect(() => {
    let logoutTimer: ReturnType<typeof setTimeout>;

    const handleLogout = async () => {
      if (isLastActivityExpired()) {
        return setUser(null);
      }
      handleUserSession();
    };

    // Función para reiniciar el temporizador
    const resetTimer = debounce(() => {
      clearTimeout(logoutTimer);
      addLastActivityCookie();
      logoutTimer = setTimeout(handleLogout, MAX_TIME_EXPIRATION); // 3600000 ms = 60 minutos
    }, 1000)

    // Eventos para reiniciar el temporizador
    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];

    if (user) {
      events.forEach((event) => document.addEventListener(event, resetTimer));
      // Configurar el temporizador inicial
      resetTimer();
    }

    return () => {
      // Limpieza al desmontar el componente
      clearTimeout(logoutTimer);
      events.forEach((event) =>
        document.removeEventListener(event, resetTimer)
      );
    };
  }, [user]);

  const login = async (email: string, password: string) => {
    const userLogged = await authenticate(email, password);
    setUser(userLogged);
  };

  const logout = () => {
    signOut();
    setUser(null);
  };

  const value = useMemo(
    () => ({ user, isLoading, login, logout }),
    [isLoading, user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error(
      "useAuthContext must be used within a MaskFormContextProvider"
    );

  return context;
};

export default AuthContextProvider;
