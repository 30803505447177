import { Form, FormItemProps } from "antd";

const MasksItem = (props: FormItemProps) => {
  return (
    <Form.Item className={`masksFormItem ${props.className}`} {...props}>
      {props.children}
    </Form.Item>
  );
};
export default MasksItem;
