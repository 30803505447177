import dayjs from "dayjs";

import { Maybe } from "../types/common";
import { FormFilters, OptionalFormFilters } from "../types/formFilters";
import { Task, TaskError } from "../types/task";
import { PROCESSING_ERRORS } from "./constants";

type SortType = "asc" | "desc";

export const getSortedTasks = (tasks: Task[]) => {
  let sortedTasks = [...tasks];

  sortedTasks = getTasksCompleted(sortedTasks);
  sortedTasks = sortedTasks.filter(
    (task) => !task.deleted_at && (task.edited_at || task.created_at)
  );
  sortedTasks.sort((firstTask, secondTask) =>
    sortTasksByDate(
      secondTask.edited_at ?? secondTask.created_at,
      firstTask.edited_at ?? firstTask.created_at
    )
  );

  return sortedTasks;
};

export const sortTasksByDate = (
  firstDate: string,
  secondDate: string,
  sortType: SortType = "asc"
) => {
  const firstDateToCompare = new Date(firstDate);
  const secondDateToCompare = new Date(secondDate);

  if (
    isNaN(firstDateToCompare.getTime()) ||
    isNaN(secondDateToCompare.getTime())
  )
    return 0;

  return sortType === "desc"
    ? secondDateToCompare.getTime() - firstDateToCompare.getTime()
    : firstDateToCompare.getTime() - secondDateToCompare.getTime();
};

export const getProcessingTaskError = (
  taskError: Maybe<TaskError>
): TaskError => {
  if (!taskError) {
    return PROCESSING_ERRORS.ProcessingError;
  }

  return (
    PROCESSING_ERRORS[taskError.type] ?? {
      type: "ProcessingError",
      message: taskError.message,
    }
  );
};

/* Tasks filters */

export const getTasksCompleted = (tasks: Task[]) =>
  tasks.filter(getTaskCompletedWithoutErrors);

export const getTasksProcessing = (tasks: Task[]) =>
  tasks.filter(getTaskProcessing);

export const getTasksPending = (tasks: Task[]) => tasks.filter(getTaskPending);

export const getTasksPendingAndRunning = (tasks: Task[]) =>
  tasks
    .filter((task) => getTaskPending(task) || getTaskRunning(task))
    .filter((task) => !getTaskDeleted(task));

export const getTasksError = (tasks: Task[]) =>
  tasks.filter((task) => getTaskError(task) && !getTaskDeleted(task));

const getTaskCompleted = (task: Task) => task.status === "SUCCESS";

const getTaskCompletedWithoutErrors = (task: Task) =>
  getTaskCompleted(task) && task.error === null;

const getTaskProcessing = (task: Task) => task.status !== "SUCCESS";

const getTaskPending = (task: Task) => task.status === "PENDING";

const getTaskRunning = (task: Task) => task.status === "RUNNING";

const getTaskError = (task: Task) => task.status === "FAILED";

const getTaskDeleted = (task: Task) => task.deleted_at;

export const getTasksFiltered = (
  tasks: Task[],
  filtersApplied: OptionalFormFilters[],
  searchName = ""
) => {
  if (filtersApplied.length === 0 && searchName.trim().length === 0)
    return tasks;

  let tasksFiltered = tasks;

  filtersApplied.forEach((filter) => {
    const filterName = Object.keys(filter)[0] as keyof FormFilters;

    tasksFiltered = tasksFiltered.filter((task) =>
      shouldIncludeTask(task, filterName, filter)
    );
  });

  const searchNameLowerCase = searchName.toLowerCase();

  return tasksFiltered.filter((task) =>
    task.name.toLowerCase().includes(searchNameLowerCase)
  );
};

const shouldIncludeTask = (
  task: Task,
  filterName: keyof OptionalFormFilters,
  filter: OptionalFormFilters
): boolean => {
  switch (filterName) {
    case "taskId":
      return getTaskFilteredById(task, filter.taskId);
    case "product":
      return task.product.value === filter.product;
    case "user":
      return task.created_by === filter.user;
    case "procedureType":
      return task.procedure_type.value === filter.procedureType;
    case "procedureId":
      return task.procedure_id
        .toLowerCase()
        .includes(filter.procedureId?.toLowerCase() ?? "");
    case "creationDate":
    case "editionDate": {
      const isCreationDate = filterName === "creationDate";
      const dateField = isCreationDate ? task.created_at : task.updated_at;
      const filterToUse = isCreationDate
        ? filter.creationDate
        : filter.editionDate;
      return getTaskFilteredByDate(filterToUse, dateField);
    }
    case "validation": {
      const noValidationFiltersApplied = filter.validation?.length === 0;
      const needsValidatedTasks = filter.validation?.includes("Validated");
      const needsPendingTasks = filter.validation?.includes("Pending");
      const needsAllTasks =
        noValidationFiltersApplied ||
        (needsValidatedTasks && needsPendingTasks);

      const isValidatedTask = Boolean(task.validated_at && task.validated_by);

      return needsAllTasks
        ? true
        : needsValidatedTasks
        ? isValidatedTask
        : !isValidatedTask;
    }
    default:
      return true;
  }
};

const getTaskFilteredById = (
  task: Task,
  filter?: { from: number | null; to: number | null }
) => {
  if (!filter) return true;

  const { from, to } = filter;

  return (from === null || task.id >= from) && (to === null || task.id <= to);
};

const getTaskFilteredByDate = (
  filterToUse: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null | undefined,
  dateField: string
) => {
  if (!filterToUse) return true;

  const [from, toInitial] = filterToUse;
  const to = toInitial?.hour(23).minute(59).second(59);

  const dateToCompare = dayjs(dateField);

  if (!from && !to) return true;

  return from && to
    ? dateToCompare.isAfter(from, "second") &&
        dateToCompare.isBefore(to, "second")
    : false;
};
