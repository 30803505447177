import { Form, FormItemProps } from "antd";

const FiltersFormItem = (props: FormItemProps) => {
  return (
    <Form.Item className="filtersFormInput" {...props}>
      {props.children}
    </Form.Item>
  );
};
export default FiltersFormItem;
