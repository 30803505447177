import { Button, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { CustomError } from "../../../../utils/errorHandler";

import styles from "./ExpandedRowContent.module.css";

const DocumentsError = ({
  error,
  refreshTask,
}: {
  error: CustomError;
  refreshTask: () => void;
}) => {
  return (
    <div className={styles.documentsErrorContent}>
      <p className="lg m-0">Something was wrong... {error.message}</p>
      <Tooltip title={"Reload documents"}>
        <Button icon={<ReloadOutlined />} onClick={refreshTask} />
      </Tooltip>
    </div>
  );
};

export default DocumentsError;
