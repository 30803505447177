import { useState } from "react";
import { Button, Spin, Tabs, TabsProps } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { useModalContext, useTasksContext } from "../../providers";

import { HomeLayout } from "../../layouts";

import CreateTaskModal from "../../components/Modals/CreateTask/CreateTask";
import DeleteTaskPopup from "../../components/Modals/DeleteTask/DeleteTask";
import ReactivateTaskPopup from "../../components/Modals/ReactivateTask/ReactivateTask";

import EmptyContent from "./EmptyContent";
import TabCompleted from "./TabCompleted/TabCompleted";
import TabProcessing from "./TabProcessing/TabProcessing";

import styles from "./Home.module.css";

const TABS_KEYS = {
  completed: "1",
  processing: "2",
};

const Home = () => {
  const [tabSelected, setTabSelected] = useState(TABS_KEYS.completed);

  const { openCreateTask } = useModalContext();
  const { tasks, isLoading, addNewTask, refreshTasks } = useTasksContext();

  const onChangeTab = (tabKey: string) => setTabSelected(tabKey);

  const tabItems: TabsProps["items"] = [
    {
      key: TABS_KEYS.completed,
      label: "Completed",
      children: <TabCompleted tasks={tasks} />,
    },
    {
      key: TABS_KEYS.processing,
      label: "Processing",
      children: <TabProcessing tasks={tasks} />,
    },
  ];

  if (isLoading) return <Spin tip="Loading" spinning={isLoading} fullscreen />;

  return (
    <HomeLayout>
      <main className={styles.main}>
        {tasks.length === 0 ? (
          <EmptyContent onOpenModal={openCreateTask} />
        ) : (
          <div className={styles.tabsContainer}>
            <Tabs
              className="customTabBar"
              defaultActiveKey={tabSelected}
              activeKey={tabSelected}
              tabBarExtraContent={
                <Button
                  onClick={openCreateTask}
                  className="lg normal flex-r-center"
                  size="large"
                  type="primary"
                >
                  <PlusCircleOutlined />
                  Create task
                </Button>
              }
              items={tabItems}
              onTabClick={(tabClicked) => {
                setTabSelected(tabClicked);
                refreshTasks();
              }}
            />
          </div>
        )}
      </main>
      <CreateTaskModal
        addTask={addNewTask}
        goToProcessingTab={() => onChangeTab(TABS_KEYS.processing)}
      />
      <DeleteTaskPopup />
      <ReactivateTaskPopup />
    </HomeLayout>
  );
};

export default Home;
