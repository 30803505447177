import { ChangeEvent, useEffect, useState } from "react";
import { debounce } from "lodash";

import { MasksFilters } from "../types/providers";

import { Mask } from "../types/masks";

import { getMasksList } from "../pages/DocumentsViewer/utils/getMasksList";

const useMasks = (masks: Mask[], filtersApplied: MasksFilters) => {
  const [searchText, setSearchText] = useState("");
  const [masksList, setMasksList] = useState<Mask[]>(() =>
    getMasksList(masks, filtersApplied, searchText)
  );

  const onChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    debounce(
      () => setMasksList(getMasksList(masks, filtersApplied, e.target.value)),
      300
    )();
  };

  useEffect(() => {
    setMasksList(getMasksList(masks, filtersApplied, searchText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masks, filtersApplied]);

  return { masksList, searchText, onChangeSearchText };
};
export default useMasks;
