import { ReactNode } from "react";
import { Empty } from "antd";

import styles from "./EmptyContainer.module.css";

interface EmptyContainerProps {
  title: string;
  description: string;
  image: string | ReactNode;
}

const EmptyContainer = ({ title, description, image }: EmptyContainerProps) => {
  return (
    <Empty
      className="customEmptyTable"
      image={image}
      description={
        <div>
          <h3 className="lg strong">{title}</h3>
          <p className={`lg normal ${styles.emptyDescription}`}>
            {description}
          </p>
        </div>
      }
    />
  );
};
export default EmptyContainer;
