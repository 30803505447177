import { History, Mask, Occurrence } from "../../types/masks";

export const getHistoryToShow = (
  closedChevron: boolean,
  historyData: History[]
) => {
  const historyDataCloned = [...historyData];

  historyDataCloned.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const historyToShow = closedChevron
    ? historyDataCloned.slice(0, 1)
    : historyDataCloned;

  return historyToShow;
};

export const getOccurrenceShowAsDeleted = (
  maskInfo: Mask,
  occurrence: Occurrence
) => {
  const isMaskRejected = maskInfo.status === "REJECTED";
  const isMaskDeleted = maskInfo.deleted_at !== null;
  const isOccurrenceDeleted = occurrence.deleted_at !== null;

  return isMaskRejected || isMaskDeleted || isOccurrenceDeleted;
};

export const getOccurrencesSorted = (maskInfo: Mask) => {
  const occurrences = [...maskInfo.occurrences];
  occurrences.sort((a, b) => {
    if (a.quads.length === 0 || b.quads.length === 0) return 0;
    if (a.page !== b.page) return a.page - b.page;

    return a.quads[0].y1 - b.quads[0].y1;
  });

  return occurrences;
};
