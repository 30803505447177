import { WebViewerInstance } from "@pdftron/webviewer";

import {
  useMasksContext,
  useMessageContext,
  useModalContext,
  useWebViewerContext,
} from "../../../providers";

import { Mask } from "../../../types/masks";

import { redactionsFetcher } from "../../../services/api";

import { deleteMassiveRedactionsAnnotationsFromMask } from "../../../utils/pdfVisor";

import PopupModal from "../PopupModal";

const DeleteMassiveOccurrences = () => {
  const { modalOpen, maskSelected, taskSelected, docSelected, closeModal } =
    useModalContext();

  const { sendMessage } = useMessageContext();

  const { getApiMasks } = useMasksContext();

  const { instance } = useWebViewerContext();

  const onDeleteMassiveOccurrences = async () => {
    closeModal();
    sendMessage("Deleting all occurrences...", "loading", 0);
    try {
      await redactionsFetcher.deleteMask(taskSelected?.id, docSelected?.id, maskSelected?.id);
      deleteMassiveRedactionsAnnotationsFromMask(
        maskSelected as Mask,
        instance as WebViewerInstance
      );
      sendMessage("All occurrences deleted successfully", "success");
      await getApiMasks(taskSelected?.id as number, docSelected?.id as string);
    } catch (error) {
      sendMessage(
        `Occurrences couldn’t be deleted. Please, retry in a few moments. ${
          (error as Error).message
        }`,
        "error",
        2.5
      );
    }
  };
  return (
    <PopupModal
      open={modalOpen === "deleteMassiveOccurrences"}
      onCancel={closeModal}
      onConfirm={onDeleteMassiveOccurrences}
      confirmText="Delete all"
    >
      {" "}
      <h3 className="lg normal">Deleting all occurrences</h3>
      <p>
        You are about to delete the all occurrences for the mask "
        {maskSelected?.name}". If you delete it, this mask will disappear from
        all pages.
      </p>
    </PopupModal>
  );
};
export default DeleteMassiveOccurrences;
