import { ReactNode } from "react";
import { ConfigProvider } from "antd";

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: "#222223",
          colorTextSecondary: "#444446",
          colorTextTertiary: "#808085",
          colorTextQuaternary: "#b4b5b6",
          colorTextPlaceholder: "#c9cacc",
          colorBgLayout: "#fbfbff",
          colorBgMask: "#6b6b6f",
          colorBgSpotlight: "#222223",
          colorBorder: "#d0d0d1",
          colorBorderSecondary: "#e6e6e7",
          colorFill: "#d8d8d9",
          colorFillSecondary: "#e6e6e7",
          colorFillTertiary: "#f2f2f3",
          colorFillQuaternary: "#fafafb",
          colorSplit: "#f5f5f6",
          colorPrimary: "#0161fe",
          colorSuccess: "#008774",
          borderRadius: 8,
          fontFamily: "Manrope, Helvetica, Arial",
          fontSizeXL: 18,
        },
        components: {
          Table: {
            headerBg: "#ffffff",
            fontSize: 16,
            colorTextDisabled: "#d8d8d9",
            headerColor: "#808085",
          },
          Menu: {
            subMenuItemBg:
              "rgba(0, 0.08235294371843338, 0.16078431904315948, 0.02)",
            darkSubMenuItemBg: "#001529",
            itemHoverBg: "#f2f2f3",
            itemBorderRadius: 4,
          },
          Tabs: {
            colorTextDisabled: "#b4b5b6",
            itemColor: "#808085",
          },
          Card: {
            colorBorderSecondary: "#f5f5f6",
          },
          Alert: {
            fontWeightStrong: 600,
          },
          Dropdown: {
            colorIconHover: "#0161fe",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
export default ThemeProvider;
