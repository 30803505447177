import { useCallback, useEffect, useState } from "react";

import { CustomError } from "../utils/errorHandler";

import { Task } from "../types/task";

import { tasksFetcher } from "../services/api";

const useTaskDocuments = (taskId: number) => {
  const [task, setTask] = useState<Task | null>(null);
  const [error, setError] = useState<CustomError | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getTask = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const taskFetched = await tasksFetcher.getTask(taskId, false);
      setTask(taskFetched);
    } catch (error) {
      console.error(error);
      if (error instanceof CustomError) setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [taskId]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  return { task, isLoading, error, getTask };
};

export default useTaskDocuments;
