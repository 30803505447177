import { BaseFetcher } from "./BaseFetcher";

import { Mask } from "../../types/masks";
import { RedactionInfo, RedactionToCreate } from "../../types/redaction";

import { getUserLogged } from "../cognito";

class RedactionsFetch extends BaseFetcher {
  async createMask(redactionInfo: RedactionInfo): Promise<Mask> {
    this.validateRedactionInfo(redactionInfo);

    const { taskId, documentId, ...rest } = redactionInfo;
    const redactionToCreate: RedactionToCreate = { ...rest };

    const { data: mask } = await this.apiFetcher.callApi<Mask>({
      endpoint: `tasks/${taskId}/documents/${documentId}/masks`,
      options: {
        method: "POST",
        body: JSON.stringify(redactionToCreate),
        headers: {
          "Content-type": "application/json",
        },
      },
    });
    return mask;
  }

  async getMasks(taskId: number, documentId: string): Promise<Mask[]> {
    this.validateTaskId(taskId);
    this.validateDocumentId(documentId);

    const { data: masks } = await this.apiFetcher.callApi<Mask[]>({
      endpoint: `tasks/${taskId}/documents/${documentId}/masks`,
    });
    return masks;
  }

  async deleteOccurrence(
    taskId: number,
    documentId: string,
    maskId: number,
    occurrenceId: number
  ) {
    this.validateTaskId(taskId);
    this.validateDocumentId(documentId);
    this.validateMaskId(maskId);
    this.validateOccurrenceId(occurrenceId);

    const username = getUserLogged()?.getUsername();
    this.validateUsername(username);

    await this.apiFetcher.callApi({
      endpoint: `tasks/${taskId}/documents/${documentId}/masks/${maskId}/occurrences/${occurrenceId}`,
      options: { method: "DELETE" },
    });
  }

  async deleteMask(
    taskId: number | undefined,
    documentId: string | undefined,
    maskId: number | undefined
  ) {
    this.validateTaskId(taskId as number);
    this.validateDocumentId(documentId as string);
    this.validateMaskId(maskId as number);

    const username = getUserLogged()?.getUsername();
    this.validateUsername(username);

    await this.apiFetcher.callApi({
      endpoint: `tasks/${taskId}/documents/${documentId}/masks/${maskId}`,
      options: { method: "DELETE" },
    });
  }
}

const redactionsFetcher = new RedactionsFetch();

export default redactionsFetcher;
