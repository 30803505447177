import EmptyContainer from "./EmptyContainer";

import NoResultsIcon from "../icons/NoResultsIcon";

const EmptyFilters = () => {
  return (
    <EmptyContainer
      title="No results found"
      description="Try adjusting your search
            to find what you are looking for."
      image={<NoResultsIcon />}
    />
  );
};
export default EmptyFilters;
