import { useState } from "react";
import { Button, Tooltip } from "antd";

import { Task } from "../../types/task";

import ReuploadIcon from "../icons/ReuploadIcon";

import UpdateTaskModal from "../Modals/UpdateTask/UpdateTask";

const ReuploadButton = ({ task }: { task: Task }) => {
  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  return (
    <>
      <Tooltip title="Re upload task">
        <Button onClick={onOpenModal} icon={<ReuploadIcon />} />
      </Tooltip>
      {openModal && (
        <UpdateTaskModal
          isOpen={openModal}
          taskId={task.id}
          onCancel={onCloseModal}
        />
      )}
    </>
  );
};
export default ReuploadButton;
