import { useMasksFiltersContext, useModalContext } from "../../../providers";

import MasksFiltersForm from "../../Forms/Filters/Masks";

import ModalContainer from "../ModalContainer";

const FiltersMasksModal = () => {
  const { modalOpen, closeModal } = useModalContext();

  const { filtersApplied, onApplyFilters } = useMasksFiltersContext();

  return (
    <ModalContainer
      width={331}
      centered
      footer={null}
      open={modalOpen === "masksFilters"}
      title="Feedback panel filter"
      onCancel={closeModal}
      className="filtersFormModal"
      destroyOnClose
    >
      <MasksFiltersForm
        filtersApplied={filtersApplied}
        onApplyFilters={onApplyFilters}
      />
    </ModalContainer>
  );
};
export default FiltersMasksModal;
