import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TasksContextType } from "./interfaces";

import { Task } from "../types/task";

import { tasksFetcher } from "../services/api";

export const TasksContext = createContext<TasksContextType | null>(null);

const updateSingleTask = (prevTask: Task, taskToUpdate: Task): Task => {
  return prevTask.id === taskToUpdate.id ? taskToUpdate : prevTask;
};

const TasksContextProvider = ({ children }: { children: ReactNode }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  const addNewTask = (newTask: Task) => {
    setTasks((prevTasks) => [...prevTasks, newTask]);
  };

  const updateTask = (taskToUpdate: Task) => {
    setTasks((prevTasks) =>
      prevTasks.map((prevTask) => updateSingleTask(prevTask, taskToUpdate))
    );
  };

  const refreshTasks = useCallback(() => {
    if (isFirstFetch) return;
    tasksFetcher
      .getTasks()
      .then(setTasks)
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  }, [isFirstFetch]);

  const fetchAndUpdateTasks = useCallback(() => refreshTasks(), [refreshTasks]);

  const setTaskInterval = useCallback((): number => {
    return window.setInterval(fetchAndUpdateTasks, 10000);
  }, [fetchAndUpdateTasks]);

  useEffect(() => {
    tasksFetcher
      .getTasks()
      .then(setTasks)
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
        setIsFirstFetch(false);
      });
  }, []);

  useEffect(() => {
    fetchAndUpdateTasks();
    const interval = setTaskInterval();

    return () => {
      window.clearInterval(interval);
    };
  }, [setTaskInterval, fetchAndUpdateTasks]);

  const contextValue = useMemo(
    () => ({
      tasks,
      isLoading,
      addNewTask,
      updateTask,
      refreshTasks,
    }),
    [tasks, isLoading, refreshTasks]
  );
  return (
    <TasksContext.Provider value={contextValue}>
      {children}
    </TasksContext.Provider>
  );
};

export const useTasksContext = () => {
  const context = useContext(TasksContext);

  if (!context)
    throw new Error(
      "useTasksContext must be used within a TasksContextProvider"
    );

  return context;
};

export default TasksContextProvider;
