import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { TaskContextType } from "./interfaces";

import { Task } from "../types/task";

import { tasksFetcher } from "../services/api";

import { CustomError } from "../utils/errorHandler";

export const TaskContext = createContext<TaskContextType | null>(null);

const TaskContextProvider = ({ children }: { children: ReactNode }) => {
  const { taskId } = useParams();

  const [task, setTask] = useState<Task | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState<CustomError | null>(null);

  useEffect(() => {
    refreshTask(Number(taskId));
  }, [taskId]);

  const refreshTask = (taskId: number) => {
    if (!taskId) return;
    setIsLoading(true);

    tasksFetcher
      .getTask(Number(taskId))
      .then(setTask)
      .catch(setError)
      .finally(() => setIsLoading(false));
  };
  const updateTask = (task: Task) => setTask(task);

  const refreshNotifications = useCallback(() => {
    if (!task) return;
    tasksFetcher.getTask(Number(task?.id)).then(setTask).catch(setError);
  }, [task]);

  const contextValue = useMemo(
    () => ({
      task,
      isLoading,
      error,
      updateTask,
      refreshNotifications,
    }),

    [task, isLoading, error, refreshNotifications]
  );

  return (
    <TaskContext.Provider value={contextValue}>{children}</TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  const context = useContext(TaskContext);

  if (!context)
    throw new Error("useTaskContext must be used within a TaskContextProvider");

  return context;
};

export default TaskContextProvider;
