import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { DownloadOutlined } from "@ant-design/icons";

import { useMessageContext } from "../../../../../providers";

import { Task } from "../../../../../types/task";
import { Results } from "../../../../../types/subtask";

import { documentsFetcher } from "../../../../../services/api";

import {
  downloadFileHidden,
  generateRandomId,
} from "../../../../../utils/common";

import DeleteTaskButton from "../../../../Buttons/DeleteTaskButton";
import ReactivateButton from "../../../../Buttons/ReactivateButton";

import styles from "../../columns.module.css";

const GetActionDropdownItems = (
  task: Task,
  sendMessage: (
    message: string,
    messageType: NoticeType,
    duration?: number,
    key?: string
  ) => void
): MenuProps["items"] => {
  const getSubtaskId = async (
    messageId: string,
    downloadType: "ALL" | "FRDP",
    onlyResults = false
  ) => {
    const typeText = downloadType === "ALL" ? "Task" : downloadType;
    const message = `${typeText} ${task.id} files are being prepared for download. Please, wait a moment.`;

    try {
      sendMessage(message, "loading", 0, messageId);
      const { subtask_id } =
        await documentsFetcher.startProcessDownloadDocuments(
          task.id,
          onlyResults
        );
      return subtask_id;
    } catch (error) {
      sendMessage(
        "Something was wrong downloading files, please, try in a few moments",
        "error",
        1.5,
        messageId
      );
    }
  };

  const downloadDocument = async (
    intervalId: NodeJS.Timer,
    subtaskId: string,
    messageId: string,
    downloadType: "ALL" | "FRDP"
  ) => {
    try {
      const { subtask, results } = await documentsFetcher.getDownloadDocuments(
        task.id,
        subtaskId
      );

      if (subtask.status === "SUCCESS") {
        const { url, filename } = results as Results;
        await downloadFileHidden(url, filename);
        const typeText = downloadType === "ALL" ? "Task" : downloadType;
        const message = `${typeText} ${task.id} files already downloaded.`;
        sendMessage(message, "success", 1.5, messageId);
        clearInterval(intervalId);
      } else if (subtask.status === "FAILED") {
        throw Error("Subtask status failed");
      }
    } catch (error) {
      sendMessage(
        "Something was wrong downloading files, please, try in a few moments",
        "error",
        1.5,
        messageId
      );
      clearInterval(intervalId);
    }
  };

  const onDownloadDocuments = async (downloadType: "ALL" | "FRDP") => {
    const messageId = generateRandomId();
    const onlyResults = downloadType === "FRDP";
    let intervalId: NodeJS.Timer | undefined;
    try {
      const subtask_id = await getSubtaskId(
        messageId,
        downloadType,
        onlyResults
      );
      intervalId = setInterval(
        () =>
          downloadDocument(
            intervalId as NodeJS.Timer,
            subtask_id as string,
            messageId,
            downloadType
          ),
        10000
      );
    } catch (error) {
      sendMessage(
        "Something was wrong downloading files, please, try in a few moments",
        "error",
        1.5,
        messageId
      );
      clearInterval(intervalId);
    }
  };

  return [
    {
      key: 1,
      label: "All task documents",
      onClick: async () => onDownloadDocuments("ALL"),
    },
    {
      key: 2,
      label: "Final redaction documents",
      onClick: async () => onDownloadDocuments("FRDP"),
    },
  ];
};

const ActionRow = ({ task }: { task: Task }) => {
  const { sendMessage } = useMessageContext();
  const items = GetActionDropdownItems(task, sendMessage);
  return (
    <div className={styles.tableRowActions}>
      {!task.deleted_at ? (
        <>
          <Tooltip title="Download task files">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Button icon={<DownloadOutlined />} />
            </Dropdown>
          </Tooltip>
          <DeleteTaskButton task={task} />
        </>
      ) : (
        <ReactivateButton task={task} />
      )}
    </div>
  );
};

export default ActionRow;
