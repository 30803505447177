import { CloseCircleFilled } from "@ant-design/icons";

import { Maybe } from "../../../../../types/common";
import { Task, TaskError } from "../../../../../types/task";

import { getProcessingTaskError } from "../../../../../utils/tasks";

import useTaskDocuments from "../../../../../hooks/useTaskDocuments";

import ProgressNoPerc from "../../../../Progress/ProgressNoPerc";
import StatusLoader from "./StatusLoader";

import styles from "./ProcessingStatusRow.module.css";

const getDocumentsProcessing = (task: Task) =>
  task.documents.RPP_JT.map((rppJtBlock) => rppJtBlock.RPP);

const getSubtasksCompleted = (task: Task) => {
  const { subtasks } = task;
  if (!subtasks) return [];
  return subtasks.filter((subtask) => subtask.status === "SUCCESS");
};

const calculateProcessingPercentage = (processed: number, total: number) => {
  if (total === 0) {
    return total;
  }
  return Math.round((processed / total) * 100);
};

const ProcessingStatusRow = ({
  error,
  task,
}: {
  error: Maybe<TaskError>;
  task: Task;
}) => {
  const { task: taskFetched, isLoading } = useTaskDocuments(task.id);

  if (isLoading) return <StatusLoader />;

  const documentsProcessing = getDocumentsProcessing(taskFetched as Task);
  const subtasksCompleted = getSubtasksCompleted(task);
  return (
    <div className={styles.tableRowProcessStatus}>
      {task.status === "FAILED" ? (
        <>
          <CloseCircleFilled style={{ color: "#FF4D4F", width: "12px" }} />
          <span className={`base normal ${styles.statusError}`}>
            {getProcessingTaskError(error).message}
          </span>
        </>
      ) : (
        <>
          <ProgressNoPerc
            size="small"
            percent={calculateProcessingPercentage(
              subtasksCompleted.length,
              documentsProcessing.length
            )}
            steps={4}
          />
          <span className="base normal">
            {subtasksCompleted.length}/{documentsProcessing.length} files
          </span>
        </>
      )}
    </div>
  );
};
export default ProcessingStatusRow;
