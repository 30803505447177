import { useState } from "react";
import { useNavigate } from "react-router";
import { Alert, Button, Form, Input } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";

import { FormValues } from "./interfaces";

import { useAuthContext } from "../../providers";

import { emailRules, passwordRules } from "./utils";

import { PATH_ROUTES } from "../../router/routes";

import styles from "./LoginForm.module.css";

const LoginForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const { login } = useAuthContext();

  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

  const onSubmitLogin = async () => {
    try {
      const formValues = await form.validateFields();

      try {
        await login(formValues.email, formValues.password);
        navigate(PATH_ROUTES.home);
      } catch (error) {
        setIsInvalidCredentials(true);
      }
    } catch (error) {
      setIsInvalidCredentials(true);
    }
  };

  const resetInvalidCredentials = () => {
    setIsInvalidCredentials(false);
  };

  return (
    <section className={styles.formContainer}>
      <Form className={styles.form} form={form} layout="vertical">
        <header className={styles.formHeader}>
          <h3 className="head3">Welcome!</h3>
          <h4 className="lg normal">Enter your credentials to access</h4>
        </header>
        <section className={styles.formContent}>
          <Form.Item
            required={false} // Para eliminar el asterisco
            name="email"
            rules={emailRules}
            label="Email"
            hasFeedback
            className="customFormItem login"
          >
            <Input
              name="email"
              onChange={resetInvalidCredentials}
              prefix={<MailOutlined />}
              placeholder="Enter your email"
            />
          </Form.Item>
          <Form.Item
            required={false} // Para eliminar el asterisco
            name="password"
            rules={passwordRules}
            label="Password"
            hasFeedback
            className="customFormItem login"
          >
            <Input.Password
              name="password"
              onChange={resetInvalidCredentials}
              prefix={<LockOutlined />}
              placeholder="Enter your password"
            />
          </Form.Item>
          {isInvalidCredentials && (
            <Alert
              message={<h4 className="base strong">Login error</h4>}
              description="Incorrect user or password. Verify and try again"
              type="error"
            />
          )}
        </section>
        <Button onClick={onSubmitLogin} type="primary" htmlType="submit" block>
          Log In
        </Button>
      </Form>
    </section>
  );
};
export default LoginForm;
