import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";

import { Task } from "../../../types/task";

import { sharedColumns } from "./sharedColumns";

import { getParsedFullDate } from "../../../utils/dateParsers";
import { sortTasksByDate } from "../../../utils/tasks";

import StatusTag from "../../Tag/StatusTag";

import ActionRow from "./CustomRows/Completed/ActionRow";

const completedColumns: TableProps<Task>["columns"] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span className="base normal">{text}</span>,
    sorter: (a, b) => Number(a.id) - Number(b.id),
    fixed: "left",
    width: 61,
    defaultSortOrder: "descend",
  },
  ...(sharedColumns as ColumnsType<Task>),
  {
    title: "Edited on",
    dataIndex: "edited_at",
    key: "edited_at",
    render: (text, task) => (
      <span className="base normal">
        {getParsedFullDate(task.edited_at ?? task.created_at, "·")}
      </span>
    ),
    sorter: (a, b) =>
      sortTasksByDate(a.edited_at ?? a.created_at, b.edited_at ?? b.created_at),
    width: 160,
  },
  {
    title: "Owner",
    dataIndex: "created_by",
    key: "owner",
    render: (created_by) => <span className="base normal">{created_by}</span>,
  },
  {
    title: "Validation",
    render: (_, task) => {
      const isValidated = Boolean(task.validated_at && task.validated_by);
      return <StatusTag isValidated={isValidated} tooltipPlacement="top" />;
    },
    align: "center",
    width: 100,
  },
  {
    title: "Actions",
    render: (_, task) => <ActionRow task={task} />,
    fixed: "right",
  },
];

export default completedColumns;
