import { Button, Input } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";

import {
  useMasksContext,
  useMasksFiltersContext,
  useModalContext,
  useTaskContext,
} from "../../../providers";

import { Notification } from "../../../types/task";
import { Mask } from "../../../types/masks";
import { MasksFilters } from "../../../types/providers";

import useMasks from "../../../hooks/useMasks";

import { getParsedFullDate } from "../../../utils/dateParsers";

import MasksTagsList from "../../../components/TagsLists/MasksTagsList";

import MasksList from "./MasksList";
import MasksNotificationsList from "./MasksNotificationsList";
import LoadingFeedback from "../LoadingFeedback";

import styles from "./RightAside.module.css";

interface RightAsideProps {
  masks: Mask[];
  masksNotifications: Notification[];
}

const hasFiltersApplied = ({
  createdByUser,
  feedbackStatus,
  maskType,
  page: { from, to },
}: MasksFilters) => {
  return !!(createdByUser || feedbackStatus || maskType || from || to);
};

const RightAside = ({ masks, masksNotifications }: RightAsideProps) => {
  const { openMasksFilters, openValidateTask, setTask } = useModalContext();
  const { task } = useTaskContext();

  const { filtersApplied, removeFilter, onClearFilters } =
    useMasksFiltersContext();

  const { isLoading } = useMasksContext();
  const { masksList, searchText, onChangeSearchText } = useMasks(
    masks,
    filtersApplied
  );

  const isTaskValidated = Boolean(task?.validated_at && task.validated_by);

  return (
    <aside className={styles.rightAside}>
      <header className={styles.rightAsideHeader}>
        <h4 className="lg normal">Feedback Panel</h4>
        <div className={styles.searchContainer}>
          <Input
            onChange={onChangeSearchText}
            value={searchText}
            prefix={<SearchOutlined style={{ color: "#808085" }} />}
            placeholder="Search by keyword"
            style={{ width: "167px", borderColor: "#d0d0d1" }}
          />
          <Button
            style={{ borderColor: "#d0d0d1" }}
            icon={<FilterOutlined />}
            onClick={openMasksFilters}
          />
        </div>
      </header>
      <section className={styles.masksContainer}>
        <MasksTagsList
          filtersApplied={filtersApplied}
          removeFilter={removeFilter}
          onClearFilters={onClearFilters}
        />
        {isLoading ? (
          <LoadingFeedback />
        ) : (
          <>
            <MasksNotificationsList masksNotifications={masksNotifications} />
            <MasksList
              masksList={masksList}
              hasFiltersApplied={hasFiltersApplied(filtersApplied)}
            />
          </>
        )}
      </section>
      <footer className={styles.footer}>
        {isTaskValidated && (
          <p>
            Validated by {task?.validated_by}{" "}
            {getParsedFullDate(task?.validated_at ?? "")}
          </p>
        )}
        <Button
          onClick={() => {
            task && setTask(task);
            openValidateTask();
          }}
          type="primary"
          size="large"
        >
          {isTaskValidated ? "Undo Validation" : "Validate"}
        </Button>
      </footer>
    </aside>
  );
};
export default RightAside;
